import React, { useState } from 'react';
import { ChevronsDown, ChevronsUp } from 'react-feather';

interface PeopleOptions {
  name: string;
  userId: string;
}

interface ColumnData {
  label: string;
  type: string;
  recordName: string;
  options?: string[];
  dependent?: string;
  dependentResults?: string[];
  people?: PeopleOptions[];
  width?: string;
  sort?: boolean;
  admin?: boolean;
}

interface UserData {
  active: boolean;
  company: string;
  department: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  privileges: string;
  userid: string;
}

interface RecordData {
  [key: string]: any;
}

interface TableProps {
  columns: ColumnData[];
  records: RecordData[] | null; // Adjusted to RecordData array type
  users: UserData[] | null;
  table: string;
  intella_claim_id?: string;
  callBack?: (intella_claim_id: string) => {} 
}

const CensusTableComponent: React.FC<TableProps> = (props) => {
  const { columns, records, users, table, callBack, intella_claim_id } = props;

  const [tableRecords, setTableRecords] = useState<any[] | null>(records)

  const [sortColumn, setSortColumn] = useState<string>('Date');
  const [sortDirection, setSortDirection] = useState<string>('Desc');

   

  const convertDateToMMDDYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, '0'); // Get the day
    const yyyy = date.getFullYear(); // Get the full year
  
    return `${mm}/${dd}/${yyyy}`;
  };

  const formatDollarAmount = (str: string) => {
    const num = parseFloat(str);
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const sortRecords = (columnName: string) => {
    if (columnName === sortColumn) {
      // Toggle sort direction if same column clicked again
      setSortDirection(sortDirection === 'Asc' ? 'Desc' : 'Asc');
    } else {
      // Default to ascending when switching columns
      setSortColumn(columnName);
      setSortDirection('Asc');
    }

    // Perform sorting based on column and direction
    const sortedRecords = [...records!].sort((a, b) => {
      const aValue = a[columnName];
      const bValue = b[columnName];

      if (aValue < bValue) {
        return sortDirection === 'Asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === 'Asc' ? 1 : -1;
      }
      return 0;
    });

    // Update state with sorted records
    setTableRecords(sortedRecords);
  };

  return (
    <div className="max-w-full max-h-full">
      <table className="w-full h-1/2 border-collapse">
        <thead className="bg-primary dark:bg-secondary">
          <tr>
            {columns?.map((column, index) => (
              <th key={index} onClick={() => sortRecords(column.recordName)}>
                <div className='flex flex-row items-center justify-center'>
                  <p className="text-lg text-white">{column.label}</p>
                  {
                    sortDirection === 'Asc'
                      ? <ChevronsDown size={28} color={'white'}/>
                      : <ChevronsUp size={28} color={'white'}/>
                  }
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRecords && tableRecords.length > 0 ? (
            tableRecords.map((record: RecordData, rowIndex: number) => (
              <tr key={rowIndex} className={`text-center min-h-14 h-16 text-white ${rowIndex % 2 === 0 ? 'bg-stone-900' : 'bg-stone-800'}`}>
                {columns.map((column, columnIndex: number) => (
                  <td key={columnIndex}>
                    {column.type === 'date' && convertDateToMMDDYYYY(record[column.recordName])}
                    {column.type === 'dollar' && formatDollarAmount(record[column.recordName])}
                    {column.type === 'text' && record[column.recordName]}
                    {!column.type && record[column.recordName]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center text-black dark:text-white">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CensusTableComponent;
