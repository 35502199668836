import React, { useEffect, useState } from 'react'
import SearchComponent from '../Inputs/SearchComponent'
import { useClaims } from '../../Context/ClaimsContext'
import ButtonComponent from '../Inputs/ButtonComponent'
import CalendarSelectComponent from '../Inputs/CalendarSelectComponent'
import SelectComponent from '../Inputs/SelectComponent'
import PercentOptionComponent from '../Inputs/PercentOptionComponent'
import SelectInputComponent from '../Inputs/SelectInputComponent'
import { useData } from '../../Context/DataContext'
import { start } from 'repl'
import { faC } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../Pagination/PaginationComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeft, ChevronRight, Search } from 'react-feather'
import SearchSubmitComponent from '../Inputs/SearchSubmitComponent'
import { useNavigation } from '../../Context/NavigationContext'
import LargeButtonComponent from '../Inputs/LargeButtonComponent'
import SelectInput from '@mui/material/Select/SelectInput'
import SelectInputColumns from '../Inputs/SelectInputColumns'

//236672020

const ClaimsCensusFilter = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const { selectedClaims, addBatchCensusToFavorites, pushingToFollowup, toggleToShowColumnCensus, selectedColumnsAvea, handleCurrentInsuranceChange,
    currentInsurance, grabSearchByNameCensus, claimsSearch, handleClaimsSearchChange, 
    activeClaimSearch, handleAcriveClaimSearchChange,
    grabRefreshClaims, page, startDate, endDate, minPercent, 
    maxPercent, facility, cptCode,loc, claimsCensusSearch, handlePageChange,
    handleStartDate,handleEndDate,handleMinPercent,handleMaxPercent,
    handleFacilityChange, handleClaimsCensusSearchChange, handleLocChange, handleCptChange,
    showDeniedClaims, setShowDeniedClaims
  } = useClaims()

  const {insuranceOptions} = useData()

  const percentOptions = [];
  const facilityOptions = ['All', 'Affinity', 'Beachside', 'Axis']
  const locOptions = ['All', 'Residential', 'Detox']
  const cptCodeOptions = ['All','H0018', 'H0010', 'H0035']
  const claimsColumnsOptinos = [
    'Select',
    'Coordinator',
    'Claim Id',
    'In Collab',
    'CPT Code',
    'Auth. Code',
    'LOC',
    'Name',
    'DOB',
    'Policy',
    'Insruance',
    'Facility',
    'Start Date',
    'End Date',
    'Submission Date'
  ]

  for (let i = 0; i <= 100; i++) {
    percentOptions.push(i);
  }

  const insuranceFilterOptions = insuranceOptions ? insuranceOptions.map(object => object.label ? object.label : 'N/A') : ['None']; insuranceFilterOptions.unshift('All')

  useEffect(() => {
    grabSearchByNameCensus(claimsCensusSearch)
  }, [startDate, endDate, minPercent, maxPercent, page, facility, cptCode, loc, claimsCensusSearch, currentInsurance])

  return (
    <div className="h-full px-2 w-full flex flex-col justify-between bg-primary-header dark:bg-slate-700 rounded-lg mb-4">
      <div className='w-full flex flex-row items-center justify-between mt-1'>
        <div className=''>
          <PaginationComponent pageCount={50} currentPage={page} handlePageChange={handlePageChange}/>
        </div>
        <div className='flex-1 mr-2'>
          <SearchComponent searchTerm={claimsCensusSearch} handler={handleClaimsCensusSearchChange}
          placeholder='Search name...' activeSearch={activeClaimSearch} handleActiveSearch={handleAcriveClaimSearchChange}/>
        </div>
        <div onClick={() => {setShowDeniedClaims(!showDeniedClaims)}} className='hover:cursor-pointer flex flex-row items-center border-2 border-primary p-.5 rounded-full ml-2'>
          <p className='mx-2 min-w-16 text-black dark:text-white font-bold'>Denied Claims</p>
          {
            showDeniedClaims
              ? <ChevronRight height={24} width={24} className='text-black dark:text-white'/>
              : <ChevronLeft height={24} width={24} className='text-black dark:text-white'/>
          }
        </div>
      </div>
      <div className='w-full flex flex-row items-center justify-between  mb-1'>
        <div>
          {
            selectedClaims.length > 0
              ? <LargeButtonComponent label={pushingToFollowup ? 'Submitting' : 'Move To Followup'} handler={() => {addBatchCensusToFavorites()}}/>
              : null
          }
        </div>
        <div className='w-full flex flex-row items-center justify-end'>
          <div className='flex flex-row items-center justify-end'>
            <div className='flex flex-row items-center'>
              <p className='mx-2 min-w-16 max-w-24 text-black dark:text-white font-bold'>Date Range: </p>
              <CalendarSelectComponent selectedDate={startDate} handleDateChange={handleStartDate}/>
              <p className='mx-2 text-black dark:text-white font-bold'> - </p>
              <CalendarSelectComponent selectedDate={endDate} handleDateChange={handleEndDate}/>
            </div>
            <div className='flex flex-row items-center'>
              <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Payout: </p>
              <PercentOptionComponent placeholder='0' options={percentOptions} selectedValue={minPercent} handleOptionClick={handleMinPercent}/>
              <p className='mx-2 text-black dark:text-white font-bold'> - </p>
              <PercentOptionComponent placeholder='0' options={percentOptions} selectedValue={maxPercent} handleOptionClick={handleMaxPercent}/>
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Columns: </p>
            <SelectInputColumns options={claimsColumnsOptinos} showColumnFunction={toggleToShowColumnCensus} columnsList={selectedColumnsAvea}/>
          </div>
          {/* <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Insurance: </p>
            <SelectInputComponent placeholder="All" options={insuranceFilterOptions} selectedValue={currentInsurance} handleOptionClick={handleCurrentInsuranceChange}/>
          </div> */}
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Facility: </p>
            <SelectInputComponent placeholder='All' options={facilityOptions} selectedValue={facility} handleOptionClick={handleFacilityChange}/>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>LOC: </p>
            <SelectInputComponent placeholder='All' options={locOptions} selectedValue={loc} handleOptionClick={handleLocChange}/>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>CPT Code: </p>
            <SelectInputComponent placeholder='All' options={cptCodeOptions} selectedValue={cptCode} handleOptionClick={handleCptChange}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimsCensusFilter
