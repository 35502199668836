import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useClaims } from '../../Context/ClaimsContext';

interface SelectProps {
  options: string[];
  columnsList: string[]
  showColumnFunction: (text: string) => void
}

const SelectInputColumns: React.FC<SelectProps> = ({ options, columnsList, showColumnFunction }) => {
  const { selectedColumns } = useClaims();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex w-full items-center p-1 bg-primary dark:bg-secondary text-sm rounded-md text-white focus:outline-none focus:shadow-outline"
      >
        Select Columns
        {isOpen ? <ChevronUp className="ml-2" /> : <ChevronDown className="ml-2" />}
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-full bg-white rounded-md shadow-lg z-10 h-80 overflow-scroll">
          {options.map((option) => (
            <label className="flex items-center p-2 cursor-pointer">
              <input
                type="checkbox"
                checked={columnsList.includes(option)}
                onChange={() => showColumnFunction(option)}
                className="mr-2"
              />
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectInputColumns;
