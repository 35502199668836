import axios from 'axios';
import baseUrl from '../Utils/baseUrl';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useData } from './DataContext';
import { DateUtilityFunctions } from '../Utils/DateUtilityFunctions';
//
interface ClaimsContextType {
  selectedClaims: string[];
  selectedClaimsAvea: string[];
  pushingToFollowup: boolean;
  allClaims: boolean;
  allClaimsAvea: boolean;
  currentClaimsNotes: ClaimsNotes[];
  currentClaimId: string;
  selectedColumns: string[];
  selectedColumnsCensus: string[]
  selectedColumnsAvea: string[]
  oldCmdSubmittedClaims: any[]
  newCmdSubmittedClaims: any[]
  currentInsurance: string;
  followupAveaRecords: ClaimsProps[] | null;
  pendingAveaRecords: ClaimsProps[] | null;
  successfullAveaRecords: ClaimsProps[] | null;
  failedAveaRecords: ClaimsProps[] | null;
  claimsSearch: string,
  activeClaimSearch: boolean,
  claimsCensusSearch: string,
  facility: string,
  status: string,
  handleClaimsSearchChange: (text: string) => void,
  handleClaimsCensusSearchChange: (text: string) => void,
  handleAcriveClaimSearchChange: () => void,
  clearActiveClaimSearch: () => void
  updateSelectedClaims: (text: string) => void;
  updateSelectedClaimsAvea: (text: string) => void;
  addBatchToFavorites: (navigate: any, location: any) => void;
  selectAllClaims: (records: any) => void;
  selectAllClaimsAvea: (records: any) => void;
  unselectAllClaims: () => void;
  unselectAllClaimsAvea: () => void;
  addBatchToAveaFavorites: () => void;
  getClaimsNotes:(notes: any) => void;
  storeClaimsNotes: (notesData: any) => void;
  addBatchCensusToFavorites: () => void
  toggleToShowColumn: (text: string) => void
  toggleToShowColumnAvea: (text: string) => void
  toggleToShowColumnCensus: (text:string) => void
  handleCurrentInsuranceChange: (insurance: string) => void
  grabSearchByNameClaims: (name: string) => void
  grabSearchByNameCensus: (name: string) => void
  grabAveaClaims: () => void,
  SearchAveaClaims: (text: string) => void,
  aveaClaimsRecords: ClaimsProps[] | null,
  grabClaims: () => void,
  grabRefreshClaims: () => void
  grabRefreshAveaClaims: (
    startDate: Date,
    endDate: Date,
    minPercent: number,
    maxPercent: number,
    page: number,
    facility: string,
    status: string,
  ) => void
  getAveaFollowup: () => void,
  getRefreshAveaFollowup: (
    facility: string, 
    status: string
  ) => void
  submittingCensusToCollabeLoading: boolean
  updateNewClaim: (claim_id: string, auth_number: string, start_date: Date, end_date: Date, bill_type: string, diagnosis: string, gender: string, rev_code: string, cpt_code: string) => void
  getAllCensusFollowup: () => void
  claimsCensusRecords: any[]
  cptCode: string,
  loc: string,
  page: number,
  startDate: Date,
  endDate: Date,
  minPercent: number,
  maxPercent: number,
  handleStartDate: (date: Date) => void,
  handleEndDate: (date: Date) => void,
  handleMinPercent: (data: string) => void,
  handleMaxPercent: (data: string) => void,
  handleLocChange: (text: string) => void,
  handleFacilityChange: (data: string) => void,
  handleStatusChange: (data: string) => void,
  handleSortColumnChange: (text:string) => void,
  handleSortOrderChange: (value: boolean) => void,
  handlePageChange: (page: number) => void,
  handleCptChange: (text: string) => void,
  ascending: boolean,
  submitCensusToCollab: (uuids: string) => void,
  claimsRecords: ClaimsProps[] | null,
  showDeniedClaims: boolean,
  setShowDeniedClaims: (data: boolean) => void
  grabAllDeniedClaims: () => void
  allDeniedClaims: any[]
}

interface ClaimsNotes {
  coordinator: string,
  date: string,
  notes: string
}

interface ClaimsProps {
  balance_total: number,
  charged_total: number,
  claim_id: string,
  claim_status: string,
  coordinator: string,
  end_date: string,
  facility: string,
  favorites: number,
  fu_note: string | null,
  name: string,
  network: string,
  paid_total: number,
  payout_ratio: boolean,
  start_date: string,
  status: string
}

const ClaimsContext = createContext<ClaimsContextType>({
  selectedClaims: [],
  selectedClaimsAvea: [],
  allClaims: false,
  allClaimsAvea: false,
  pushingToFollowup: false,
  currentClaimsNotes: [],
  currentClaimId: "",
  selectedColumns: [],
  selectedColumnsCensus: [],
  selectedColumnsAvea: [],
  oldCmdSubmittedClaims: [],
  newCmdSubmittedClaims: [],
  currentInsurance: "All",
  followupAveaRecords: null,
  pendingAveaRecords: null,
  successfullAveaRecords: null,
  failedAveaRecords: null,
  claimsSearch: '',
  activeClaimSearch: false,
  claimsCensusSearch: '',
  handleClaimsSearchChange: () => {},
  handleClaimsCensusSearchChange: () => {},
  handleAcriveClaimSearchChange: () => {},
  clearActiveClaimSearch: () => {},
  updateSelectedClaims: () => {},
  updateSelectedClaimsAvea: () => {},
  addBatchToFavorites: () => {},
  selectAllClaims: () => {},
  selectAllClaimsAvea: () => {},
  unselectAllClaims: () => {},
  unselectAllClaimsAvea: () => {},
  addBatchToAveaFavorites: () => {},
  getClaimsNotes: () => {},
  storeClaimsNotes: () => {},
  addBatchCensusToFavorites: () => {},
  toggleToShowColumn: () => {},
  toggleToShowColumnAvea: () => {},
  toggleToShowColumnCensus: () => {},
  handleCurrentInsuranceChange: () => {},
  grabSearchByNameClaims: () => {},
  grabSearchByNameCensus: () => {},
  grabAveaClaims: () => {},
  SearchAveaClaims: () => {},
  aveaClaimsRecords: [],
  grabClaims: () => {},
  grabRefreshClaims: () => {},
  grabRefreshAveaClaims: () => {},
  getAveaFollowup: () => {},
  getRefreshAveaFollowup: () => {},
  submittingCensusToCollabeLoading: false,
  updateNewClaim: () => {},
  getAllCensusFollowup: () => {},
  claimsCensusRecords: [],
  facility: 'ALL',
  cptCode: '',
  loc: '',
  page: 1,
  startDate: new Date('Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)'),
  endDate: new Date,
  minPercent: 0,
  maxPercent: 100,
  status: 'ALL',
  handleStartDate: () => {},
  handleEndDate: () => {},
  handleMinPercent: () => {},
  handleMaxPercent: () => {},
  handleLocChange: () => {},
  handleFacilityChange: () => {},
  handleStatusChange: () => {},
  handleSortColumnChange: () => {},
  handleSortOrderChange: () => {},
  handlePageChange: () => {},
  handleCptChange: () => {},
  ascending: false,
  submitCensusToCollab: () => {}, 
  claimsRecords: [],
  showDeniedClaims: false,
  setShowDeniedClaims: () => {} ,
  grabAllDeniedClaims: () => {},
  allDeniedClaims: []
});

export function useClaims() {
  return useContext(ClaimsContext);
}

interface AppProviderProps {
  children: ReactNode;
}

export const ClaimsProvider: React.FC<AppProviderProps> = ({ children }) => {
  const {currentTimeZone} = useData()
  const [selectedClaims, setSelectedClaims] = useState<string[]>([])
  const [selectedClaimsAvea, setSelectedClaimsAvea] = useState<string[]>([])
  const [pushingToFollowup, setPushingToFollowup] = useState<boolean>(false)
  const [pushingToFollowupAvea, setPushingToFollowupAvea] = useState<boolean>(false)
  const [allClaims, setAllClaims] = useState<boolean>(false)
  const [allClaimsAvea, setAllClaimsAvea] = useState<boolean>(false)
  const [currentClaimsNotes, setCurrentClaimsNotes] = useState<ClaimsNotes[]>([])
  const [currentClaimId, setCurrentClaimId] = useState<string>("")
  const [claimsRecords, setClaimsRcords] = useState<ClaimsProps[] | null>(null)


  const [oldCmdSubmittedClaims, setOldCmdSubmittedClaims] = useState<any[]>([])
  const [newCmdSubmittedClaims, newNewCmdSubmittedClaims] = useState<any[]>([])
  const [currentInsurance, setCurrentInsurance] = useState<string>("All")
  const [aveaClaimsRecords, setAveaClaimsRecords] = useState<ClaimsProps[] | null>(null)

  const [showDeniedClaims, setShowDeniedClaims] = useState<boolean>(false)
  const [allDeniedClaims, setAllDeniedClaims] = useState<any[]>([])

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'Show Notes',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Network',
    'Status',
    'Notes',
    'Charged Total',
    'Paid Total',
    'Payout %',
    'Balance Total',
    'Start Date',
    'End Date'
  ])

  const [selectedColumnsAvea, setSelectedColumnsAvea] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'Show Notes',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Network',
    'Status',
    'Notes',
    'Charged Total',
    'Paid Total',
    'Payout %',
    'Balance Total',
    'Start Date',
    'End Date'
  ])

  const [selectedColumnsCensus, setSelectedColumnsCensus] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'In Collab',
    'CPT Code',
    'Auth. Code',
    'LOC',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Start Date',
    'End Date',
    'Submission Date'
  ])

  const [followupStartDate, setFollowupStartDate] = useState(new Date("Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)"));
  const [followupEndDate, setFollowupEndDate] = useState(new Date());
  const [followupAveaRecords, setFollowupAveaClaims] = useState<ClaimsProps[] | null>(null)
  const [pendingAveaRecords, setPendingAveaRecords] = useState<ClaimsProps[] | null>(null)
  const [successfullAveaRecords, setSuccessfulAveaRecords] = useState<ClaimsProps[] | null>(null)
  const [failedAveaRecords, setFailedAveaRecords] = useState<ClaimsProps[] | null>(null)

  const [submittingCensusToCollabeLoading, setSubmittingCensusToCollabeLoading] = useState<boolean>(false)
  const [claimsCensusRecords, setClaimsCensusRecords] = useState<any[]>([])

  const [claimsSearch, setClaimsSearch] = useState<string>('')
  const [activeClaimSearch, setActiveClaimSearch] = useState<boolean>(false)
  const [claimsCensusSearch, setClaimsCensusSearch] = useState<string>('')

  const [facility, setFacility] = useState<string>(localStorage.getItem('facility') || 'All')
  const [status, setStatus] = useState<string>(localStorage.getItem('status') || 'All')
  const [column, setColumn] = useState<string>(localStorage.getItem('sortColumn') || 'end_date')
  const [ascending, setAscending] = useState<boolean>(localStorage.getItem('sortOrder') === 'true' ? true : false || false)

  const [cptCode, setCptCode] = useState<string>('All')
  const [loc, setLoc] = useState<string>('All')

  const [page, setPage] = useState<number>(() => {
    const storedPage = localStorage.getItem('page');
    return storedPage !== null ? parseInt(storedPage, 10) : 0;
  });

  const [startDate, setStartDate] = useState<Date>(() => {
    const storedDate = localStorage.getItem('startDate');
    return storedDate !== null ? new Date(storedDate) : new Date('Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)');
  });
  const [endDate, setEndDate] = useState<Date>(() => {
    const storedDate = localStorage.getItem('endDate');
    return storedDate !== null ? new Date(storedDate) : new Date();
  })
  const [minPercent, setMinPercent] = useState<number>(() => {
    const storedMinPercent = localStorage.getItem('minPercent');
    return storedMinPercent !== null ? parseFloat(storedMinPercent) : 0;
  });  
  const [maxPercent, setMaxPercent] = useState<number>(() => {
    const storedMinPercent = localStorage.getItem('maxPercent');
    return storedMinPercent !== null ? parseFloat(storedMinPercent) : 100;
  })

  const handleStartDate = (date: Date) => {
    setStartDate(date)
    localStorage.setItem('startDate', date.toString());
  }

  const handleEndDate = (date: Date) => {
    setEndDate(date)
    localStorage.setItem('endDate', date.toString());
  }

  const handleMinPercent = (data: string) => {
    setMinPercent(parseInt(data))
    localStorage.setItem('minPercent', data.toString());
  }

  const handleMaxPercent = (data: string) => {
    setMaxPercent(parseInt(data))
    localStorage.setItem('maxPercent', data.toString());
  }

  const handleFacilityChange = (data: string) => {
    setFacility(data)
    localStorage.setItem('facility', data.toString());
  }

  const handleStatusChange = (data: string) => {
    setStatus(data)
    localStorage.setItem('status', data.toString());
  }

  const handleClaimsSearchChange = (text: string) => {
    text === ''
      ? setActiveClaimSearch(false)
      : setActiveClaimSearch(true)
    setClaimsSearch(text)
    grabSearchByNameClaims(text)
  }

  const handleClaimsCensusSearchChange = (text: string) => {
    text === ''
      ? setActiveClaimSearch(false)
      : setActiveClaimSearch(true)
    setClaimsCensusSearch(text)
  }

  const handleAcriveClaimSearchChange = () => {
    setActiveClaimSearch(!activeClaimSearch)
  }

  const clearActiveClaimSearch = () => {
    setClaimsSearch('')
    setActiveClaimSearch(false)
  }

  const handleSortColumnChange = (text:string) => {
    setColumn(text)
    localStorage.setItem('sortColumn', text);
  }

  const handleSortOrderChange = (value: boolean) => {
    setAscending(value)
    localStorage.setItem('sortOrder', value.toString());
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('page', page.toString());
  }

  const handleCptChange = (data: string) => {
    setCptCode(data)
  }

  const handleLocChange = (text: string) => {
    setLoc(text)
  }

  useEffect(() => {
    getOldSubmittedCMD()
    getNewSubmittedCMD()
  }, [])

  const toggleToShowColumn = (text: string) => {
    setSelectedColumns((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const toggleToShowColumnAvea = (text: string) => {
    setSelectedColumnsAvea((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const toggleToShowColumnCensus = (text: string) => {
    setSelectedColumnsCensus((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const updateSelectedClaims = (text: string) => {
    setSelectedClaims(prevSelectedClaims => {
      if (prevSelectedClaims.includes(text)) {
        return prevSelectedClaims.filter(item => item !== text);
      } else {
        return [...prevSelectedClaims, text];
      }
    });
  }

  const selectAllClaims = (records: any) => {
    setAllClaims(true)
    let recordList: any = []
    records.map((record: any) => {
      recordList.push(record.claim_id)
    })
    setSelectedClaims(recordList)
  }

  const unselectAllClaims = () => {
    setAllClaims(false)
    setSelectedClaims([])
  }

  function arrayToIndexedObject(arr: string[]) {
    return arr.reduce((acc: { [key: number]: string }, val, index) => {
      acc[index + 1] = val.toString();
      return acc;
    }, {});
  }

  function arrayToIndexedObjectAvea(arr: string[]) {
    return arr.reduce((acc: { [key: number]: string }, val, index) => {
      acc[index + 1] = val.toString();
      return acc;
    }, {});
  }

  const updateSelectedClaimsAvea = (text: string) => {
    setSelectedClaimsAvea(prevSelectedClaims => {
      if (prevSelectedClaims.includes(text)) {
        return prevSelectedClaims.filter(item => item !== text);
      } else {
        return [...prevSelectedClaims, text];
      }
    });
  }

  const selectAllClaimsAvea = (records: any) => {
    setAllClaimsAvea(true)
    let recordList: any = []
    records.map((record: any) => {
      recordList.push(record.claim_id)
    })
    setSelectedClaimsAvea(recordList)
  }

  const unselectAllClaimsAvea = () => {
    setAllClaimsAvea(false)
    setSelectedClaimsAvea([])
  }

  const addBatchToFavorites = (navigate: any, location: any) => {
    setPushingToFollowup(true)
    let newData = arrayToIndexedObject(selectedClaims)
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/add_favorite_collab`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: selectedClaims
    };
    axios.request(config)
      .then((response) => {
        setSelectedClaims([])
        setSelectedClaimsAvea([])
        grabClaims()
        setPushingToFollowup(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addBatchCensusToFavorites = () => {
    let newData = arrayToIndexedObject(selectedClaims)
    
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/followup/census/add`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : selectedClaims
    };
    
    axios.request(config)
      .then((response) => {
        getAllCensusFollowup()
        setPushingToFollowup(false)
      })
      .catch((error) => {
        console.error('Config:', error.config);
      });
  }

  const addBatchToAveaFavorites = () => {
    setPushingToFollowup(true)
    let newData = arrayToIndexedObjectAvea(selectedClaimsAvea)
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/add_favorite_avea/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: newData
    };
    
    axios.request(config)
      .then((response) => {
        setSelectedClaims([])
        setSelectedClaimsAvea([])
        grabAveaClaims()
        setPushingToFollowup(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getClaimsNotes = (claim_id: string) => {
    setCurrentClaimId(claim_id)
    const body = {
      'claim_id': claim_id,
      'tz': currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/note/fetch`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    };

    axios.request(config)
      .then((response: any) => {
        setCurrentClaimsNotes(response.data)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  const storeClaimsNotes = (notesData: any) => {
    const url = `${baseUrl}/claims/note/create`
    const body = {
      ...notesData,
      'tz': currentTimeZone
    }
    axios.post(url, body)
    .then((response: any) => {
      getClaimsNotes(currentClaimId)
    })
    .catch((err: any) => {
      console.error("Error sending new notes.", err)
    })
  }

  const getOldSubmittedCMD = () => {
    let data = {
      "start_date": "2022-01-01",
      "end_date": "2024-06-19",
      "tz": currentTimeZone
    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/old_cmd_analytics`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      const reformattedData = reformatData(response.data);
      setOldCmdSubmittedClaims(reformattedData);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getNewSubmittedCMD = () => {
    let data = {
      "start_date": "2022-01-01",
      "end_date": "2024-06-19",
      "tz": currentTimeZone
    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/new_cmd_analytics`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      const reformattedData = reformatData(response.data);
      newNewCmdSubmittedClaims(reformattedData);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabSearchByNameClaims = ( name:string ) => {
    let formattedName = name.toUpperCase()
    let data = {
      'start_date': DateUtilityFunctions.ReformatDate(startDate),
      'end_date': DateUtilityFunctions.ReformatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'status': status,
      'sort_by': column,
      'ascending': ascending,
      'name': formattedName,
      'insurance': currentInsurance,
      'tz': currentTimeZone
    }
    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}/claims/full_search_mainpage/`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
    };
    axios.request(config)
      .then((response: any) => {
        setClaimsRcords(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const grabSearchByNameCensus = ( name:string ) => {
    let formattedName = name.toUpperCase()
    let data = {
      'start_date': DateUtilityFunctions.ReformatDate(startDate),
      'end_date': DateUtilityFunctions.ReformatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'sort_by': column,
      'ascending': ascending,
      'name': formattedName,
      'auth_number': "",
      'loc': loc,
      'insurance': currentInsurance,
      'cpt_code': cptCode
    }
  
    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}/claims/census/search`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
    };
    axios.request(config)
      .then((response: any) => {
        setClaimsCensusRecords(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    
}

  const reformatSubmissionDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-based month
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  const reformatData = (data: any[]) => {
    const result: { [key: string]: { total_records: number; pending: number; successful: number; failed: number } } = {};
  
    data.forEach((record: any) => {
      const submissionDate = reformatSubmissionDate(record.submission_date); // Reformat the date
      if (!result[submissionDate]) {
        result[submissionDate] = {
          total_records: 0,
          pending: 0,
          successful: 0,
          failed: 0
        };
      }
      result[submissionDate].total_records += 1;
      if (record.claim_status === 'Pending') {
        result[submissionDate].pending += 1;
      } else if (record.claim_status === 'Successful') {
        result[submissionDate].successful += 1;
      } else if (record.claim_status === 'Failed') {
        result[submissionDate].failed += 1;
      }
    });
  
    const reformattedArray =  Object.entries(result).map(([date, counts]) => ({
      submission_date: date,
      total_records: counts.total_records,
      pending: counts.pending,
      successful: counts.successful,
      failed: counts.failed
    }));

    return reformattedArray.reverse();
  };

  const handleCurrentInsuranceChange = (insurance: string) => {
    setCurrentInsurance(insurance)
  }

  function formatDate(date: Date) {
    const nextDate = new Date(date);
    const pstOffset = 8 * 60;
    const utcOffset = nextDate.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const grabAveaClaims = () => {
    let data = {
      'start_date': formatDate(new Date(Date.UTC(2018, 1, 1))),
      'end_date': formatDate(endDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 1,
      "facilities":"ALL",
      "status": "ALL"}
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
        .then((response) => {
          setAveaClaimsRecords(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const SearchAveaClaims = (text: string) => {
    let data = {
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'status': status,
      'sort_by': column,
      'ascending': ascending,
      'name': text,
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea/search`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setAveaClaimsRecords(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const grabClaims = () => {
    let data = {
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 1,
      "facilities":"ALL",
      "status": "ALL",
      "sort_by": 'end_date',
      'ascending': false,
      'tz': DateUtilityFunctions.getTimeZone()
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/claim_main_page`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      setClaimsRcords(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabRefreshClaims = () => {
    let data = {
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'status': status,
      'sort_by': column,
      'ascending': ascending
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/claim_main_page`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      setClaimsRcords(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabRefreshAveaClaims = (
    startDate: Date,
    endDate: Date,
    minPercent: number,
    maxPercent: number,
    page: number,
    facility: string,
    status: string,
  ) => {
    let data = {
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'status': status,
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
        .then((response) => {
          setAveaClaimsRecords(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
  }

  const getAveaFollowup = () => {
    let pendingRecords: any = []
    let successfulRecords: any = []
    let rejectedRecords: any = []
    const requestData = {
      'start_date': formatDate(followupStartDate),
      'end_date': formatDate(followupEndDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 0,
      "facilities":"ALL",
      "status": "ALL"
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea_favorites`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : requestData
    };
    axios.request(config)
    .then((response) => {
      response.data.map((record: any) => {
        record.claim_status === 'Successful'
          ? successfulRecords.push(record)
          : record.claim_status === 'pending'
              ? pendingRecords.push(record)
              : record.claim_status == 'Failed'
                  ? rejectedRecords.push(record)
                  : pendingRecords.push(record)
      })
      setFailedAveaRecords(rejectedRecords)
      setSuccessfulAveaRecords(successfulRecords)
      setPendingAveaRecords(pendingRecords)
      setFollowupAveaClaims(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getRefreshAveaFollowup = (
    facility: string, 
    status: string,
  ) => {
    let pendingRecords: any = []
    let successfulRecords: any = []
    let rejectedRecords: any = []
    const requestData = {
      'start_date': formatDate(followupStartDate),
      'end_date': formatDate(followupEndDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 0,
      "facilities":facility,
      "status": status
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea_favorites`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };
    axios.request(config)
    .then((response) => {
      response.data.map((record: any) => {
        record.claim_status === 'Successful'
          ? successfulRecords.push(record)
          : record.claim_status === 'pending'
              ? pendingRecords.push(record)
              : record.claim_status == 'Failed'
                  ? rejectedRecords.push(record)
                  : pendingRecords.push(record)
      })
      setFailedAveaRecords(rejectedRecords)
      setSuccessfulAveaRecords(successfulRecords)
      setPendingAveaRecords(pendingRecords)
      setFollowupAveaClaims(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const submitCensusToCollab = (uuids: string) => {
    setSubmittingCensusToCollabeLoading(true)
    let data = {
      "tz": DateUtilityFunctions.getTimeZone(),
      "uuids": [
        uuids
      ]
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/census/submit`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      setSubmittingCensusToCollabeLoading(false)
    })
    .catch((error) => {
      setSubmittingCensusToCollabeLoading(false)
      console.log(error);
    });
  }

  const updateNewClaim = async (claim_id: string, auth_number: string, start_date: Date, end_date: Date, bill_type: string, diagnosis: string, gender: string, rev_code: string, cpt_code: string) => {
    let data = {
      "intella_claim_id": claim_id,
      "authorization_number": auth_number,
      "start_date": start_date,
      "end_date": end_date,
      "bill_type": bill_type,
      "diagnosis": diagnosis,
      "gender": gender,
      "rev_code":rev_code,
      "cpt_code": cpt_code
    };
    
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/census/records`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    return axios.request(config)
  }

  const getAllCensusFollowup = () => {
    const body = {
      'tz': DateUtilityFunctions.getTimeZone()
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/census`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: body
    };
    
    axios.request(config)
    .then((response) => {
      console.log(response.data)
      setClaimsCensusRecords(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabAllDeniedClaims = () => {
    const body = {
        'tz': DateUtilityFunctions.getTimeZone()
    };
    
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/notifications`,
        headers: { 
            'Content-Type': 'application/json'
        },
        data: body
    };

    axios.request(config)
    .then((response) => {
        // Utility function to check if a date is within the last month
        const isWithinLastMonth = (dateString: string) => {
            const claimDate = new Date(dateString);
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 3);
            return claimDate >= oneMonthAgo;
        };

        const deniedClaims = response.data.filter((claim: any) => 
          isWithinLastMonth(claim.start_date)
        );

        console.log('denied claims count: ', deniedClaims)
        setAllDeniedClaims(deniedClaims);
    })
    .catch((error) => {
        console.log(error);
    });
};


  const contextValue: ClaimsContextType = {
    selectedClaims,
    selectedClaimsAvea,
    pushingToFollowup,
    allClaims,
    allClaimsAvea,
    selectedColumns,
    selectedColumnsCensus,
    selectedColumnsAvea,
    oldCmdSubmittedClaims,
    newCmdSubmittedClaims,
    updateSelectedClaims,
    updateSelectedClaimsAvea,
    addBatchToFavorites,
    selectAllClaims,
    selectAllClaimsAvea, 
    unselectAllClaims,
    unselectAllClaimsAvea,
    addBatchToAveaFavorites,
    getClaimsNotes,
    currentClaimsNotes,
    currentClaimId,
    storeClaimsNotes,
    addBatchCensusToFavorites,
    toggleToShowColumn,
    toggleToShowColumnAvea,
    toggleToShowColumnCensus,
    currentInsurance,
    handleCurrentInsuranceChange,
    grabSearchByNameClaims,
    grabSearchByNameCensus,
    grabAveaClaims,
    SearchAveaClaims,
    aveaClaimsRecords,
    grabClaims,
    grabRefreshClaims,
    grabRefreshAveaClaims,
    followupAveaRecords,
    pendingAveaRecords,
    successfullAveaRecords,
    failedAveaRecords,
    getAveaFollowup,
    getRefreshAveaFollowup,
    submittingCensusToCollabeLoading,
    updateNewClaim,
    getAllCensusFollowup,
    claimsCensusRecords,
    claimsSearch,
    activeClaimSearch,
    claimsCensusSearch,
    handleClaimsSearchChange,
    handleClaimsCensusSearchChange,
    handleAcriveClaimSearchChange,
    clearActiveClaimSearch,
    cptCode,
    loc,
    page,
    startDate,
    endDate,
    minPercent,
    maxPercent,
    handleStartDate,
    handleEndDate,
    handleMinPercent,
    handleMaxPercent,
    handleLocChange,
    handleFacilityChange,
    handleStatusChange,
    handleSortColumnChange,
    handleSortOrderChange,
    handlePageChange,
    handleCptChange,
    facility,
    status,
    ascending,
    submitCensusToCollab,
    claimsRecords,
    showDeniedClaims,
    setShowDeniedClaims,
    grabAllDeniedClaims,
    allDeniedClaims
  };

  return (
    <ClaimsContext.Provider value={contextValue}>
      {children}
    </ClaimsContext.Provider>
  );
};
