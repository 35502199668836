import React, { useEffect, useState } from 'react';
import LayoutComponent from '../BaseScreen';
import { useData } from '../../Context/DataContext';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import DisplayAccordianHeader from '../../Components/SortAndFilter/DisplayAccordianHeader';
import BillingDetailsFilterComponent from '../../Components/SortAndFilter/BillingDetailsFilterComponent';
import { useNavigate } from 'react-router-dom';
import CensusFilterComponent from '../../Components/SortAndFilter/CensusFilterComponent';
import LoadingScreen from '../LoadingScreen';
import SelectInputComponent from '../../Components/Inputs/SelectInputComponent';
import BillingCensusFilterOptions from '../../Options/BillingCensusFilterOptions';
import { EyeOff, Eye } from 'react-feather';

const CensusScreen = () => {
  const navigation = useNavigate()
  const { currentPatients, getCurrentPatients, updatePatientDetails, startDateCensus, endDateCensus, loadingCensus } = useData();

  const [showAffinity, setShowAffinity] = useState<boolean>(false)
  const [showBeachside, setShowBeachside] = useState<boolean>(false)
  const [showAxis, setShowAxis] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<string>("New")
  const [visibleTiles, setVisibleTiles] = useState<Record<string,boolean>>({});

  useEffect(() => {
    getCurrentPatients();

    const savedVisibility = localStorage.getItem('tileVisibility')
    if (savedVisibility) setVisibleTiles(JSON.parse(savedVisibility));

    const savedStatusFilter = localStorage.getItem('currentStatusFilter');
    if (savedStatusFilter) setStatusFilter(JSON.parse(savedStatusFilter));

  }, [startDateCensus, endDateCensus])

  const redirectToPatientPage = (patient: any) => {
    updatePatientDetails(patient)
    navigation(`/census/${patient.policy_no}`)
  }

  const handleStatusFilterChange = (value: string) => {
    setStatusFilter(value);
    localStorage.setItem('currentStatusFilter', JSON.stringify(value))
  }

  const toggleTileVisibility = (id: string) => {
    setVisibleTiles(prev => {
      const newState = {
        ...prev,
        [id]: !prev[id]
      };

      localStorage.setItem('tileVisibility', JSON.stringify(newState));

      return newState
    });
  };

  function formatDate(date: string) {
    const nextDate = new Date(date);
    const pstOffset = 8 * 60;
    const utcOffset = nextDate.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');

    return `${month}-${day}-${year}`;
  }

  const renderPatientTile = (patient: any, facility: string) => {
    const isVisible = visibleTiles[patient.intella_claim_id] !== false;
    return (
      <div key={patient.intella_claim_id} className={`p-4 bg-stone-300 dark:bg-slate-700 rounded-lg relative ${isVisible ? 'h-auto' : 'h-16'}`}>
        {isVisible ? (
          <div onClick={() => redirectToPatientPage(patient)}>
            <div className='w-full flex flex-row justify-between'>
              <p className='text-primary dark:text-sky-500 font-semibold'>{patient.first_name} {patient.last_name}</p>
              <p className='text-black dark:text-white'>DOB: ({formatDate(patient.date_of_birth)})</p>
            </div>
            <div className='w-full flex flex-row justify-between mt-2'>
              <p className='text-black dark:text-white text-sm'>{patient.level_of_care} ({patient.cpt_code})</p>
              <p className='text-black dark:text-white text-sm'>Start: {formatDate(patient.start_date)}</p>
            </div>
            <div className='w-full flex flex-row justify-between mt-2'>
              <p className='text-black dark:text-white text-sm'>{patient.insurance_name}</p>
            </div>
            <div className='w-full flex flex-row justify-between mt-2'>
              <p className='text-black dark:text-white text-sm'>{patient.policy_no}</p>
            </div>
            {facility === 'Affinity' && (
              <>
                <div className='w-full flex flex-row justify-between mt-2'>
                  <p className='text-black dark:text-white text-sm'>{patient.rev_code}</p>
                </div>
                <div className='w-full flex flex-row justify-between mt-2'>
                  <p className='text-black dark:text-white text-sm'>{patient.bill_type}</p>
                </div>
              </>
            )}
            <div className='w-full flex flex-row justify-between mt-2'>
              <p className='text-black dark:text-white text-sm'>{patient.auth_status}</p>
              <p className='text-black dark:text-white text-sm'>Days {facility === 'Affinity' ? 'remaining' : 'left'}: {patient.days_left}</p>
            </div>
            <div onClick={(e) => {
            e.stopPropagation();
            toggleTileVisibility(patient.intella_claim_id);
          }} className='flex flex-row justify-end mt-3'>
            <Eye size={20} className='flex' color='green' /> 
          </div>
          </div>
          
        ) : (
          <div className='flex items-center justify-between h-full'>
            <p className='text-black dark:text-white'>Content hidden</p>
            <div onClick={(e) => {
            e.stopPropagation();
            toggleTileVisibility(patient.intella_claim_id);
            }}>
            <EyeOff size={20} className='flex w-full' color='red' />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    loadingCensus 
    ? (<LoadingScreen />)
    :
    <LayoutComponent
      header={
          <div className='flex flex-row justify-center items-center h-12 w-full bg-primary-header dark:bg-slate-700 rounded-lg'>
            <CensusFilterComponent />
            <div className='flex flex-row mr-5'>
              <p className='text-black dark:text-white mr-2'>Status: </p>
              <SelectInputComponent 
              options={BillingCensusFilterOptions} 
              selectedValue={statusFilter}
              handleOptionClick={handleStatusFilterChange}
              placeholder='New'
              />
            </div>
          </div>
      }
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg">
            <DisplayAccordianHeader label={'Affinity Census'} display={showAffinity} onChange={() => setShowAffinity(!showAffinity)} />
            {showAffinity && (
              <div className='grid grid-cols-4 gap-2 mt-4 mb-4'>
                {currentPatients && currentPatients['Affinity'] && 
                  currentPatients['Affinity'].map((patient: any) => 
                    patient.auth_status === statusFilter ? renderPatientTile(patient, 'Affinity') : null
                  )
                }
              </div>
            )}
            <DisplayAccordianHeader label={'Beachside Census'} display={showBeachside} onChange={() => setShowBeachside(!showBeachside)} />
            {showBeachside && (
              <div className='grid grid-cols-4 gap-4 mt-4 mb-4'>
                {currentPatients && currentPatients['Beachside'] && 
                  currentPatients['Beachside'].map((patient: any) => 
                    patient.auth_status === statusFilter ? renderPatientTile(patient, 'Beachside') : null
                  )
                }
              </div>
            )}
            <DisplayAccordianHeader label={'Axis Census'} display={showAxis} onChange={() => setShowAxis(!showAxis)} />
            {showAxis && (
              <div className='grid grid-cols-4 gap-4 mt-4 mb-4'>
                {currentPatients && currentPatients['Axis'] && 
                  currentPatients['Axis'].map((patient: any) => 
                    patient.auth_status === statusFilter ? renderPatientTile(patient, 'Axis') : null
                  )
                }
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}

export default CensusScreen;