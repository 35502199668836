import { useState } from "react"
import { useChat } from "../../Context/ChatContext";

const IntellachatInput = () => {
  const [currentText, setCurrentText] = useState("");

  const {handleSubmitMessage} = useChat();

  const handleButtonClick = () => {
    if (currentText.trim() !== "") {
      handleSubmitMessage(currentText);
      setCurrentText(""); // Clear the input field after sending
    }
  };

  return (
  <div className="flex items-center min-w-full mt-2 space-x-1 rounded-md overflow-hidden">
    <input
      className="flex-grow rounded-sm p-2 bg-slate-300 mr-1 rounded-md overlfow-hidden"
      value={currentText}
      onChange={(e) => setCurrentText(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' && handleButtonClick()}
    />
    <button 
    className="inline-block ml- bg-primary rounded-md p-2 mt-2 mb-1 text-white"
    onClick={() => handleButtonClick()}
    > 
    Send</button>
  </div>
  )
}

export default IntellachatInput
