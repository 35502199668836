import CalendarSelectComponent from '../Inputs/CalendarSelectComponent'
import { useData } from '../../Context/DataContext'
import { useEffect } from 'react'

const CensusFilterComponent = () => {
  const { startDateCensus, endDateCensus, updateEndDateCensus, updateStartDateCensus, getCurrentPatients} = useData()

  // useEffect(() => {
  //   getCurrentPatients()
  // }, [startDateCensus, endDateCensus])

  return (
    <div className="h-full px-2 w-full flex flex-col justify-center items-center bg-primary-header dark:bg-slate-700 rounded-lg">
      <div className='w-full flex-1 flex flex-row items-center justify-center mt-1'>
        <div className='flex-1 flex flex-row items-center justify-center'>
          <p className='mx-2 min-w-16 max-w-24 text-black dark:text-white font-bold'>Date Range: </p>
          <CalendarSelectComponent selectedDate={startDateCensus} handleDateChange={updateStartDateCensus}/>
          <p className='mx-2 text-black dark:text-white font-bold'> - </p>
          <CalendarSelectComponent selectedDate={endDateCensus} handleDateChange={updateEndDateCensus}/>
        </div>
      </div>
    </div>
  )
}

export default CensusFilterComponent
