import React from 'react'
import LayoutComponent from '../BaseScreen'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { ClaimOptions } from '../../Options/ClaimOptions'
import AveaClaimsFilterComponent from "../../Components/SortAndFilter/AveaClaimsFilterComponent";
import {Tab} from "@mui/material";
import { useAuth } from '../../Context/AuthContext'
import { useClaims } from '../../Context/ClaimsContext'

const ClaimsAveaScreen = () => {
    const {aveaClaimsRecords} = useClaims()
    const {allUsers} = useAuth()

    return (

    <LayoutComponent
      header={
        <div className='h-20 w-full mb-2'>
          <AveaClaimsFilterComponent />
        </div>
      } 
      content={
        <div className="w-full px-4 mt-8" style={{ height: 'calc(100vh - 12rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent columns={ClaimOptions} records={aveaClaimsRecords} users={allUsers} table={'Avea Claims'} />
          </div>
        </div>
      }
    />
  )
}

export default ClaimsAveaScreen
