import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import baseUrl from '../Utils/baseUrl';
import { useData } from './DataContext';
import { start } from 'repl';
import { useNavigate } from 'react-router-dom';
import { DateUtilityFunctions } from '../Utils/DateUtilityFunctions';

interface historicRecordsProps {
  average_charge: string,
  average_paid: string,
  balance: string,
  facility: string,
  insurance: string,
  last_updated: string,
  name: string, 
  network: string,
  payout: number,
  ploicy_id: string,
  prefix: string
}

interface userInfoProps {
  name: string,
  insurance: string,
  policy: string,
  prefix: string,
  facility: string,
  dob: string,
}

interface UserFinancialProps {
  Balance: number,
  Charge_code: string,
  Charged: number
  Claim_ID: string,
  Date: string,
  FU_Note: string | null,
  Facility: string,
  Insurance: string,
  Paid: number,
  Patient_ID: string,
  Patient_birthday: string,
  Policy_ID: string,
  Primary_pay_amount: number,
  Status: string,
}

interface UserCareProps {
  admission_date: string,
  case_id: string,
  discharge_date: string,
  dob: string,
  facility: string,
  insurance_company: string,
  level_of_care: string,
  name: string,
  policy_id: string,
  prefix: string,
  ssn: string
}

interface CensusUserDetailsProps {
  claim_id: string,
  claim_status: string,
  cpt_code: string,
  end_date: string,
  rev_code: string,
  start_date: string,
  submission_date: string,
}

interface HistoricProps {
  average_charged: number;
  average_paid: number;
  balance: number;
  insurance: string;
  network: string;
  payout_ratio: number;
  prefix: string
}

interface HistoricContextType {
  prefixRecords: historicRecordsProps[] | null;
  selectedUserInfo: userInfoProps | null;
  selectedUserCare: UserCareProps[] | null;
  selectedUserFinancial: UserFinancialProps[] | null;
  singlePatientFinancial: UserFinancialProps[];
  censusUserDetails: CensusUserDetailsProps[];
  grabPrefixRecords: (selectedPrefix: string, selectedNetwork: string) => void;
  grabUserRecords: (selectedPolicy: string) => void;
  grabUserFinancialRecords: (selectedPolicy: string) => void;
  grabUserIntellaClaimRecord: (intellaClaimId: string) => void;
  setCensusUserDetails: (records: CensusUserDetailsProps[]) => void
  grabRecords: () => void
  searchHistoricRecords: (search: string) => void
  billingDetails: HistoricProps[] | null
}



const HistoricContext = createContext<HistoricContextType>({
  prefixRecords: null,
  selectedUserInfo: null,
  selectedUserCare: null,
  selectedUserFinancial: null,
  singlePatientFinancial: [],
  censusUserDetails: [],
  grabPrefixRecords: () => {},
  grabUserRecords: () => {},
  grabUserFinancialRecords: () => {},
  grabUserIntellaClaimRecord: () => {},
  setCensusUserDetails: () => {},
  grabRecords: () => {},
  searchHistoricRecords: () => {},
  billingDetails: []
});

export function useHistoric() {
  return useContext(HistoricContext);
}

interface AppProviderProps {
  children: ReactNode;
}

export const HistoricProvider: React.FC<AppProviderProps> = ({ children }) => {

  const [prefixRecords, setPrefixRecords] = useState<historicRecordsProps[] | null>(null)
  const [selectedUserInfo, setSelectedUserInfo] = useState<userInfoProps | null>(null)
  const [selectedUserCare, setSelectedUserCare] = useState<UserCareProps[] | null>(null)
  const [selectedUserFinancial, setSelectedUserFinancial] = useState<UserFinancialProps[] | null>(null)
  const [singlePatientFinancial, setSinglePatientFinancial] = useState<UserFinancialProps[]>([])
  const [censusUserDetails, setCensusUserDetails] = useState<CensusUserDetailsProps[]>([])
  const [billingDetails, setBillingDetails] = useState<HistoricProps[] | null>(null)

  const { currentTimeZone } = useData()

  const grabRecords = () => {
    const url = `${baseUrl}/level3`
    axios.get(url)
    .then((response) => {
      setBillingDetails(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabPrefixRecords = (selectedPrefix: string, selectedNetwork: string) => {
    const body = {
      'prefix': `${selectedPrefix}_${selectedNetwork}` ,
      'tz': currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level2/`,
      headers: { },
      data: body
    };
    axios.request(config)
    .then((response) => {
      setPrefixRecords(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabUserRecords = (selectedPolicy: string) => {
    const body = {
      'policy_id': selectedPolicy,
      'tz': currentTimeZone
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level1`,
      headers: { },
      data: body
    };
    axios.request(config)
    .then((response) => {
      let newUserDate = {
        name: response.data.patient_info[0].name || '',
        insurance: response.data.patient_info[0].insurance_company || '',
        policy: response.data.patient_info[0].policy_id || '',
        prefix: response.data.patient_info[0].prefix || '',
        facility: response.data.patient_info[0].facility || '',
        dob: response.data.patient_info[0].dob || '',
        // name: string,
        // insurance: string,
        // policy: string,
        // prefix: string,
        // facility: string,
        // dob: string,
      }
      setSelectedUserInfo(newUserDate)
      setSelectedUserFinancial(response.data.billing)
      setSelectedUserCare(response.data.patient_info)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabUserFinancialRecords = (selectedPolicy: string) => {
    const body = {
      'policy_id': selectedPolicy,
      'tz': currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level1`,
      headers: { },
      data: body
    };
    axios.request(config)
    .then((response) => {
      setSinglePatientFinancial(response.data.billing)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabUserIntellaClaimRecord = (intellaClaimId: string) => {
    const body = {
      'intella_claimid': intellaClaimId,
      'tz': currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/census_billing`,
      headers: { },
      data: body
    };
    axios.request(config)
    .then((response) => {
      const processedData = response.data.map((record: any) => {
        return record.uuid
      })
      setCensusUserDetails(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const searchHistoricRecords = (search: string) => {
    let searchResults:any = []
    if(search === ''){
      grabRecords()
    } else {
      billingDetails?.map((record: any) => {
        const reference = record.prefix.toLowerCase()
        if(reference.includes(search.toLowerCase())){
          searchResults.push(record)
        }
      })
      setBillingDetails(searchResults)
    }
    
  }

  const contextValue: HistoricContextType = {
    prefixRecords,
    selectedUserInfo,
    selectedUserCare,
    selectedUserFinancial,
    singlePatientFinancial,
    grabPrefixRecords,
    grabUserRecords,
    grabUserFinancialRecords,
    grabUserIntellaClaimRecord,
    censusUserDetails,
    setCensusUserDetails,
    grabRecords,
    searchHistoricRecords,
    billingDetails
  };

  return (
    <HistoricContext.Provider value={contextValue}>
      {children}
    </HistoricContext.Provider>
  );
};
