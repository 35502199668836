import React from 'react'
import LayoutComponent from '../BaseScreen'
import ClaimsFilterComponent from '../../Components/SortAndFilter/ClaimsFilterComponent'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { ClaimOptions } from '../../Options/ClaimOptions'
import { FollowupOptions } from '../../Options/FollowupOptions'
import FollowupFilterComponent from '../../Components/SortAndFilter/FollowupFilterComponent'
import { useFollowup } from '../../Context/FollowupContext'
import AveaFollowupFilterComponent from '../../Components/SortAndFilter/AveaFollowupFilterComponent'
import { useAuth } from '../../Context/AuthContext'

const FollowupAveaScreen = () => {

  const {followupAveaRecords, pendingAveaRecords, successfullAveaRecords, failedAveaRecords} = useFollowup()
  const {followupTab} = useFollowup()
  const {allUsers} = useAuth()

  return (
    <LayoutComponent 
      header={
        <div className='h-12 w-full mb-2'>
          <AveaFollowupFilterComponent/>
        </div>
      } 
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent table={'Followup Avea'} users={allUsers} columns={FollowupOptions} records={followupTab === 'ALL' ? followupAveaRecords : followupTab === 'PENDING' ? pendingAveaRecords : followupTab === 'SUCCESSFULL' ? successfullAveaRecords : failedAveaRecords }/>
          </div>
        </div>
      }
    />
  )
}

export default FollowupAveaScreen
