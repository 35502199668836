import React, {useMemo} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { startOfMonth, endOfMonth, eachMonthOfInterval, format, parseISO } from 'date-fns';

interface AvailityBillingDataProps {
  finalized_date: Date,
  payment_amount: string,
  facility: 'Affinity' | 'Beachside' | 'Axis'
}

interface FacilityBarChartProps {
  data: AvailityBillingDataProps[];
  startDate: Date;
  endDate: Date;
}


const FacilityBarChart: React.FC<FacilityBarChartProps> = ({ data, startDate, endDate }) => {
  
  const aggregateData = useMemo(() => {
    const monthIntervals = eachMonthOfInterval({ start: startDate, end: endDate });
    
    const aggregated = monthIntervals.map(monthStart => {
      const monthEnd = endOfMonth(monthStart);
      const monthTotal = data
        .filter(item => {
          const itemDate = new Date(item.finalized_date);
          return itemDate >= monthStart && itemDate <= monthEnd;
        })
        .reduce((sum, item) => sum + parseInt(item.payment_amount), 0);

      return {
        date: format(monthStart, 'yyyy-MM'),
        total: monthTotal
      };
    });

    return aggregated;
  }, [data, startDate, endDate]);

  const yAxisDomain = useMemo(() => {
    const maxValue = Math.max(...aggregateData.map(item => item.total));
    const yAxisMax = Math.ceil(maxValue * 1.4); // Increase the max by 20%
    return [0, yAxisMax] as [number, number];
  }, [aggregateData]);

  const xAxisTicks = useMemo(() => {
    const numberOfMonths = aggregateData.length;
    const tickInterval = Math.max(1, Math.floor(numberOfMonths / 6)); // Show about 6 ticks
    return aggregateData
      .filter((_, index) => index % tickInterval === 0)
      .map(item => item.date);
  }, [aggregateData]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={aggregateData}>
        <CartesianGrid strokeDasharray="3 3" stroke='grey' />
        <XAxis 
          dataKey="date" 
          tickFormatter={(value) => format(parseISO(value), 'MMM yyyy')}
          tickMargin={10}
          interval={0}
          ticks={xAxisTicks}
        />
        <YAxis domain={yAxisDomain} />
        <Tooltip 
          labelFormatter={(value) => format(parseISO(value), 'MMMM yyyy')}
          formatter={(value) => [`$${value}`, 'Total']}
        />
        <Legend />
        <Bar dataKey="total" fill="#fcba03" name="Total" barSize={160} maxBarSize={160}/>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FacilityBarChart;