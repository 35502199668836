import React from 'react'
import LayoutComponent from '../BaseScreen';
import AvailityComponent from './Availity/AvailityComponent';
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import { useNavigate, useLocation } from 'react-router-dom'
import { useNavigation } from '../../Context/NavigationContext';
import { useData } from '../../Context/DataContext';
import AvailityHeader from './Availity/AvailityHeader';
import { useClaims } from '../../Context/ClaimsContext';



const ClaimsAvailityScreen = () => {
  const {currentSidebarSubTab} = useNavigation();
  const {currentSidebarTab, handleUpdateCurrentSidebarTab, handleUpdateCurrentSidebarSubTab} = useNavigation();
  const {availityData, page} = useData();
  const {claimsRecords} = useClaims()
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <LayoutComponent
      header={
      <div className='mb-3'>
      <ButtonComponent 
      label='BACK' 
      handler=
      {() => {if (currentSidebarTab === "Claims" && currentSidebarSubTab === "Avea") navigate('/claims/avea');
              if (currentSidebarTab === "Claims" && currentSidebarSubTab === "Collab Md") {handleUpdateCurrentSidebarSubTab('Collab Md')
                  navigate(-1);}
              if (currentSidebarTab === "Follow Up" && currentSidebarSubTab === "Collab Md") navigate('/follow-up/collab');
              if (currentSidebarTab === "Follow Up" && currentSidebarSubTab === "Avea") navigate('/follow-up/avea');
              if (currentSidebarTab === "Follow Up" && currentSidebarSubTab === "Billing Census") navigate('/follow-up/census')
              if (currentSidebarTab === "Claims" && currentSidebarSubTab === "Billing Census") navigate('/claims/census')
              }
      }
       />
      <AvailityHeader />
      </div>
      } // Render your custom header component here
      content={
      <div>
        <AvailityComponent />
      </div>} // Render your custom content component here
    />
  )
}

export default ClaimsAvailityScreen;
