export const intakeAdminColumns = [
  {
    label:'Notes',
    type:'popup',
    recordName: '',
    width: '10rem'
  },
  {
    label:'Details',
    type:'clickable',
    recordName: '',
    width: 'rem'
  },
  {
    label:'Date',
    type:'date',
    recordName: 'created_date',
    width: '10rem'
  },
  {
    label:'Client',
    type:'name-edit',
    recordName: 'name',
    width: '13rem'
  },
  {
    label:'Insurance',
    type:'insurance-edit',
    recordName: 'insurance',
    width: '22rem'
  },
  {
    label:'Coordinator',
    type:'people',
    recordName: 'coordinator',
    people: [],
    width: '12rem',
    admin: true
  },
  {
    label:'Status',
    type:'select',
    options: [
      'Pending',
      'Approved',
      'Approved BD',
      'Approved RTC',
      'Approved LB No RTC',
      'Private Pay',
      'Denied'
    ],
    recordName: 'checked_in',
    width: '12rem',
    admin: true
  },
  {
    label:'Booked',
    type:'select',
    options: [
      'Pending',
      'Arrived',
      'MIA',
      'Booked',
      'Booked BD',
      'Yellow Stripe',
      'Clinically Denied'
    ],
    dependent: 'checked_in',
    dependentResults: [
      'Approved',
      'Approved BD',
      'Approved RTC',
      'Approved LB No RTC',
      'Private Pay'
    ],
    recordName: 'booked',
    width: '12rem',
    admin: true
  },
  {
    label:'Reason',
    type:'select',
    options: [
      'Pending',
      'Lost Contact',
      'Unwilling To Travel',
      'Booked Out',
      'Other - See Notes'
    ],
    dependent: 'booked',
    dependentResults: [
      'MIA',
      'Yellow Stripe'
    ],
    recordName: 'reason',
    width: '12rem',
    admin: true
  },
  {
    label:'Arriving Date',
    type:'select_date',
    dependent: 'booked',
    dependentResults: [
      'Booked',
    ],
    recordName: 'expected_arrival_date',
    width: '12rem',
    admin: true
  },
  {
    label:'Facility',
    type:'select',
    options: [
      'Not Set',
      'Affinity',
      'Beachside',
      'Axis',
    ],
    recordName: 'facility',
    width: '8rem',
    admin: true
  },
  {
    label:'DOB',
    type:'date-edit',
    recordName: 'date_of_birth',
    width: '12rem'
  },
  {
    label:'Prefix',
    type:'clickable',
    recordName: 'prefix',
    width: '8rem',
    admin: true
  },
  {
    label:'Policy',
    type:'policy-edit',
    recordName: 'policy_id',
    width: '14rem'
  },
  // {
  //   label:'Active',
  //   type:'boolean',
  //   recordName: 'active',
  //   width: '52'
  // },
  {
    label:'Verify TX',
    type:'clickable',
    recordName: '',
    width: '10rem',
    admin: true
  },
  // {
  //   label:'INN Admit %',
  //   type:'text',
  //   recordName: '',
  //   width: '52'
  // },
  // {
  //   label:'INN Admit',
  //   type:'text',
  //   recordName: '',
  //   width: '32'
  // },
  // {
  //   label:'INN Ded',
  //   type:'dollar',
  //   recordName: 'inn_deductible',
  //   width: '52'
  // },
  // {
  //   label:'INN OOP',
  //   type:'dollar',
  //   recordName: 'in_network_oop',
  //   width: '52'
  // },
  // {
  //   label:'OON Admit %',
  //   type:'text',
  //   recordName: '',
  //   width: '52'
  // },
  // {
  //   label:'OON Admit',
  //   type:'text',
  //   recordName: '',
  //   width: '32'
  // },
  // {
  //   label:'OON Ded',
  //   type:'dollar',
  //   recordName: 'onn_deductible',
  //   width: '52'
  // },
  // {
  //   label:'OON OOP',
  //   type:'dollar',
  //   recordName: 'out_network_oop',
  //   width: '52'
  // },
  {
    label:'Phone',
    type:'popup',
    recordName: 'phone_number',
    width: '52',
    admin: true
  },
  {
    label:'Emergency Name',
    type:'text',
    recordName: 'emergency_contact',
    width: '12rem',
    admin: true
  },
  {
    label:'Emergency Phone',
    type:'text',
    recordName: 'emergency_number',
    width: '13rem',
    admin: true
  },
  {
    label:'Source',
    type:'text',
    recordName: 'source',
    width: '12rem'
  },
]