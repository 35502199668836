import React, { useState } from 'react'
import SingleClickTabComponent from '../Navigation/SingleClickTabComponent'
import PaginationComponent from '../Pagination/PaginationComponent'
import ButtonComponent from '../Inputs/ButtonComponent'
import SelectOptionComponent from '../Tables/SelectOptionComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { X } from 'react-feather'
import CreateClaimForm from '../Forms/CreateClaimForm'
import { useNavigation } from '../../Context/NavigationContext'
import { useHistoric } from '../../Context/HistoricContext'

const CensusUserFilterComponent = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const [addRecord, setAddRecord] = useState<boolean>(false)

  const {handleUpdateCurrentSidebarTab} = useNavigation()
  const {setCensusUserDetails} = useHistoric()

  const goBack = () => {
    setCensusUserDetails([])
    navigate(-1);
  }

  return (
    <div className="h-14 px-2 w-full flex flex-col justify-center bg-primary-header dark:bg-slate-700 rounded-lg mb-4">
      <div className='w-full flex flex-row items-center'>
        <ButtonComponent label='Go Back' handler={goBack}/>
      </div>
    </div>
  )
}

export default CensusUserFilterComponent
