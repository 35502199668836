export const ClaimOptions = [
  {
    label:'Select',
    type:'checkbox',
    recordName: '',
    width: '5rem',
    sort: false
  },
  {
    label:'Coordinator',
    type:'people',
    recordName: 'userid',
    people: [],
    width: '16rem',
    sort: false,
  },
  {
    label:'Claim Id',
    type:'text',
    recordName: 'claim_id',
    width: '10rem',
    sort: false
  },
  {
    label:'Show Notes',
    type:'popup',
    recordName: '',
    width: '10rem'
  },
  // {
  //   label:'New Claim Id',
  //   type:'text',
  //   recordName: 'new_claim_id',
  //   width: '52',
  //   sort: false
  // },
  {
    label:'Name',
    type:'clickable',
    recordName: 'name',
    width: '20rem',
    sort: true
  },
  {
    label:'DOB',
    type:'text',
    recordName: 'dob',
    width: '9rem',
    sort: false
  },
  {
    label:'Insurance',
    type:'text',
    recordName: 'insurance',
    width: '22rem',
    sort: true
  },
  {
    label:'Policy',
    type:'text',
    recordName: 'policy_id',
    width: '12rem',
    sort: false
  },
  {
    label:'Facility',
    type:'text',
    recordName: 'facility',
    width: '22rem',
    sort: false
  },
  {
    label:'Network',
    type:'text',
    recordName: 'network',
    width: '14rem',
    sort: false
  },
  {
    label:'Status',
    type:'text',
    options: [
      'CLAIM AT INSURANCE', 
      'SEND TO INSURANCE VIA CLEARINGHOUSE',
      'PENDING INSURANCE AUTH',
      'REJECTED AT INSURANCE',
      'WRITEOFF',
      'BALANCE DUE PATIENT',
      'DENIED AT INSURANCE',
      'PAID'
    ],
    recordName: 'status',
    width: '18rem',
    sort: false
  },
  {
    label:'Notes',
    type:'note',
    recordName: 'fu_note',
    width: '20rem',
    sort: false
  },
  {
    label:'Charged Total',
    type:'dollar',
    recordName: 'charged_total',
    width: '10rem',
    sort: false
  },
  {
    label:'Paid Total',
    type:'dollar',
    recordName: 'paid_total',
    width: '10rem',
    sort: true
  },
  {
    label:'Payout %',
    type:'percent',
    recordName: 'payout_ratio',
    width: '10rem',
    sort: true
  },
  {
    label:'Balance Total',
    type:'dollar',
    recordName: 'balance_total',
    width: '10rem',
    sort: false
  },
  {
    label:'Start Date',
    type:'date',
    recordName: 'start_date',
    width: '12rem',
    sort: false
  },
  {
    label:'End Date',
    type:'date',
    recordName: 'end_date',
    width: '12rem',
    sort: true
  },
]