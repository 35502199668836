import React, { useEffect, useState } from 'react'
import SearchComponent from '../Inputs/SearchComponent'
import { useClaims } from '../../Context/ClaimsContext'
import ButtonComponent from '../Inputs/ButtonComponent'
import CalendarSelectComponent from '../Inputs/CalendarSelectComponent'
import SelectComponent from '../Inputs/SelectComponent'
import PercentOptionComponent from '../Inputs/PercentOptionComponent'
import SelectInputComponent from '../Inputs/SelectInputComponent'
import { useData } from '../../Context/DataContext'
import { start } from 'repl'
import { faC } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../Pagination/PaginationComponent'
import { useFollowup } from '../../Context/FollowupContext'
import SelectInputColumns from '../Inputs/SelectInputColumns'
import { ChevronLeft } from 'react-feather'

const FollowupFilterComponent = () => {

  const {selectedFollowup, updateFollowupTab, 
    followupTab, submuttingData, submitBatchToCollab, toggleToShowColumnFollowup, selectedColumnsFollowup, insuranceFilterFollowUp,
    handleInsuranceFilter, getRefreshClaimsFollowupTwo, handleClaimsFollowupTermUpdate
  } = useFollowup()
  const { handleFollowupClaimsFacilityChange,
    handleFollowupClaimsStatusChange,
    followupClaimsStatus,
    followupClaimsFacility, claimsFollowupTerm} = useFollowup()
  const {insuranceOptions} = useData()

  useEffect(() => {
    getRefreshClaimsFollowupTwo()
  }, [followupClaimsStatus, followupClaimsFacility, insuranceFilterFollowUp, claimsFollowupTerm])

  const [activeClaimSearch, setActiveClaimSearch] = useState<boolean>(false)

  const handleActiveChange = () => {
    setActiveClaimSearch(!activeClaimSearch)
  }

  const facilityOptions = ['All', 'Affinity', 'Beachside', 'Axis']
  const statusOptions = [
                          'ALL',
                          'CLAIM AT INSURANCE', 
                          'SEND TO INSURANCE VIA CLEARINGHOUSE',
                          'PENDING INSURANCE AUTH',
                          'REJECTED AT INSURANCE',
                          'WRITEOFF',
                          'BALANCE DUE PATIENT',
                          'DENIED AT INSURANCE',
                          'PAID'
                        ]
const claimsColumnsOptinos = [
                          'Select',
                          'Coordinator',
                          'Claim Id',
                          'New Claim Id',
                          'In Collab',
                          'Show Notes',
                          'Name',
                          'DOB',
                          'Policy',
                          'Insurance',
                          'Facility',
                          'Network',
                          'Status',
                          'Notes',
                          'Charged Total',
                          'Paid Total',
                          'Payout %',
                          'Balance Total',
                          'Start Date',
                          'End Date'
                        ]

  const insuranceFilterOptions = insuranceOptions ? insuranceOptions.map(object => object.label ? object.label : 'N/A') : ['None']; insuranceFilterOptions.unshift('All')

  const handleFacilityChange = (data: string) => {
    handleFollowupClaimsFacilityChange(data)
  }

  const handleStatusChange = (data: string) => {
    handleFollowupClaimsStatusChange(data)
  }

  const handleUpdatingSearch = (text: string) => {
    updateFollowupTab('ALL')
    handleClaimsFollowupTermUpdate(text)
  }

  return (
    <div className="h-22 px-2 w-full flex flex-col justify-center bg-primary-header dark:bg-slate-700 rounded-lg mb-4">
      <div className='w-full flex flex-row items-center justify-between'>
        <div>
          {
            selectedFollowup.length > 0
              ? <ButtonComponent label={submuttingData ? 'Submitting' : 'Submit To Collab'} handler={submitBatchToCollab}/>
              : null
          }
        </div>
        <div className='flex-1 mr-2 '>
          <SearchComponent searchTerm={claimsFollowupTerm} handler={handleUpdatingSearch}
          placeholder='Search name...' />
        </div>
        <div className='flex flex-row items-center border-2 border-primary p-.5 rounded-full ml-2'>
          <p className='mx-2 min-w-16 text-black dark:text-white font-bold'>Denied Claims</p>
          <ChevronLeft height={24} width={24} className='text-black dark:text-white'/>
        </div>
        <div className='flex flex-col items-center'>
          <div className='w-full flex flex-row items-center'>
          <div className='flex flex-row'>
          </div>
        </div>
      </div>
    </div>
    <div className='flex flex-row items-center justify-end'>
      <div className='flex flex-row'>
        <div className='bg-stone-500 flex flex-row rounded-lg hover:cursor-pointer'>
          <div onClick={() => {updateFollowupTab('ALL')}} className={`${followupTab === 'ALL' ? 'bg-primary dark:bg-secondary p-2 py-1 text-sm rounded-lg font-bold' : 'bg-stone-500  p-2 py-1 text-sm rounded-lg font-bold' } text-white`}>
            <p>ALL</p>
          </div>
          <div onClick={() => {updateFollowupTab('PENDING')}} className={`${followupTab === 'PENDING' ? 'bg-primary dark:bg-secondary p-2 py-1 text-sm w-36 rounded-lg font-bold' : 'bg-stone-500  p-2 py-1 w-36 text-sm rounded-lg font-bold' } text-white`}>
            <p>TO BE SUBMITTED</p>
          </div>
          <div onClick={() => {updateFollowupTab('SUCCESSFULL')}} className={`${followupTab === 'SUCCESSFULL' ? 'bg-primary dark:bg-secondary p-2 py-1 text-sm rounded-lg font-bold' : 'bg-stone-500  p-2 py-1 text-sm rounded-lg font-bold' } text-white`}>
            <p>SUCCESSFULL</p>
          </div>
          <div onClick={() => {updateFollowupTab('FAILED')}} className={`${followupTab === 'FAILED' ? 'bg-primary dark:bg-secondary p-2 py-1 text-sm rounded-lg font-bold' : 'bg-stone-500  p-2 py-1 text-sm rounded-lg font-bold' } text-white`}>
            <p>FAILED</p>
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center'>
        <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Columns: </p>
        <SelectInputColumns options={claimsColumnsOptinos} showColumnFunction={toggleToShowColumnFollowup} columnsList={selectedColumnsFollowup}/>
      </div>
        <div className='flex flex-row items-center'>
          <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dark:text-white font-bold'>Facility: </p>
          <SelectInputComponent placeholder='All' options={facilityOptions} selectedValue={followupClaimsFacility} handleOptionClick={handleFacilityChange}/>
        </div>
        <div className='flex flex-row items-center'>
          <p className=' ml-6 min-w-16 max-w-24 text-black dark:text-white font-bold'>Status: </p>
          <SelectInputComponent placeholder='ALL' options={statusOptions} selectedValue={followupClaimsStatus} handleOptionClick={handleStatusChange}/>
        </div>
      </div>
      {/* <div className='flex flex-row'>
        <p className='mx-2 ml-6 min-w-16 max-w-24 text-black dark:text-white font-bold'>Insurance: </p>
        <SelectInputComponent placeholder='ALL' options={insuranceFilterOptions} selectedValue={insuranceFilterFollowUp} handleOptionClick={handleInsuranceFilter} />
      </div> */}
    </div>
  )
}

export default FollowupFilterComponent
