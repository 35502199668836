import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import baseUrl from '../Utils/baseUrl';
import { DateUtilityFunctions } from '../Utils/DateUtilityFunctions';
import { useData } from './DataContext';
import { useClaims } from './ClaimsContext';

interface FollowupContextType {
  selectedFollowup: string[];
  pushingToFollowup: boolean;
  allFollowup: boolean;
  followupTab: string;
  submuttingData: boolean;
  collabFormData: any;
  remindersData: RemindersDataProps[];
  fetchingRemindersData: boolean;
  showReminder: boolean;
  billingAnalyticsCount: ReformattedDataType[]
  setCollabDisableEdit: any;
  collabDisableEdit: boolean;
  selectedColumnsFollowup: string[];
  selectedColumnsFollowupCensus: string[]
  selectedColumnsFollowupAvea: string[]
  insuranceFilterFollowUp: string
  followupRecords: FolloupProps[] | null;
  pendingRecords: FolloupProps[] | null;
  successfullRecords: FolloupProps[] | null;
  failedRecords: FolloupProps[] | null;
  followUpCensusInsurance: string,
  followupAveaRecords: ClaimsProps[] | null,
  pendingAveaRecords: ClaimsProps[] | null,
  successfullAveaRecords: ClaimsProps[] | null,
  failedAveaRecords: ClaimsProps[] | null,
  handleFacilityChange: (data: string) => void,
  handleStatusChange: (data: string) => void, 
  handleSortColumnChange: (text: string) => void,
  handleSortOrderChange: (value: boolean) => void,
  status: string,
  claimsSearch: string,
  activeClaimSearch: boolean,
  followupCensusRecords: any[],
  updateSelectedFollowup: (text: string) => void;
  addBatchToFavorites: () => void;
  selectAllFollowup: (records: any) => void;
  unselectAllFollowup: () => void;
  updateFollowupTab: (text: string) => void;
  updateCoordinatorFollwup: (claim_id: string, coordinator: string) => void;
  submitBatchToCollab: () => void; 
  removeBatchToFavorites: (claim_id: string) => void;
  fetchAutomateCollab: (claim_id: string) => void;
  setCollabFormData: (array: CollabFormData[]) => void;
  fetchReminders: () => void;
  handleStartDate: (date: Date) => void,
  handleEndDate: (date: Date) => void,
  handleMinPercent: (data: string) => void,
  handleMaxPercent: (date: string) => void,
  setShowReminder: (showReminder: boolean) => void;
  submitCensusBatchToCollab: () => void
  updateCoordinatorClaims: (claim_id: string, coordinator: string) => void
  updateFollowupClaim: (claim_id: string, coordinator: string | null, status: string | null) => void
  toggleToShowColumnFollowup: (text: string) => void
  toggleToShowColumnFollowupAvea: (text: string) => void
  toggleToShowColumnFollowupCensus: (text:string) => void
  grabBillingCount: (timeSpan: number, startDate?: string, endDate?: string) => void;
  handleInsuranceFilter: (insurance: string) => void;
  getRefreshClaimsFollowupTwo: () => void;
  handleClaimsFollowupTermUpdate: (data: any) => void;
  searchClaimsFollowup: (data: any) => void
  handleInsuranceFollowUpCensus: (insurance: string) => void
  getRefreshFollowupCensusRecords: () => void
  handleSearchFOllowupFilter: (text: string) => void
  handleFollowupCensusSearchChange: (text: string) => void
  handleFollowupClaimsStatusChange: (text: string) => void
  handleFollowupClaimsFacilityChange: (text:string) => void
  getClaimsFollowup: () => void
  getRefreshAveaFollowup: (facility: string, status: string,) => void,
  page: number, 
  startDate: Date,
  endDate: Date, 
  minPercent: number, 
  maxPercent: number, 
  facility: string, 
  cptCode: string, 
  loc: string,
  handlePageChange: (page: number) => void,
  handleCptChange: (date: string) => void,
  handleLocChange: (text: string) => void,
  searchFollowupCensus: string,
  followupClaimsStatus: string,
  followupClaimsFacility: string,
  claimsFollowupTerm: string
}

const FollowupContext = createContext<FollowupContextType>({
  selectedFollowup: [],
  allFollowup: false,
  pushingToFollowup: false,
  followupTab: 'ALL',
  submuttingData: false,
  collabFormData: [],
  setCollabFormData: () => {},
  remindersData: [],
  fetchingRemindersData: false,
  showReminder: false,
  setCollabDisableEdit: false,
  collabDisableEdit: false,
  billingAnalyticsCount: [],
  selectedColumnsFollowup: [],
  selectedColumnsFollowupCensus: [],
  selectedColumnsFollowupAvea: [],
  insuranceFilterFollowUp: "All",
  followupRecords: null,
  pendingRecords: null,
  successfullRecords: null,
  failedRecords: null,
  followUpCensusInsurance: "All",
  followupAveaRecords: [],
  pendingAveaRecords: [] ,
  successfullAveaRecords: [] ,
  failedAveaRecords: [] ,
  handleFacilityChange: () => {},
  handleStatusChange: () => {}, 
  handleSortColumnChange: () => {},
  handleSortOrderChange: () => {},
  status: 'ALL',
  claimsSearch: '',
  activeClaimSearch: false,
  followupCensusRecords: [],
  updateSelectedFollowup: () => {},
  addBatchToFavorites: () => {},
  selectAllFollowup: () => {},
  unselectAllFollowup: () => {},
  updateFollowupTab: () => {},
  updateCoordinatorFollwup: () => {},
  submitBatchToCollab: () => {},
  removeBatchToFavorites: () => {},
  fetchAutomateCollab: () => {},
  fetchReminders: () => {},
  setShowReminder: () => {},
  handleStartDate: () => {},
  handleEndDate: () => {},
  handleMinPercent: () => {},
  handleMaxPercent: () => {},
  submitCensusBatchToCollab: () => {},
  updateCoordinatorClaims: () => {},
  updateFollowupClaim: () => {},
  toggleToShowColumnFollowup: () => {},
  toggleToShowColumnFollowupAvea: () => {},
  toggleToShowColumnFollowupCensus: () => {},
  grabBillingCount: () => {},
  handleInsuranceFilter: () => {},
  getRefreshClaimsFollowupTwo: () => {},
  handleClaimsFollowupTermUpdate: () => {},
  searchClaimsFollowup: () => {},
  handleInsuranceFollowUpCensus: () => {},
  getRefreshFollowupCensusRecords: () => {},
  handleSearchFOllowupFilter: () => {},
  handleFollowupCensusSearchChange: () => {},
  handleFollowupClaimsStatusChange: () => {},
  handleFollowupClaimsFacilityChange: () => {},
  getClaimsFollowup: () => {},
  getRefreshAveaFollowup: () => {},
  page: 0, 
  startDate: new Date('Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)'),
  endDate: new Date(), 
  minPercent: 0, 
  maxPercent: 100, 
  facility: 'ALL', 
  cptCode: 'ALL', 
  loc: 'ALL',
  handlePageChange: () => {},
  handleCptChange: () => {},
  handleLocChange: () => {},
  searchFollowupCensus: '',
  followupClaimsStatus: 'ALL',
  followupClaimsFacility: 'ALL',
  claimsFollowupTerm: ''
});

export function useFollowup() {
  return useContext(FollowupContext);
}

export interface CollabFormData {
  facility: string,
  start_date: string,
  end_date: string,
  charge_code: string,
  first_name : string,
  last_name: string,
  claim_id: string,
  gender: string,
  street_address: string,
  city: string,
  state: string,
  zip: string,
  policy_id: string,
  group_id: string,
  insurance_company: string,
  dx_codes: string,
  dob: string,
  admission_date: string,
  discharge_date: string
}

type RecordType = {
  balance_total: string;
  charged_total: string;
  claim_id: string;
  claim_status: string | null;
  coordinator: string | null;
  dob: string;
  end_date: string;
  facility: string;
  favorites: boolean;
  fu_note: string;
  insurance: string;
  name: string;
  network: string;
  new_claim_id: string;
  paid_total: string;
  payout_ratio: string;
  policy_id: string;
  start_date: string;
  status: string;
  submission_date: string;
  userid: string | null;
};

type ReformattedDataType = {
  submission_date: string;
  total_records: number;
  pending: number;
  successful: number;
  failed: number;
};
        
interface RemindersDataProps {
  claim_id: string,
  date: string
}

interface AppProviderProps {
  children: ReactNode;
}

interface FolloupProps {
  balance_total: number,
  charged_total: number,
  claim_id: string,
  claim_status: string,
  coordinator: string,
  end_date: string,
  facility: string,
  favorites: number,
  fu_note: string | null,
  name: string,
  network: string,
  paid_total: number,
  payout_ratio: boolean,
  start_date: string,
  status: string
}

interface ClaimsProps {
  balance_total: number,
  charged_total: number,
  claim_id: string,
  claim_status: string,
  coordinator: string,
  end_date: string,
  facility: string,
  favorites: number,
  fu_note: string | null,
  name: string,
  network: string,
  paid_total: number,
  payout_ratio: boolean,
  start_date: string,
  status: string
}

export const FollowupProvider: React.FC<AppProviderProps> = ({ children }) => {
  const {grabClaims} = useClaims()
  const {currentTimeZone} = useData()

  const [selectedFollowup, setSelectedFollowup] = useState<string[]>([])
  const [pushingToFollowup, setPushingToFollowup] = useState<boolean>(false)
  const [allFollowup, setAllFollowup] = useState<boolean>(false)
  const [followupTab, setFollowupTab] = useState<string>('ALL')
  const [submuttingData, setSubmittingData] = useState<boolean>(false)
  const [collabFormData, setCollabFormData] = useState<CollabFormData[]>([])
  const [remindersData, setCurrentRemindersData] = useState<RemindersDataProps[]>([])
  const [fetchingRemindersData, setFetchingRemindersData] = useState<boolean>(false)
  const [showReminder, setShowReminder] = useState(false);
  const [billingAnalyticsCount, setBillingAnalyticsCount] = useState<ReformattedDataType[]>([])
  const [collabDisableEdit, setCollabDisableEdit] = useState<boolean>(false)
  const [selectedColumnsFollowup, setSelectedColumnsFollowup] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'New Claim Id',
    'In Collab',
    'Show Notes',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Network',
    'Status',
    'Notes',
    'Charged Total',
    'Paid Total',
    'Payout %',
    'Balance Total',
    'Start Date',
    'End Date'
  ])
  const [selectedColumnsFollowupAvea, setSelectedColumnsFollowupAvea] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'New Claim Id',
    'In Collab',
    'Show Notes',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Network',
    'Status',
    'Notes',
    'Charged Total',
    'Paid Total',
    'Payout %',
    'Balance Total',
    'Start Date',
    'End Date'
  ])
  const [selectedColumnsFollowupCensus, setSelectedColumnsFollowupCensus] = useState<string[]>([
    'Select',
    'Coordinator',
    'Claim Id',
    'New Claim Id',
    'In Collab',
    'CPT Code',
    'Auth. Code',
    'LOC',
    'Name',
    'DOB',
    'Policy',
    'Insurance',
    'Facility',
    'Start Date',
    'End Date',
    'Submission Date'
  ])
  const [insuranceFilterFollowUp, setInsuranceFilterFollowUp] = useState<string>("All")
  const [followupRecords, setFollowupClaims] = useState<ClaimsProps[] | null>(null)
  const [pendingRecords, setPendingRecords] = useState<ClaimsProps[] | null>(null)
  const [successfullRecords, setSuccessfulRecords] = useState<ClaimsProps[] | null>(null)
  const [failedRecords, setFailedRecords] = useState<ClaimsProps[] | null>(null)

  const [followUpCensusInsurance, setFollowUpCensusInsurance] = useState<string>("All")
  const [claimsSearch, setClaimsSearch] = useState<string>('')
  const [activeClaimSearch, setActiveClaimSearch] = useState<boolean>(false)
  const [searchFollowupCensus, setSearchFollowupCensus] = useState<string>('')

  const [followupClaimsFacility, setFollowupClaimsFacility] = useState('All')
  const [followupClaimsStatus, setFollowupClaimsStatus] = useState('ALL')

  const [followupStartDate, setFollowupStartDate] = useState(new Date("Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)"));
  const [followupEndDate, setFollowupEndDate] = useState(new Date());

  const [followupCensusRecords, setFollowupCensusRecords] = useState<any[]>([])

  const [followupAveaRecords, setFollowupAveaClaims] = useState<ClaimsProps[] | null>(null)
  const [pendingAveaRecords, setPendingAveaRecords] = useState<ClaimsProps[] | null>(null)
  const [successfullAveaRecords, setSuccessfulAveaRecords] = useState<ClaimsProps[] | null>(null)
  const [failedAveaRecords, setFailedAveaRecords] = useState<ClaimsProps[] | null>(null)

  const [cptCode, setCptCode] = useState<string>('All')
  const [loc, setLoc] = useState<string>('All')

  const [page, setPage] = useState<number>(() => {
    const storedPage = localStorage.getItem('page');
    return storedPage !== null ? parseInt(storedPage, 10) : 0;
  });

  const [startDate, setStartDate] = useState<Date>(() => {
    const storedDate = localStorage.getItem('startDate');
    return storedDate !== null ? new Date(storedDate) : new Date('Wed Jan 31 2018 16:00:00 GMT-0800 (Pacific Standard Time)');
  });
  const [endDate, setEndDate] = useState<Date>(() => {
    const storedDate = localStorage.getItem('endDate');
    return storedDate !== null ? new Date(storedDate) : new Date();
  })
  const [minPercent, setMinPercent] = useState<number>(() => {
    const storedMinPercent = localStorage.getItem('minPercent');
    return storedMinPercent !== null ? parseFloat(storedMinPercent) : 0;
  });  
  const [maxPercent, setMaxPercent] = useState<number>(() => {
    const storedMinPercent = localStorage.getItem('maxPercent');
    return storedMinPercent !== null ? parseFloat(storedMinPercent) : 100;
  })

  const [claimsFollowupTerm, setClaimsFollowupTerm] = useState<string>('')

  const [facility, setFacility] = useState<string>(localStorage.getItem('facility') || 'All')
  const [status, setStatus] = useState<string>(localStorage.getItem('status') || 'All')
  const [column, setColumn] = useState<string>(localStorage.getItem('sortColumn') || 'end_date')
  const [ascending, setAscending] = useState<boolean>(localStorage.getItem('sortOrder') === 'true' ? true : false || false)

  const handleStartDate = (date: Date) => {
    setStartDate(date)
    localStorage.setItem('startDate', date.toString());
  }

  const handleEndDate = (date: Date) => {
    setEndDate(date)
    localStorage.setItem('endDate', date.toString());
  }

  const handleMinPercent = (data: string) => {
    setMinPercent(parseInt(data))
    localStorage.setItem('minPercent', data.toString());
  }

  const handleMaxPercent = (data: string) => {
    setMaxPercent(parseInt(data))
    localStorage.setItem('maxPercent', data.toString());
  }

  const handleInsuranceFollowUpCensus = (insurance: string) => {
    setFollowUpCensusInsurance(insurance)
  }

  const handleSearchFOllowupFilter = (text: string) => {
    setSearchFollowupCensus(text)
  }

  const handleFollowupCensusSearchChange = (text: string) => {
    text === ''
      ? setActiveClaimSearch(false)
      : setActiveClaimSearch(true)
    setClaimsSearch(text)
    grabSearchByNameFollowupCensus(text)
  }

  const handleFollowupClaimsStatusChange = (text: string) => {
    setFollowupClaimsStatus(text)
  }

  const handleFollowupClaimsFacilityChange = (text: string) => {
    setFollowupClaimsFacility(text)
  }

  const handleFacilityChange = (data: string) => {
    setFacility(data)
    localStorage.setItem('facility', data.toString());
  }

  const handleStatusChange = (data: string) => {
    setStatus(data)
    localStorage.setItem('status', data.toString());
  }

  const handleSortColumnChange = (text:string) => {
    setColumn(text)
    localStorage.setItem('sortColumn', text);
  }

  const handleSortOrderChange = (value: boolean) => {
    setAscending(value)
    localStorage.setItem('sortOrder', value.toString());
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('page', page.toString());
  }

  const handleCptChange = (data: string) => {
    setCptCode(data)
  }

  const handleLocChange = (text: string) => {
    setLoc(text)
  }

  const toggleToShowColumnFollowup = (text: string) => {
    setSelectedColumnsFollowup((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const toggleToShowColumnFollowupAvea = (text: string) => {
    setSelectedColumnsFollowupAvea((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const toggleToShowColumnFollowupCensus = (text: string) => {
    setSelectedColumnsFollowupCensus((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const updateFollowupTab = (text: string) => {
    setFollowupTab(text)
  } 


  useEffect(() => {
  }, [selectedFollowup])

  const updateSelectedFollowup = (text: string) => {
    setSelectedFollowup(prevSelectedFollowup => {
      if (prevSelectedFollowup.includes(text)) {
        return prevSelectedFollowup.filter(item => item !== text);
      } else {
        return [...prevSelectedFollowup, text];
      }
    });
  }

  const getRefreshAveaFollowup = (
    facility: string, 
    status: string,
  ) => {
    let pendingRecords: any = []
    let successfulRecords: any = []
    let rejectedRecords: any = []
    const requestData = {
      'start_date': formatDate(followupStartDate),
      'end_date': formatDate(followupEndDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 0,
      "facilities":facility,
      "status": status
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/avea_favorites`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };
    axios.request(config)
    .then((response) => {
      response.data.map((record: any) => {
        record.claim_status === 'Successful'
          ? successfulRecords.push(record)
          : record.claim_status === 'pending'
              ? pendingRecords.push(record)
              : record.claim_status == 'Failed'
                  ? rejectedRecords.push(record)
                  : pendingRecords.push(record)
      })
      setFailedAveaRecords(rejectedRecords)
      setSuccessfulAveaRecords(successfulRecords)
      setPendingAveaRecords(pendingRecords)
      setFollowupAveaClaims(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const selectAllFollowup = (records: any) => {
    setAllFollowup(true)
    let recordList: any = []
    records.map((record: any) => {
      recordList.push(record.claim_id)
    })
    setSelectedFollowup(recordList)
  }

  const unselectAllFollowup = () => {
    setAllFollowup(false)
    setSelectedFollowup([])
  }

  function arrayToIndexedObject(arr: string[]) {
    return arr.reduce((acc: { [key: number]: string }, val, index) => {
      acc[index + 1] = val.toString();
      return acc;
    }, {});
  }

  function formatDate(date: Date) {
    const nextDate = new Date(date);
    const pstOffset = 8 * 60;
    const utcOffset = nextDate.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const grabSearchByNameFollowupCensus = ( name:string ) => {
    let formattedName = name.toUpperCase()
    let data = {
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      'min_percent': (minPercent / 100),
      'max_percent': (maxPercent / 100),
      'page': page,
      'facilities': facility,
      'sort_by': column,
      'ascending': ascending,
      'name': formattedName,
      'tz': DateUtilityFunctions.getTimeZone()
    }
    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}/followup/census/search`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
    };
    axios.request(config)
      .then((response: any) => {
        // setClaimsCensusRecords(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
}

  const addBatchToFavorites = () => {
    setPushingToFollowup(true)
    let newData = arrayToIndexedObject(selectedFollowup)
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/Followup/add_favorite_collab`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: newData
    };
    axios.request(config)
    .then((response) => {
      setPushingToFollowup(false)
      setSelectedFollowup([])
    })
    .catch((error) => {
      console.log(error);
      setPushingToFollowup(false)
    });
  }

  const removeBatchToFavorites = (claim_id: string) => {
    setPushingToFollowup(true)
    let newData = {"1": claim_id}
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/remove_favorite_collab`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: newData
    };
    axios.request(config)
      .then((response) => {
        getClaimsFollowup()
        setPushingToFollowup(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleInsuranceFilter = (insurance: string) => {
    setInsuranceFilterFollowUp(insurance)
  }

  const updateFollowupClaim = (claim_id: string, coordinator: string | null, status: string | null) => {
    let data = {
      "userid": coordinator,
      "claim_id": claim_id,
      "status": status
    };
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/update_claim`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      grabClaims()
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const updateCoordinatorFollwup = (claim_id: string, coordinator: string) => {
    let data = {
      "claim_id": claim_id,
      "userid": coordinator
    };
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/favorites/update_coordinator`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      getClaimsFollowup()
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const grabBillingCount = ( timeSpan?: number, startDate?: string, endDate?: string) => {
    if (!timeSpan) return 
      const endDateObj = new Date();
      const startDateObj = new Date();
      startDateObj.setDate(endDateObj.getDate() - timeSpan);

      startDate = startDateObj.toISOString().split('T')[0]; // format as YYYY-MM-DD
      endDate = endDateObj.toISOString().split('T')[0]; // format as YYYY-MM-DD
    
    const data = {
      "start_date": startDate,
      "end_date": endDate,
      "tz": currentTimeZone
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/favorites/analytics`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        const reformattedData = reformatData(response.data);
        setBillingAnalyticsCount(reformattedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const reformatSubmissionDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-based month
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  const reformatData = (data: RecordType[]) => {
    const result: { [key: string]: { total_records: number; pending: number; successful: number; failed: number } } = {};
  
    data.forEach((record: RecordType) => {
      const submissionDate = reformatSubmissionDate(record.submission_date); // Reformat the date
      if (!result[submissionDate]) {
        result[submissionDate] = {
          total_records: 0,
          pending: 0,
          successful: 0,
          failed: 0
        };
      }
      result[submissionDate].total_records += 1;
      if (record.claim_status === 'Pending') {
        result[submissionDate].pending += 1;
      } else if (record.claim_status === 'Successful') {
        result[submissionDate].successful += 1;
      } else if (record.claim_status === 'Failed') {
        result[submissionDate].failed += 1;
      }
    });
  
    const reformattedArray =  Object.entries(result).map(([date, counts]) => ({
      submission_date: date,
      total_records: counts.total_records,
      pending: counts.pending,
      successful: counts.successful,
      failed: counts.failed
    }));

    return reformattedArray.reverse();
  };

  const updateCoordinatorClaims = (claim_id: string, coordinator: string) => {
    let data = {
      "claim_id": claim_id,
      "userid": coordinator
    };
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/update_coordinator`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      getClaimsFollowup()
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const submitBatchToCollab = () => {
    setSubmittingData(true)

    const data = {
      "claim_ids": selectedFollowup,
      "tz": currentTimeZone
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/submit_claim_ids`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
      .then((response) => {
        getClaimsFollowup()
        setSubmittingData(false)
      })
      .catch((error) => {
        console.log(error);
        setSubmittingData(false)
      });
  }

  const submitCensusBatchToCollab = () => {
    setSubmittingData(true)

    const data = {
      "claim_ids": selectedFollowup,
      "tz": currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/followup/census/submit`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
      .then((response) => {
        getClaimsFollowup()
        setSubmittingData(false)
      })
      .catch((error) => {
        console.log(error);
        setSubmittingData(false)
      });
  }

  const fetchAutomateCollab = (claim_id: string) => { // Call from cell component with handleClick
    let url = `${baseUrl}/claims/automate_collab/get_record/${claim_id}`
    const body = {
      "tz": currentTimeZone
    }
    
    axios.post(url, body)
      .then((response) => {
        const formattedData = {
          ...response.data,
          dx_codes: response.data.dx_codes ? formatDiagnosis(response.data.dx_codes) : ""
        }
        setCollabFormData(formattedData)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const fetchReminders = async () => {
    const url = `${baseUrl}/claims/followup/reminder`
    const body = {
      'tz': currentTimeZone
    }
    axios.post(url, body)
    .then((response) => {
      if (response.data.length) setCurrentRemindersData(response.data)
      setFetchingRemindersData(false)
    })
    .catch((error) => {
      console.log(error);
      setFetchingRemindersData(false)
    });
  }

  const formatDiagnosis = (dxCodes: string): string => {
    const regex = /F\d{2}\.\d{1,2}/g;
    const matches = dxCodes.match(regex);
    return matches ? matches.join(' ') : '';
  };

  const getRefreshClaimsFollowupTwo = () => {
    setFailedRecords([])
    setSuccessfulRecords([])
    setPendingRecords([])
    setFollowupClaims([])
    let pendingRecords: any = []
    let successfulRecords: any = []
    let rejectedRecords: any = []
    const requestData = {
      'start_date': DateUtilityFunctions.ReformatDate(followupStartDate),
      'end_date': DateUtilityFunctions.ReformatDate(followupEndDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 0,
      "facilities": followupClaimsFacility,
      "status": followupClaimsStatus, 
      "sort_by": column,
      'ascending': ascending,
      "insurance": insuranceFilterFollowUp,
      "name": claimsFollowupTerm
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/favorites`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };
    axios.request(config)
    .then((response) => {
      response.data.map((record: any) => {
        record.claim_status === 'Successful'
          ? successfulRecords.push(record)
          : record.claim_status === 'pending'
              ? pendingRecords.push(record)
              : record.claim_status == 'Failed'
                  ? rejectedRecords.push(record)
                  : pendingRecords.push(record)
      })
      setFailedRecords(rejectedRecords)
      setSuccessfulRecords(successfulRecords)
      setPendingRecords(pendingRecords)
      setFollowupClaims(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const handleClaimsFollowupTermUpdate = (data: string) => {
    setClaimsFollowupTerm(data)
    getRefreshClaimsFollowupTwo()
  }

  const getClaimsFollowup = () => {
    setFailedRecords([])
      setSuccessfulRecords([])
      setPendingRecords([])
      setFollowupClaims([])
    let pendingRecords: any = []
    let successfulRecords: any = []
    let rejectedRecords: any = []
    const requestData = {
      'start_date': formatDate(followupStartDate),
      'end_date': formatDate(followupEndDate),
      "min_percent": 0.0,
      "max_percent": 1.0,
      "page": 0,
      "facilities":"All",
      "status": "ALL",  
      "sort_by": 'end_date',
      'ascending': false,
      'tz': DateUtilityFunctions.getTimeZone()
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/favorites`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: requestData
    };
    axios.request(config)
    .then((response) => {
      response.data.map((record: any) => {
        record.claim_status === 'Successful'
          ? successfulRecords.push(record)
          : record.claim_status === 'pending'
              ? pendingRecords.push(record)
              : record.claim_status == 'Failed'
                  ? rejectedRecords.push(record)
                  : pendingRecords.push(record)
      })
      setFailedRecords(rejectedRecords)
      setSuccessfulRecords(successfulRecords)
      setPendingRecords(pendingRecords)
      setFollowupClaims(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const searchClaimsFollowup = (data: string) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/followup_collab_search/${data}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.request(config)
      .then((response) => {
        setFollowupClaims(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getRefreshFollowupCensusRecords = () => {
    let data = {
      "ascending": true,
      "sort_by": "submission_date",
      "start_date": formatDate(followupStartDate),
      "end_date": formatDate(followupEndDate),
      "cpt_code": cptCode,
      "loc": loc,
      "page": page,
      "facilities": facility,
      "name": searchFollowupCensus,
      "insurance": followUpCensusInsurance,
      "tz": DateUtilityFunctions.getTimeZone()
    };
    console.log(JSON.stringify(data))
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/followup/census/search`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      console.log('census followup refresh: ', response.data)
      setFollowupCensusRecords(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }
    
  const contextValue: FollowupContextType = {
    selectedFollowup,
    pushingToFollowup,
    allFollowup,
    followupTab,
    submuttingData,
    billingAnalyticsCount,
    selectedColumnsFollowup,
    selectedColumnsFollowupCensus,
    selectedColumnsFollowupAvea,
    followupRecords,
    pendingRecords,
    successfullRecords,
    failedRecords,
    followupAveaRecords,
    pendingAveaRecords,
    successfullAveaRecords,
    failedAveaRecords,
    handleFacilityChange,
    handleStatusChange, 
    handleSortColumnChange,
    handleSortOrderChange,
    status,
    claimsSearch,
    activeClaimSearch,
    followupCensusRecords,
    updateSelectedFollowup,
    getClaimsFollowup, 
    addBatchToFavorites,
    selectAllFollowup,
    unselectAllFollowup,
    updateFollowupTab,
    updateCoordinatorFollwup,
    submitBatchToCollab,
    removeBatchToFavorites,
    fetchAutomateCollab,
    collabFormData,
    setCollabFormData,
    fetchReminders,
    remindersData,
    fetchingRemindersData,
    showReminder,
    setShowReminder,
    submitCensusBatchToCollab,
    updateCoordinatorClaims,
    updateFollowupClaim,
    setCollabDisableEdit,
    collabDisableEdit,
    toggleToShowColumnFollowup,
    toggleToShowColumnFollowupAvea,
    toggleToShowColumnFollowupCensus,
    grabBillingCount,
    insuranceFilterFollowUp,
    handleInsuranceFilter,
    getRefreshClaimsFollowupTwo,
    handleClaimsFollowupTermUpdate,
    handleStartDate,
    handleEndDate,
    handleMinPercent,
    handleMaxPercent,
    searchClaimsFollowup,
    followUpCensusInsurance,
    handleInsuranceFollowUpCensus,
    getRefreshFollowupCensusRecords,
    handleSearchFOllowupFilter,
    handleFollowupCensusSearchChange,
    handleFollowupClaimsStatusChange,
    handleFollowupClaimsFacilityChange,
    getRefreshAveaFollowup,
    page, 
    startDate,
    endDate, 
    minPercent, 
    maxPercent, 
    facility, 
    cptCode, 
    loc,
    handlePageChange,
    handleCptChange,
    handleLocChange,
    searchFollowupCensus,
    followupClaimsStatus,
    followupClaimsFacility,
    claimsFollowupTerm
  };

  return (
    <FollowupContext.Provider value={contextValue}>
      {children}
    </FollowupContext.Provider>
  );
};
