import LayoutComponent from '../BaseScreen';
import { useData } from '../../Context/DataContext';
import TableComponent from '../../Components/Tables/TableComponent';
import { ExternalOptions } from '../../Options/ExternalOptions';
import ExternalFilterComponent from '../../Components/SortAndFilter/ExternalFilterComponent';
import { useAuth } from '../../Context/AuthContext';

const ExternalScreen = () => {

  const {externalData} = useData()
  const {allUsers} = useAuth()


  return (
    <LayoutComponent
      header={
        <div className='h-14 w-full mb-2'>
          <ExternalFilterComponent />
        </div>
      } // Render your custom header component here
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent table='External' columns={ExternalOptions} records={externalData} users={allUsers} />
          </div>
        </div>
      } 
    />
  )
}

export default ExternalScreen
