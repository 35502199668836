export const CensusUserTableOptions = [
  {
    label:'Claim Id',
    type:'text',
    recordName: 'claim_id'
  },

  {
    label:'Status',
    type:'text',
    recordName: 'claim_status'
  },
  {
    label:'CPT Code',
    type:'text',
    recordName: 'cpt_code'
  },
  {
    label:'Rev. Code',
    type:'text',
    recordName: 'rev_code'
  },
  {
    label: 'UUID',
    type: 'text',
    recordName: 'uuid'
  },
  {
    label:'Start',
    type:'date',
    recordName: 'start_date'
  },
  {
    label:'Submitted',
    type:'date',
    recordName: 'submission_date'
  },
  {
    label:'End',
    type:'date',
    recordName: 'end_date'
  },
]