import React, { useEffect, useState } from 'react'
import SearchComponent from '../Inputs/SearchComponent'
import { useClaims } from '../../Context/ClaimsContext'
import ButtonComponent from '../Inputs/ButtonComponent'
import CalendarSelectComponent from '../Inputs/CalendarSelectComponent'
import { useFollowup } from '../../Context/FollowupContext'
import SelectComponent from '../Inputs/SelectComponent'
import PercentOptionComponent from '../Inputs/PercentOptionComponent'
import SelectInputComponent from '../Inputs/SelectInputComponent'
import { useData } from '../../Context/DataContext'
import { start } from 'repl'
import { faC } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../Pagination/PaginationComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeft, Search } from 'react-feather'
import SearchSubmitComponent from '../Inputs/SearchSubmitComponent'
import { useNavigation } from '../../Context/NavigationContext'
import LargeButtonComponent from '../Inputs/LargeButtonComponent'
import SelectInput from '@mui/material/Select/SelectInput'
import SelectInputColumns from '../Inputs/SelectInputColumns'

//236672020

const FollowupCensusFilter = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const {selectedFollowup, submitBatchToCollab, pushingToFollowup, toggleToShowColumnFollowupAvea, selectedColumnsFollowupAvea} = useFollowup()
  const { page, startDate, endDate, minPercent, 
    maxPercent, facility, status, cptCode, handlePageChange,
    handleStartDate,handleEndDate,handleCptChange,handleMaxPercent,
    handleFacilityChange, loc, handleLocChange,
    searchFollowupCensus, handleSearchFOllowupFilter, followUpCensusInsurance, 
    handleInsuranceFollowUpCensus, getRefreshFollowupCensusRecords
  } = useFollowup()
  const {insuranceOptions} = useData()

  const percentOptions = [];
  const facilityOptions = ['All', 'Affinity', 'Beachside', 'Axis']
  const cptCodeOptions = [
    'All',
    'H0018',
    'H0010',
    'H0035',
    'S0102',
    '1002',
    '0158',
    '0156',
    '0193',
    '0126',
    '0154',
    '1001'
  ]
  const locOptions = [
    'All',
    'Residential',
    'Detox',
  ]
  const claimsColumnsOptinos = [
    'Select',
    'Coordinator',
    'Claim Id',
    'In Collab',
    'CPT Code',
    'Auth. Code',
    'LOC',
    'Name',
    'DOB',
    'Policy',
    'Insruance',
    'Facility',
    'Start Date',
    'End Date',
    'Submission Date'
  ]

  for (let i = 0; i <= 100; i++) {
    percentOptions.push(i);
  }

  const insuranceFilterOptions = insuranceOptions ? insuranceOptions.map(object => object.label ? object.label : 'N/A') : ['None']; insuranceFilterOptions.unshift('All')

  useEffect(() => {
    getRefreshFollowupCensusRecords()
  }, [startDate, endDate, minPercent, maxPercent, page, facility, cptCode, loc, searchFollowupCensus, followUpCensusInsurance])

  // startDate, endDate, minPercent, maxPercent, page, facility, cptCode, loc, searchFollowupCensus, followUpCensusInsurance
  return (
    <div className="h-full px-2 w-full flex flex-col justify-between bg-primary-header dark:bg-slate-700 rounded-lg mb-4">
      <div className='w-full flex flex-row items-center justify-between mt-1'>
        <div className=''>
          <PaginationComponent pageCount={50} currentPage={page} handlePageChange={handlePageChange}/>
        </div>
        <div className='flex-1 mr-2'>
          <SearchComponent searchTerm={searchFollowupCensus} handler={handleSearchFOllowupFilter}
          placeholder='Search name...'/>
        </div>
        <div className='flex flex-row items-center border-2 border-primary p-.5 rounded-full ml-2'>
          <p className='mx-2 min-w-16 text-black dark:text-white font-bold'>Denied Claims</p>
          <ChevronLeft height={24} width={24} className='text-black dark:text-white'/>
        </div>
      </div>
      <div className='w-full flex flex-row items-center justify-between  mb-1'>
        <div>
          {
            selectedFollowup.length > 0
              ? <LargeButtonComponent label={pushingToFollowup ? 'Submitting' : 'Move To Followup'} handler={() => {submitBatchToCollab()}}/>
              : null
          }
        </div>
        <div className='w-full flex flex-row items-center justify-end'>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dar:text-white font-bold'>Columns: </p>
            <SelectInputColumns options={claimsColumnsOptinos} showColumnFunction={toggleToShowColumnFollowupAvea} columnsList={selectedColumnsFollowupAvea}/>
          </div>
          <div className='flex flex-row items-center justify-end'>
            <div className='flex flex-row items-center'>
              <p className='mx-2 min-w-16 max-w-24 text-black dar:text-white font-bold'>Date Range: </p>
              <CalendarSelectComponent selectedDate={startDate} handleDateChange={handleStartDate}/>
              <p className='mx-2 text-black dar:text-white font-bold'> - </p>
              <CalendarSelectComponent selectedDate={endDate} handleDateChange={handleEndDate}/>
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dar:text-white font-bold'>CPT Code: </p>
            <SelectInputComponent placeholder='All' options={cptCodeOptions} selectedValue={cptCode} handleOptionClick={handleCptChange}/>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6 max-w-24 text-black dar:text-white font-bold'>LOC: </p>
            <SelectInputComponent placeholder='All' options={locOptions} selectedValue={loc} handleOptionClick={handleLocChange}/>
          </div>
          <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dar:text-white font-bold'>Facility: </p>
            <SelectInputComponent placeholder='All' options={facilityOptions} selectedValue={facility} handleOptionClick={handleFacilityChange}/>
          </div>
          {/* <div className='flex flex-row items-center'>
            <p className='mx-2 ml-6  min-w-16 max-w-24 text-black dar:text-white font-bold'>Insurance: </p>
            <SelectInputComponent placeholder='All' options={insuranceFilterOptions} selectedValue={followUpCensusInsurance} handleOptionClick={handleInsuranceFollowUpCensus}/>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default FollowupCensusFilter
