import React from 'react'
import LayoutComponent from '../BaseScreen'
import TableComponent from '../../Components/Tables/TableComponent'
import { CensusFollowupOptions } from '../../Options/CensusFollowupOptions'
import { useData } from '../../Context/DataContext'
import FollowupFilterComponent from '../../Components/SortAndFilter/FollowupFilterComponent'
import FollowupCensusFilter from '../../Components/SortAndFilter/FollowupCensusFilter'
import { useFollowup } from '../../Context/FollowupContext'
import { useAuth } from '../../Context/AuthContext'

const CensusFollowupScreen = () => {

  const {allUsers} = useAuth()
  const {followupCensusRecords} = useFollowup()


  return (
    <LayoutComponent 
      header={
        <div className='h-22 w-full mb-2'>
          <FollowupCensusFilter/>
        </div>
      } 
      content={
        <div className="w-full px-4 mt-12" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent table='Followup Census' columns={CensusFollowupOptions} records={followupCensusRecords} users={allUsers}/>
          </div>
        </div>
      }
    />
  )
}

export default CensusFollowupScreen
