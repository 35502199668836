import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import SidebarComponent from '../Components/Navigation/SidebarComponent';

interface NavigationSubTab {
  label: string;
  icon: string;
  route: string;
  page: string;
}

interface NavigationTab {
  label: string;
  icon: string;
  route: string;
  page: string;
  subTabs: NavigationSubTab[] | null;
  department: string[],
  privileges: string[]
}

interface NavigationContextValue {
  sideNavigation: NavigationTab[];
  sideNavigationFollowup: NavigationTab[];
  sideNavigationClaims: NavigationTab[];
  topNavigation: NavigationTab[];
  currentSidebarTab: string;
  currentSidebarType: string;
  currentSidebarSubTab: string;
  currentContentTab: string;
  handleUpdateCurrentSidebarTab: (text: string, path: string) => void;
  handleUpdateCurrentSidebarType: (text: string) => void;
  handleUpdateCurrentSidebarSubTab: (text: string) => void;
  handleUpdateCurrentContentTab: (text: string) => void;
}

const NavigationContext = createContext<NavigationContextValue | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const sideNavigationClaims = [
    {
      label: 'Claims',
      icon: 'file-text',
      route: '/claims/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/claims/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/claims/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/claims/census',
          page: 'table',
        },
      ],
      department: [
        'administration',
        'dev',
        'billing',
        'claims',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Follow Up',
      icon: 'repeat',
      route: '/follow-up/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/follow-up/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/follow-up/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/follow-up/census',
          page: 'table',
        },
      ],
      department: [
        'billing',
        'administration',
        'dev',
        'followup',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Billing Census',
      icon: 'grid',
      route: '/census',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev',
        'claims',
        'followup'
      ],
      privileges: [
        'staff',
        'admin',
        'dev',
        'owner'
      ]
    }
  ]

  const sideNavigationFollowup = [
    {
      label: 'Claims',
      icon: 'file-text',
      route: '/claims/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/claims/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/claims/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/claims/census',
          page: 'table',
        },
      ],
      department: [
        'administration',
        'dev',
        'billing',
        'claims',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Follow Up',
      icon: 'repeat',
      route: '/follow-up/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/follow-up/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/follow-up/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/follow-up/census',
          page: 'table',
        },
      ],
      department: [
        'billing',
        'administration',
        'dev',
        'followup',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Billing Census',
      icon: 'grid',
      route: '/census',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev',
        'claims',
        'followup'
      ],
      privileges: [
        'staff',
        'admin',
        'dev',
        'owner'
      ]
    },
  ]

  const sideNavigation = [
    {
      label: 'Dashboard',
      icon: 'home',
      route: '/',
      page: 'table',
      subTabs: null,
      department: [
        'intake',
        'administration',
        'dev',
        'claims',
        'followup'
      ],
      privileges: [
        'staff',
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Intake Analytics',
      icon: 'trending-up',
      route: '/intake-analytics',
      page: 'table',
      subTabs: null,
      department: [
        'intake',
        'administration',
        'dev'
      ],
      privileges: [
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Shared',
      icon: 'list',
      route: '/historic',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev'
      ],
      privileges: [
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'External',
      icon: 'download',
      route: '/external',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev'
      ],
      privileges: [
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Billing Analytics',
      icon: 'trending-up',
      route: '/analytics/billing',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev'
      ],
      privileges: [
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Billing Census',
      icon: 'grid',
      route: '/census',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev',
        'claims',
        'followup'
      ],
      privileges: [
        'staff',
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Claims',
      icon: 'file-text',
      route: '/claims/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/claims/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/claims/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/claims/census',
          page: 'table',
        },
      ],
      department: [
        'administration',
        'dev',
        'billing',
        'claims',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Follow Up',
      icon: 'repeat',
      route: '/follow-up/collab',
      page: 'table',
      subTabs: [
        {
          label: 'Collab Md',
          icon: 'download',
          route: '/follow-up/collab',
          page: 'table',
        },
        {
          label: 'Avea',
          icon: 'download',
          route: '/follow-up/avea',
          page: 'table',
        },
        {
          label: 'Billing Census',
          icon: 'download',
          route: '/follow-up/census',
          page: 'table',
        },
      ],
      department: [
        'billing',
        'administration',
        'dev',
        'followup',
        ''
      ],
      privileges: [
        'admin',
        'dev',
        'owner',
        'staff'
      ]
    },
    {
      label: 'Accounts',
      icon: 'users',
      route: '/accounts',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev'
      ],
      privileges: [
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: 'Intellachat AI',
      icon: 'zap',
      route: '/intellachat',
      page: 'table',
      subTabs: null,
      department: [
        'administration',
        'dev'
      ],
      privileges: [
        'admin',
        'dev',
        'owner'
      ]
    },
  ];

  const topNavigation = [
    {
      label: '',
      icon: 'activity',
      route: '',
      page: 'popup',
      subTabs: null,
      department: [
        'intake',
        'billing',
        'administration',
        'dev'
      ],
      privileges: [
        'staff',
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: '',
      icon: 'bell',
      route: '',
      page: 'popup',
      subTabs: null,
      department: [
        'intake',
        'billing',
        'administration',
        'dev'
      ],
      privileges: [
        'staff',
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
    {
      label: '',
      icon: 'help-circle',
      route: '/help',
      page: 'static',
      subTabs: null,
      department: [
        'intake',
        'billing',
        'administration',
        'dev'
      ],
      privileges: [
        'staff',
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
    // {
    //   label: '',
    //   icon: 'settings',
    //   route: '/settings',
    //   page: 'static',
    //   subTabs: null,
    //   department: [
    //     'intake',
    //     'billing',
    //     'administration',
    //     'dev'
    //   ],
    //   privileges: [
    //     'staff',
    //     'manager',
    //     'admin',
    //     'dev',
    //     'owner'
    //   ]
      
    // },
    {
      label: '',
      icon: 'log-out',
      route: '/auth/logout',
      page: 'status',
      subTabs: null,
      department: [
        'intake',
        'billing',
        'administration',
        'dev'
      ],
      privileges: [
        'staff',
        'manager',
        'admin',
        'dev',
        'owner'
      ]
    },
  ];

  const {currentProfile} = useAuth()

  const [currentSidebarTab, setCurrentSidebarTab] = useState<string>('')
  const [currentSidebarType, setCurrentSidebarType] = useState<string>('table')
  const [currentSidebarSubTab, setCurrentSidebarSubTab] = useState<string>('Collab Md')
  const [currentContentTab, setCurrentContentTab] = useState<string>(localStorage.getItem('activeTab') || 'Home')


  useEffect(() => {
    currentProfile.department === 'claims'
      ? <Navigate to={'/claims/collab'}/>
      : currentProfile.department === 'claims'
          ? <Navigate to={'/followup/collab'}/>
          : <Navigate to={'/'}/>
  }, []);

  useEffect(() => {
    currentProfile.department === 'claims'
      ? setCurrentSidebarTab('Claims')
      : currentProfile.department === 'followup'
          ? setCurrentSidebarTab('Follow Up')
          : setCurrentSidebarTab('Dashboard')
  }, [currentProfile])

  const handleUpdateCurrentSidebarTab = (text: string, path: string) => {
    if(text === 'Follow Up' || text === 'Claims'){
      setCurrentSidebarSubTab('Collab Md')
    }
    localStorage.setItem('lastClickedRoute', path);
    localStorage.setItem('activeTab', text);
    localStorage.setItem('activePath', path);
    setCurrentSidebarTab(text)
    const currentPath = sideNavigation.find(tab => tab.label === text)
    if(currentPath){
      navigate(currentPath.route)
    } else {
      navigate('/')
    }
  }

  const handleUpdateCurrentSidebarType = (text: string) => {
    setCurrentSidebarType(text)
  }

  const handleUpdateCurrentSidebarSubTab = (text: string) => {
    setCurrentSidebarSubTab(text)
  }

  const handleUpdateCurrentContentTab = (text: string) => {
    setCurrentContentTab(text)
  }


  const value: NavigationContextValue = { 
                                          sideNavigation, 
                                          sideNavigationFollowup,
                                          sideNavigationClaims,
                                          topNavigation,
                                          currentSidebarTab,
                                          currentSidebarType,
                                          currentSidebarSubTab,
                                          currentContentTab,
                                          handleUpdateCurrentSidebarTab,
                                          handleUpdateCurrentSidebarType,
                                          handleUpdateCurrentSidebarSubTab,
                                          handleUpdateCurrentContentTab
                                         };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};