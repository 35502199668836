export interface AvailityBillingDataProps {
  finalized_date: Date,
  payment_amount: string,
  facility: 'Affinity' | 'Beachside' | 'Axis'
}

export const aggregateDataByFacility = (data: AvailityBillingDataProps[]) => {
  const totals = {
    Affinity: 0,
    Beachside: 0,
    Axis: 0,
    Total: 0
  };

  data.forEach((item: AvailityBillingDataProps) => {
    const amount = parseInt(item.payment_amount);
    totals[item.facility] += amount;
    totals.Total += amount;
  });

  return [
    { facility: 'Affinity', amount: totals.Affinity },
    { facility: 'Beachside', amount: totals.Beachside },
    { facility: 'Axis', amount: totals.Axis },
    { facility: 'Total', amount: totals.Total }
  ];
};

export function getDateRangeFromSelection(startMonth: string, startYear: string, endMonth: string, endYear: string): { start_date: Date, end_date: Date } {
  const startMonthIndex = monthSelectOptions.indexOf(startMonth);
  const endMonthIndex = monthSelectOptions.indexOf(endMonth);

  let start_date = new Date(parseInt(startYear), startMonthIndex, 1);
  let end_date = new Date(parseInt(endYear), endMonthIndex + 1, 0); // Last day of end month

  const today = new Date();
  today.setHours(23, 59, 59, 999); // Set to end of today

  // If end_date is in the future, set it to today
  if (end_date > today) {
    end_date = today;
  }

  // If start_date is after end_date, set start_date to the first day of end_date's month
  if (start_date > end_date) {
    start_date = new Date(end_date.getFullYear(), end_date.getMonth(), 1);
  }

  console.log('getDateRangeFromSelection - start_date:', start_date);
  console.log('getDateRangeFromSelection - end_date:', end_date);

  return { start_date, end_date };
}

export const monthSelectOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]