export const ExternalOptions = [
  {
    label:'Prefix',
    type:'text',
    recordName: 'prefix',
    admin: true,
    width: '10rem'
  },
  {
    label:'Level of Care',
    type:'text',
    recordName: 'levelOfCare',
    width: '10rem'
  },

  {
    label:'Total Units',
    type:'text',
    recordName: 'totalUnits',
    width: '10rem'
  },
  {
    label:'Allowed %',
    type:'text',
    recordName: 'allowedPercent',
    width: '10rem'
  },
  {
    label:'Paid %',
    type:'text',
    recordName: 'paidPercent',
    width: '10rem'
  },
  {
    label:'Average Daily Rate',
    type:'text',
    recordName: 'avgDailyRate',
    width: '10rem'
  },
  {
    label:'Last Paid',
    type:'text',
    recordName: 'lastPaid',
    width: '10rem'
  }
]