import React, { useEffect, useState } from "react";
import FormInputComponent from "../Inputs/FormInputComponent";
import SelectInputComponent from "../Inputs/SelectInputComponent";
import DateSelectionComponent from "../Inputs/DateSelectionComponent";
import { useFollowup, CollabFormData } from "../../Context/FollowupContext";
import { useData } from "../../Context/DataContext";
import { stateOptions, facilityOptions } from "../../Options/CollabFormOptions";
import axios from "axios";
import baseUrl from "../../Utils/baseUrl";
import { DateUtilityFunctions } from "../../Utils/DateUtilityFunctions";

const CollabForm = () => {
  const { currentTimeZone } = useData()
  const { collabFormData, collabDisableEdit } = useFollowup();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [firstName, setFirstName] = useState(collabFormData.first_name);
  const [lastName, setLastName] = useState(collabFormData.last_name);
  const [dob, setDob] = useState(collabFormData.dob);
  const [gender, setGender] = useState(collabFormData.gender);
  const [city, setCity] = useState(collabFormData.city);
  const [state, setState] = useState(collabFormData.state);
  const [streetAddress, setStreetAddress] = useState(collabFormData.street_address);
  const [zip, setZip] = useState(collabFormData.zip);
  const [claimId, setClaimId] = useState(collabFormData.claim_id);
  const [admissionDate, setAdmissionDate] = useState(collabFormData.admission_date);
  const [dischargeDate, setDischargeDate] = useState(collabFormData.discharge_date);
  const [startDate, setStartDate] = useState(collabFormData.start_date);
  const [endDate, setEndDate] = useState(collabFormData.end_date);
  const [chargeCode, setChargeCode] = useState(collabFormData.charge_code);
  const [facility, setFacility] = useState(collabFormData.facility);
  const [insuranceCompany, setInsuranceCompany] = useState(collabFormData.insurance_company);
  const [groupId, setGroupId] = useState(collabFormData.group_id);
  const [policyId, setPolicyId] = useState(collabFormData.policy_id);
  const [dxCodes, setDxCodes] = useState(collabFormData.dx_codes);


  useEffect(() => {
    setFirstName(collabFormData.first_name);
    setLastName(collabFormData.last_name);
    setDob(collabFormData.dob);
    setGender(collabFormData.gender);
    setCity(collabFormData.city);
    setState(collabFormData.state);
    setStreetAddress(collabFormData.street_address);
    setZip(collabFormData.zip);
    setClaimId(collabFormData.claim_id);
    setAdmissionDate(collabFormData.admission_date);
    setDischargeDate(collabFormData.discharge_date);
    setStartDate(collabFormData.start_date);
    setEndDate(collabFormData.end_date);
    setChargeCode(collabFormData.charge_code);
    setFacility(collabFormData.facility);
    setInsuranceCompany(collabFormData.insurance_company);
    setGroupId(collabFormData.group_id);
    setPolicyId(collabFormData.policy_id);
    setDxCodes(collabFormData.dx_codes);
  }, [collabFormData]);

  const handleSelectChangeState = (value: string) => {
    setState(value);
  };

  const handleSelectChangeFacility = (value: string) => {
    setFacility(value);
  }

  const sendFormData = (claimId: string, formData:CollabFormData) => {
    setLoading(true);
    const body = {
      ...formData,
      'tz': currentTimeZone
    }
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/claims/automate_collab/update_record/${claimId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    };
    axios.request(config)
    .then(() => {
      setSuccessMessage("Records successfully updated");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    })
    .catch((error: any) => {
      console.error(error)
    })
    .finally(() => {
      setTimeout( () => {
      setLoading(false);
    }, 1000)})
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formattedDxCodes = formatDiagnosisInput(dxCodes)
    // const formattedAdmissionDate = convertToISOFormatInPST(admissionDate)
    // const formattedDischargeDate = convertToISOFormatInPST(dischargeDate)
    const formData: CollabFormData = {
      first_name: firstName,
      last_name: lastName,
      dob: dob,
      gender: gender,
      city: city,
      state: state,
      street_address: streetAddress,
      zip: zip,
      claim_id: claimId,
      admission_date: admissionDate,
      discharge_date: dischargeDate,
      start_date: startDate,
      end_date: endDate,
      charge_code: chargeCode,
      facility: facility,
      insurance_company: insuranceCompany,
      group_id: groupId,
      policy_id: policyId,
      dx_codes: formattedDxCodes,
    };
    sendFormData(formData.claim_id,formData)
  }

  const formatDiagnosisInput = (input: string): string => {
    // Regular expression to match the pattern "F" followed by two digits, a decimal, and one or two digits
    const regex = /F\d{2}\.\d{1,2}/g;
    // Remove any extra spaces and newlines
    const sanitizedInput = input.replace(/\s+/g, ' ');
    // Use the match method to find all matches in the sanitized input string
    const matches = sanitizedInput.match(regex);
    // If matches are found, join them with spaces; otherwise, return an empty string
    return matches ? matches.join(' ') : '';
  };

  const convertToISOFormatInPST = (dateStr: string): string => {
    // Parse the input date string
    const date = new Date(dateStr + 'T00:00:00');
  
    // Convert to PST (UTC-8)
    const pstOffset = 8 * 60; // PST is UTC-8
    date.setMinutes(date.getMinutes() - pstOffset);
  
    // Convert the date to ISO format
    return date.toISOString();
  };
  
  return (
    <div className="w-full max-w-full">
      <form className="flex flex-wrap w-full">
        <div className="flex flex-row w-full gap-6 mt-5 align-center">
          <FormInputComponent
            handleFunction={setFirstName}
            value={firstName}
            placeHolder="First name here"
            type="text"
            icon="First Name"
          />
          <FormInputComponent
            handleFunction={setLastName}
            value={lastName}
            placeHolder="Last name here"
            type="text"
            icon="Last Name"
          />
          <div className="ml-4 mt-5">
            <span className="font-bold text-primary">Date of Birth:</span>
            <DateSelectionComponent
              handleDateChange={setDob}
              selectedDate={dob} 
            />
          </div>
          <FormInputComponent
            handleFunction={setGender}
            value={gender}
            placeHolder="Gender here"
            type="text"
            icon="Gender"
          />
        </div>
        <div className="flex flex-row w-full gap-6 mt-6">
          <FormInputComponent
            handleFunction={setCity}
            value={city}
            placeHolder="City here"
            type="text"
            icon="City"
          />
          <FormInputComponent
            handleFunction={setStreetAddress}
            value={streetAddress}
            placeHolder="Address here"
            type="text"
            icon="Address"
          />
          <FormInputComponent
            handleFunction={setZip}
            value={zip}
            placeHolder="Zip code here"
            type="text"
            icon="Zip Code"
          />
            <div className="mt-4">
              <span className="text-primary font-bold">State</span>
              <SelectInputComponent
                handleOptionClick={handleSelectChangeState}
                placeholder="Select a State"
                options={stateOptions}
                selectedValue={state}
              />
            </div>
            <div className="mt-4">
              <span className="text-primary font-bold">Facility Name</span>
              <SelectInputComponent
                handleOptionClick={handleSelectChangeFacility}
                placeholder="Select a Facility"
                options={facilityOptions}
                selectedValue={facility}
              />
            </div>
        </div>
        <div className="flex flex-row w-full gap-20 mt-8 mb-5">
          <div className="ml-4">
            <span className="font-bold text-primary">Admission Date:</span>
            <DateSelectionComponent
              handleDateChange={setAdmissionDate}
              selectedDate={admissionDate} 
            />
          </div>
          <div>
            <span className="font-bold text-primary">Discharge Date:</span>
            <DateSelectionComponent
              handleDateChange={setDischargeDate}
              selectedDate={dischargeDate} 
            />
          </div>
          <div>
            <span className="font-bold text-primary">Start Date:</span>
            <DateSelectionComponent
              handleDateChange={setStartDate}
              selectedDate={startDate} 
            />
          </div>
          <div>
            <span className="font-bold text-primary">End Date:</span>
            <DateSelectionComponent
              handleDateChange={setEndDate}
              selectedDate={endDate} 
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 w-full">
          <FormInputComponent
            handleFunction={setClaimId}
            value={claimId}
            placeHolder="Claim ID here"
            type="text"
            icon="Claim ID"
          />
          <FormInputComponent
            handleFunction={setChargeCode}
            value={chargeCode}
            placeHolder="Charge Code Here"
            type="text"
            icon="Charge Code"
          />
          <FormInputComponent
            handleFunction={setInsuranceCompany}
            value={insuranceCompany}
            placeHolder="Insurance Name Here"
            type="text"
            icon="Insurance"
          />
          <FormInputComponent
            handleFunction={setGroupId}
            value={groupId}
            placeHolder="Group ID here"
            type="text"
            icon="Group ID"
          />
          <FormInputComponent
          handleFunction={setPolicyId}
          value={policyId}
          placeHolder="Policy ID here"
          type="text"
          icon="Policy ID"
          />
        </div>
        <div className="flex flex-row h-32 w-40">
          <FormInputComponent
          handleFunction={setDxCodes}
          value={dxCodes}
          placeHolder="Diagnosis Codes here"
          type="text"
          icon="Diagnosis Codes"
          />
        </div>
        {successMessage && (
          <div className="w-full text-center text-green-500 font-bold my-3">
            {successMessage}
          </div>
        )}
        {
          collabDisableEdit ? null : 
        <div className="hover:cursor-pointer flex flex-row justify-center w-full">
            <p onClick={handleSubmit} className='text-center px-2 py-1 bg-primary rounded-md font-bold text-white'>
            {loading ? "Loading..." : "Submit"}
            </p>
        </div>
        }
      </form>
    </div>
  );
};

export default CollabForm;