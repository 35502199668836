const IntellachatHeader = () => {
  return (
    <div className="flex-column">
      <div className="flex justify-center p-2 bg-slate-300 dark:bg-zinc-600 mb-2 border-primary rounded-md text-lg border-2">
        <span 
          className="sparkle-emoji">
            IntellaChat AI✨ isn't trained to make casual conversations. IntellaChat is trained to provide you the most up-to-date information on historical prefixes.
        </span>  
      </div>
    </div>
  )
}

export default IntellachatHeader