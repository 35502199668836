import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useData } from './DataContext';
import { start } from 'repl';
import { useAuth } from './AuthContext';
import baseUrl from '../Utils/baseUrl';
import { useEffect, useCallback } from 'react';
import { DateUtilityFunctions } from '../Utils/DateUtilityFunctions';


interface IntakeContextType {
  timespan: string;
  showPrefix: boolean;
  yellowStripeRemindersData: YellowStripeRemindersDataProps[];
  prefixDataDashboard: PrefixDataProps[] | null;
  showBellReminder: BellReminderProps;
  updateTimespan: (text: string) => void;
  showPrefixPopup: () => void;
  hidePrefixPopup: () => void;
  availityData: any;
  showRecords: boolean;
  loadingRecordings: boolean
  numberRecordings: any[]
  anticipatedDischargeData:AnticipatedDischargeDataProps[];
  selectedColumnsIntake: string[],
  selectedColumnsIntakeDev: string[],
  selectedColumnsIntakeAdmin: string[],
  recordingsRecord: any,
  showTimeSelector: boolean,
  selectedPatientRecord: any,
  grabPrefixRecordsFromDashboard: (prefixId: string) => void;
  grabFullAvailityData: (
    insurance: string, 
    as_of_date: string, 
    member_id: string,
    date_of_birth: string,
    name: string,) => void;
  verifyLoading: boolean
  fetchYellowStripeRemindersData: () => void
  setShowBellReminder: React.Dispatch<React.SetStateAction<BellReminderProps>>;
  toggleShowRecordsing: (record: any) => void
  fetchAnticipatedData: () => void
  toggleToShowColumnIntake: (text:string) => void
  toggleToShowColumnIntakeDev: (text:string) => void
  toggleToShowColumnIntakeAdmin: (text:string) => void
  handleDismissBellReminder: () => void
  handleShowTimeZones: () => void
  handleSelectedPatientRecord: (record: any) => void
  getIntakeRecords: () => void,
  intakeRecords: IntakeProps[] | null,
  filteredIntakeRecords: IntakeProps[] | null
  searchIntakeRecords: (search: string) => void
  addIntakeRecord: (data: any) => void
  loadingNewIntake: boolean,
  addRecord: boolean,
  updateFilteredIntakeRecords: (text: string) => void,
  filteredUser: string
}

const IntakeContext = createContext<IntakeContextType>({
  showPrefix: false,
  timespan: '1 Month',
  prefixDataDashboard: null,
  availityData: null,
  verifyLoading: false,
  yellowStripeRemindersData: [],
  showBellReminder: {isVisible: true, lastDismissed: null},
  showRecords: false,
  loadingRecordings: false,
  numberRecordings: [],
  anticipatedDischargeData: [],
  selectedColumnsIntake: [],
  selectedColumnsIntakeDev: [],
  selectedColumnsIntakeAdmin: [],
  recordingsRecord: {},
  showTimeSelector: false,
  selectedPatientRecord: null,
  updateTimespan: () => {},
  showPrefixPopup: () => {},
  hidePrefixPopup: () => {},
  grabPrefixRecordsFromDashboard: () => {},
  grabFullAvailityData: () => {},
  fetchYellowStripeRemindersData: () => {},
  setShowBellReminder: () => {},
  toggleShowRecordsing: () => {},
  fetchAnticipatedData: () => {},
  toggleToShowColumnIntake: () => {},
  toggleToShowColumnIntakeDev: () => {},
  toggleToShowColumnIntakeAdmin: () => {},
  handleDismissBellReminder: () => {},
  handleShowTimeZones: () => {},
  handleSelectedPatientRecord: () => {},
  getIntakeRecords: () => {},
  intakeRecords: [],
  filteredIntakeRecords: [],
  searchIntakeRecords: () => {},
  addIntakeRecord: () => {},
  loadingNewIntake: false,
  addRecord: false,
  updateFilteredIntakeRecords: () => {},
  filteredUser: ''
});

export function useIntake() {
  return useContext(IntakeContext);
}

interface AppProviderProps {
  children: ReactNode;
}

interface PrefixDataProps {
  average_charge: string,
  average_paid: string,
  balance: string,
  facility: string,
  insurance: string,
  last_updated: string,
  name: string, 
  network: string,
  payout: number,
  ploicy_id: string,
  prefix: string
}

interface IntakeProps {
  active: boolean;
  booked: string;
  checked_in: string;
  coordinator: string;
  created_date: string;
  date: string;
  date_of_birth: string;
  in_network_oop: number;
  inn_deductable: number;
  insurance: string;
  name: string;
  onn_deducatible: number;
  out_network_oop: number;
  payer_id: string;
  policy_id: string;
  prefix: string;
  source: string;
  summary_out: string;
}

interface YellowStripeRemindersDataProps {
  intake_id: string,
  most_recent_date: string,
  name: string
}

interface BellReminderProps {
  isVisible: boolean;
  lastDismissed: number | null;
}

interface AnticipatedDischargeDataProps {
  Date: string,
  Affinity: string,
  Axis: string,
  Beachside: string
}

const usePolling = (callback: () => void, interval: number) => {
  useEffect(() => {
    const id = setInterval(callback, interval);
    return () => clearInterval(id);
  }, [callback, interval]);
}

export const IntakeProvider: React.FC<AppProviderProps> = ({ children }) => {

  const {currentUser, currentProfile} = useAuth()


  const [timespan, setTimespan] = useState<string>('1 Month')
  const [showPrefix, setShowPrefix] = useState<boolean>(false);
  const [prefixDataDashboard, setPrefixDataDashboard] = useState<PrefixDataProps[] | null>(null)
  const [availityData, setAvaillityDate] = useState<any>({})
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false)
  const [yellowStripeRemindersData, setYellowStripeRemindersData] = useState<YellowStripeRemindersDataProps[] | []>([])
  const [anticipatedDischargeData, setAnticipatedDischargeData] = useState<AnticipatedDischargeDataProps[] | []>([])

  const [intakeRecords, setIntakeRecords] = useState<IntakeProps[] | null>(null)
  const [filteredIntakeRecords, setFilteredIntakeRecords] = useState<IntakeProps[] | null>([])
  const [loadingNewIntake, setLoadingNewIntake] = useState<boolean>(false)
  const [addRecord, setAddRecord] = useState<boolean>(false)

  const [filteredUser, setFilteredUser] = useState<string>(localStorage.getItem('filteredUser') || '1')

  const [showBellReminder, setShowBellReminder] = useState<BellReminderProps>(() => {
    const savedState = localStorage.getItem('bellReminderState');
    if (savedState) {
      return JSON.parse(savedState)
    }
    return { isVisible: true, lastDismissed: null}
  })
  const [showRecords, setShowRecordings] = useState<boolean>(false)
  const [loadingRecordings, setLoadintRecords] = useState<boolean>(false)
  const [numberRecordings, setNumberRecordings] = useState<any[]>([])
  const [recordingsRecord, setRecoringsRecord] = useState<any>()
  const [showTimeSelector, setShowTimeSelector] = useState<boolean>(false)

  const {currentTimeZone} = useData()

  const [selectedPatientRecord, setSelectedPatientRecord] = useState<any>()

  // Anytime showBellReminder state changes, it is stored in local storage

  const handleSelectedPatientRecord = (record: any) => {
    setSelectedPatientRecord(record)
  }

  const updateFilteredIntakeRecords = (text: string) => {
    console.log('coordinator info: ', text)
    setFilteredUser(text)
    localStorage.setItem('filteredUser', text);
    if(text === '1'){
      setFilteredIntakeRecords(intakeRecords ? intakeRecords : [])
    } else {
      setFilteredIntakeRecords(intakeRecords ? intakeRecords?.filter((record) => record.coordinator === text) : [])
    }
  }

  useEffect(() => {
    localStorage.setItem('bellReminderState', JSON.stringify(showBellReminder));
  }, [showBellReminder]);

  const checkAndSetReminder = useCallback(async () => {
    if (currentProfile.department !== "intake" || currentUser) {
      return;
    }

    await fetchYellowStripeRemindersData();

    if (!showBellReminder.isVisible && showBellReminder.lastDismissed && 
        yellowStripeRemindersData.length > 0) {
      const timeSinceDismissal = Date.now() - showBellReminder.lastDismissed;
      const remainingTime = Math.max(120 * 60 * 1000 - timeSinceDismissal, 0);

      if (remainingTime > 0) {
        setTimeout(() => {
          setShowBellReminder(prev => ({ ...prev, isVisible: true }));
        }, remainingTime);
      } else {
        setShowBellReminder(prev => ({ ...prev, isVisible: true }));
      }
    }
  }, [showBellReminder.isVisible, showBellReminder.lastDismissed, yellowStripeRemindersData.length, currentProfile.department, currentUser]);

  usePolling(checkAndSetReminder, 2 * 60 * 1000);

  const [selectedColumnsIntake, setSelectedColumnsIntake] = useState<string[]>([
    'Details',
    'Date',
    'Status',
    'Client',
    'Insurance',
    'Prefix',
    'Verify TX',
    'Coordinator',
    'Phone'
  ])
  const [selectedColumnsIntakeDev, setSelectedColumnsIntakeDev] = useState<string[]>([
    'Notes',
    'Details',
    'Date',
    'Status',
    'Booked',
    'Reason',
    'Arriving Date',
    'Facility',
    'Client',
    'DOB',
    'Insurance',
    'Prefix',
    'Policy',
    'Active',
    'Verify TX',
    'Coordinator',
    'Phone',
    'Emergency Name',
    'Emergency Phone',
  ])
  const [selectedColumnsIntakeAdmin, setSelectedColumnsIntakeAdmin] = useState<string[]>([
    'Notes',
    'Details',
    'Date',
    'Status',
    'Booked',
    'Reason',
    'Arriving Date',
    'Facility',
    'Client',
    'DOB',
    'Insurance',
    'Prefix',
    'Policy',
    'Active',
    'Verify TX',
    'Coordinator',
    'Phone',
    'Emergency Name',
    'Emergency Phone',
  ])

  const toggleToShowColumnIntake = (text: string) => {
    setSelectedColumnsIntake((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  function sortRecordsByDateDesc(records: IntakeProps[]) {
    records.forEach((record: any) => {
        record.date = new Date(record.date);
    });
    records.sort((a: any, b: any) => b.date - a.date);
    return records;
  }

  const getIntakeRecords = () => {
    const body = {
      'tz':DateUtilityFunctions.getTimeZone()
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/get_records`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : body
    };
    axios.request(config)
    .then((response) => {
      console.log('intake records count: ', response.data.data)
      setIntakeRecords(response.data.data)
      setFilteredIntakeRecords(response.data.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const toggleToShowColumnIntakeDev = (text: string) => {
    setSelectedColumnsIntakeDev((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const toggleToShowColumnIntakeAdmin = (text: string) => {
    setSelectedColumnsIntakeAdmin((prev) => {
      const foundColumn = prev.includes(text)
      if(foundColumn){
        return prev.filter(column => column !== text)
      } else {
        return [...prev, text]
      }
    })
  }

  const updateTimespan = (text: string) => {
    setTimespan(text)
  }

  const showPrefixPopup = () => {

    setShowPrefix(true);
  }

  const handleDismissBellReminder = () => {
    setShowBellReminder({
      isVisible: false,
      lastDismissed: Date.now()
    });
  }

  const toggleShowRecordsing = (record: any) => {
    if(showRecords){
      setShowRecordings(false)
    } else {
      setShowRecordings(true)
      setRecoringsRecord(record)
      fetchRecordings(record.phone_number)
    }
  }

  const grabPrefixRecordsFromDashboard = (selectedPrefix: string) => {
    const selectedNetwork1 = "in-network";
    const selectedNetwork2 = "out-of-network";

    const body1 = {
      'prefix': `${selectedPrefix}_${selectedNetwork1}`,
      'tz': currentTimeZone
    }

    const body2 = {
      'prefix': `${selectedPrefix}_${selectedNetwork2}`,
      'tz': currentTimeZone
    }

    let config1 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level2`,
      headers: { },
      data: body1
    };

    let config2 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level2`,
      headers: { },
      data: body2
    };
    
    axios.request(config1)
    .then((response: any) => {
      if (response.data.length) {
        const data = response.data.map((item: any) => {
          if (item.payout === Infinity) {
            return {
              ...item,
              payout: 0
            };
          }
          return item;
        })
        setPrefixDataDashboard(data);
      }
      else {
        axios.request(config2)
        .then((response: any) => {
          const data = response.data.map((item: any) => {
            if (item.payout === Infinity) {
              return {
                ...item,
                payout: 0
              };
            }
            return item;
          })
          setPrefixDataDashboard(data);
        })
        .catch((error: any) => console.error("Inside of GrabPrefixRecordsFromDashboard", error))
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const hidePrefixPopup = () => {
    setShowPrefix(false);
  }

  const grabFullAvailityData = (insurance: string, as_of_date: string, member_id: string, date_of_birth: string, name: string) => {
    setVerifyLoading(true)
    setAvaillityDate({})
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/verifytx_data/${name}/${insurance}/${as_of_date}/${member_id}/${date_of_birth}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.request(config)
        .then((response) => {
          setAvaillityDate(response.data)
          setVerifyLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setVerifyLoading(false)
        });
  }

  const fetchYellowStripeRemindersData = () => {
    const body = {
      "coordinator": currentUser.userId,
      "tz": currentTimeZone
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/yellow_stripe/reminder`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    };
    axios.request(config)
      .then((response) => {
        setYellowStripeRemindersData(response.data)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const fetchRecordings = (number: string) => {
    const cleanedNumber = '1' + number.replace(/\D/g, '');
    setLoadintRecords(true)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://prod.backend.intellasure.org/s3/phone_recordings?phone_number=${cleanedNumber}`,
      headers: { }
    };
    axios.request(config)
    .then((response) => {
      setNumberRecordings(response.data)
      setLoadintRecords(false)
    })
    .catch((error) => {
      console.log(error);
      setLoadintRecords(false)
    });
  }

  const fetchAnticipatedData = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/census/anticipated_discharge_date`,
      headers: { }
    };
    
    axios.request(config)
    .then((response: any) => {
      const filteredData = response.data.filter((item: any) => 
        item.anticipated_discharge_date !== null
      )

      const groupedData = filteredData.reduce((acc: any, item: any) => {
        let date = null
        if (item.anticipated_discharge_date !== null) {
          date = convertToPST(item.anticipated_discharge_date);
        }
        
        if ( date !== null && !acc[date]) {
          acc[date] = {
            Date: date,
            Affinity: 0,
            Axis: 0,
            Beachside: 0
          };
        }
        if (date !== null) {
          if (item.facility === 'Affinity') {
            acc[date].Affinity++
          }
          else if (item.facility === 'Axis') {
            acc[date].Axis++
          }
          else if (item.facility === 'Beachside') {
            acc[date].Beachside++
          }
        }

        return acc
      },{})

      const result: AnticipatedDischargeDataProps[] = Object.values(groupedData)
      setAnticipatedDischargeData(result);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function convertToPST(dateString: any) {
    if (!dateString) return null;
    const date = new Date(dateString);
    // Convert to PST (UTC-8 or UTC-7 depending on daylight saving time)
    const utcOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const pstOffset = 8 * 60 * 60000; // PST offset in milliseconds
    const pstDate = new Date(date.getTime() - utcOffset + pstOffset);
    const month = ('0' + (pstDate.getMonth() + 1)).slice(-2);
    const day = ('0' + pstDate.getDate()).slice(-2);
    return `${month}-${day}`;
  }

  const searchIntakeRecords = (search: string) => {
    let filteredRecords = intakeRecords && intakeRecords.filter((record) => record.name.toUpperCase().includes(search.toUpperCase()));
    setFilteredIntakeRecords(filteredRecords != null ? filteredRecords : []);
  }

  const generateTenDigitNumber = () => {
    const min = 1000000000;
    const max = 9999999999;
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    return number.toString()
  }

  function getCurrentDateFormatted() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JS, add 1
    const day = now.getDate().toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  const handleAddRecord = () => {
    setAddRecord(!addRecord)
  }

  const addIntakeRecord = (data: any) => {
    setLoadingNewIntake(true)
    let intakeId = generateTenDigitNumber()
    let intakeData = { data: {
      "intake_id": intakeId,
      "name": data.name,
      "prefix": data.policy.slice(0, 3),
      "policy_id": data.policy,
      "insurance": data.insurance,
      "payer_id": data.payer_id,
      "date_of_birth": data.dob,
      "source": data.source,
      "coordinator": data.userId,
      "phone_number": data.phone_contact,
      "emergency_contact": data.emergency_contact,
      "emergency_number": data.emergency_number,
      "summary_out": 'PENDING',
      "booked": "Pending",
      "check_in": "Pending",
      "out_network_details": '',
      "in_network_details": '',
      "notes": data.notes,
      "created_date": getCurrentDateFormatted(),
      "tz": DateUtilityFunctions.getTimeZone()
    }}
    const url = `${baseUrl}/intake/`
    axios.post(url, intakeData)
      .then((response) => {
        setLoadingNewIntake(false)
        handleAddRecord()
        getIntakeRecords()
      })
      .catch((error) => {
        console.log(error);
        setLoadingNewIntake(false)
      });
}

  const handleShowTimeZones = () => {
    setShowTimeSelector(!showTimeSelector)
  }

  const contextValue: IntakeContextType = {
    timespan,
    updateTimespan,
    showPrefixPopup,
    showPrefix,
    availityData,
    hidePrefixPopup,
    grabPrefixRecordsFromDashboard,
    prefixDataDashboard,
    grabFullAvailityData,
    verifyLoading,
    yellowStripeRemindersData,
    fetchYellowStripeRemindersData,
    showBellReminder,
    setShowBellReminder,
    showRecords,
    toggleShowRecordsing,
    loadingRecordings,
    numberRecordings,
    recordingsRecord,
    fetchAnticipatedData,
    anticipatedDischargeData,
    selectedColumnsIntake,
    selectedColumnsIntakeDev,
    selectedColumnsIntakeAdmin,
    toggleToShowColumnIntake,
    toggleToShowColumnIntakeDev,
    toggleToShowColumnIntakeAdmin,
    handleDismissBellReminder,
    handleShowTimeZones,
    showTimeSelector,
    handleSelectedPatientRecord,
    selectedPatientRecord,
    getIntakeRecords,
    intakeRecords,
    filteredIntakeRecords,
    searchIntakeRecords,
    addIntakeRecord,
    loadingNewIntake,
    addRecord,
    updateFilteredIntakeRecords,
    filteredUser
  };

  return (
    <IntakeContext.Provider value={contextValue}>
      {children}
    </IntakeContext.Provider>
  );
};
