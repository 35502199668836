export const historic2Options = [
  {
    label:'Name',
    type:'text',
    recordName: 'name',
    width: '10rem'
  },
  {
    label:'Prefix',
    type:'text',
    recordName: 'prefix',
    width: '10rem'
  },
  {
    label:'Policy',
    type:'clickable',
    recordName: 'policy_id',
    width: '10rem'
  },
  {
    label:'Insurance',
    type:'text',
    recordName: 'insurance',
    width: '22rem'
  },
  {
    label:'Network',
    type:'text',
    recordName: 'network',
    width: '10rem'
  },
  {
    label:'Facility',
    type:'text',
    recordName: 'facility',
    width: '12rem'
  },
  {
    label: 'Avality Charged',
    type: 'dollar',
    recordName: 'availity_charged',
    width: '10rem'
  },
  {
    label: 'Avality Paid',
    type: 'dollar',
    recordName: 'availity_paid',
    width: '10rem'
  },
  {
    label: 'Avality Payout',
    type: 'percent',
    recordName: 'availity_payout',
    width: '10rem'
  },
  {
    label:'Avg. Charged',
    type:'dollar',
    recordName: 'average_charge',
    width: '10rem'
  },
  {
    label:'Avg. Paid',
    type:'dollar',
    recordName: 'average_paid',
    width: '10rem'
  },
  {
    label:'Balance',
    type:'dollar',
    recordName: 'balance',
    width: '10rem'
  },
  {
    label:'Payout %',
    type:'percent',
    recordName: 'payout',
    width: '10rem'
  },
  {
    label:'Last Updated',
    type:'date',
    recordName: 'last_updated',
    width: '10rem'
  },
]