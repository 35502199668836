import { useData } from "../../../Context/DataContext"
import { useEffect, useState } from "react";



const AvailityHeader = () => {
  const {availityData} = useData();

  const [totals, setTotals] = useState({ totalPaid: 0, totalClaim: 0 });

  useEffect(() => {
    const result = sumClaimAmounts(availityData);
    setTotals(result)
  }, [availityData])

  function sumClaimAmounts(data:any): { totalPaid: number, totalClaim: number } {
    const dataArray = data;
    let totalPaidAmount = 0;
    let totalClaimAmount = 0;
  
    const result = {
      totalPaid: totalPaidAmount,
      totalClaim: totalClaimAmount
    };
  
  
    for (let i = 0; i < dataArray.length; i++) {
      const paymentAmount = Number(data[i].paymentAmount);
      const claimAmount = Number (data[i].claimAmount);
  
      if (!isNaN(paymentAmount)) {
      totalPaidAmount += paymentAmount;
      }
  
      if (!isNaN(claimAmount)) {
      totalClaimAmount += claimAmount;
      }
    }
    

    result.totalPaid = totalPaidAmount;
    result.totalClaim = totalClaimAmount;

  
    return result;
  }
  
  // TODO: Make prettier

  return (             
    <div className="w-full text-black dark:text-white mt-3">
      {availityData[0].patientName}
      <div className="flex flex-row space-x-3 mt-4">
        <p>Total Paid Amount:     ${totals.totalPaid}   | </p>
        <p>  Total Claim Amount:      ${totals.totalClaim}   | </p>
        <p>  Total Number of Claims:     {availityData.length}   | </p>
        <p>  Payer Name:     {availityData[0].payerName}  |</p>
        <p>  Payer ID:     {availityData[0].payerId}  |</p>
      </div>
    </div>
  )
}

export default AvailityHeader;