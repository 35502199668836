import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import axios from 'axios';

interface ChatContextType {
  chatMessages: Message[];
  isLoading: boolean;
  handleSubmitMessage: (message: string) => void;
}

interface Message {
  message: string;
  sender: 'user' | 'ai' | 'loading';
}

const ChatContext = createContext<ChatContextType> ({
  chatMessages: [],
  isLoading: false,
  handleSubmitMessage: () => {console.log("Default handleSubmitMessage called")}
})

export function useChat() {
  return useContext(ChatContext)
}

interface AppProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [chatMessages, setChatMessages] = useState<Message[]>([]); // Where user and AI messages to be stored
  const [isLoading, setIsLoading] = useState(false); 


 


  const handleSubmitMessage = (message: string) => {
    setIsLoading(true);

    const newUserMessage: Message = { message, sender: 'user' };
    const loadingMessage: Message = { message: 'Loading...', sender: 'loading'};
    const allMessages = [...chatMessages, newUserMessage, loadingMessage];

    setChatMessages(allMessages)

    const url = 'https://intellachat-kwtb.onrender.com/chat'

    const data = JSON.stringify({ query: message });
    const config = {
      method: 'post',
      url: url, // Use the URL based on the user's role
      headers: { 'Content-Type': 'application/json' },
      data: data,
    };

    axios.request(config)
      .then((response) => 
        setChatMessages((prevMessages) => {
          // Remove the last loading message and add the AI response
          const updatedMessages = prevMessages.slice(0, -1);
          if (response.data.response && response.data.response.output) {
            const newAiMessage: Message = { message: response.data.response.output, sender: 'ai' };
            return [...updatedMessages, newAiMessage];
          }
          return updatedMessages;
        })
      )
      .catch((error) => {
        console.error("No response from Intellachat AI")
      })
  }
  
  const contextValue: ChatContextType = {
    chatMessages,
    isLoading,
    handleSubmitMessage
  };

  return (
    <ChatContext.Provider value={contextValue}>
    {children}
  </ChatContext.Provider>
  )
}