import TableComponent from "../../../Components/Tables/TableComponent";
import { useAuth } from "../../../Context/AuthContext";

import { useData } from "../../../Context/DataContext";
import { AvailityOptions } from "../../../Options/AvailityOptions";

const AvailityComponent = () => {
  const {availityData} = useData();
  const {allUsers} = useAuth()
  
  return (
    <div className="w-full px-4 mt-16" style={{ height: 'calc(100vh - 10.5rem)' }}>
      <div className="h-full overflow-scroll rounded-lg mt-2">
        <TableComponent records={availityData} columns={AvailityOptions} users={allUsers} table="Availity"/>
      </div>
    </div>
  )
}

export default AvailityComponent;