import SelectInputComponent from "./SelectInputComponent";

interface MonthYearSelectorProps {
  selectedMonth: string;
  selectedYear: string;
  onMonthChange: (month: string) => void;
  onYearChange: (year: string) => void; 
  label: string;
}

const MonthYearSelector: React.FC<MonthYearSelectorProps> = ({
  selectedMonth,
  selectedYear,
  onMonthChange,
  onYearChange,
  label
}) => {

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2022},
    (_, i): string => (currentYear - i).toString()
  )

  return (
    <div className='flex flex-row text-black dark:text-white items-center gap-3'>
      <p className='text-md mr-3'>{label}:</p>
      <SelectInputComponent
        placeholder="Month"
        options={months}
        selectedValue={selectedMonth}
        handleOptionClick={onMonthChange}
      />
      <SelectInputComponent
        placeholder="Year"
        options={years}
        selectedValue={selectedYear}
        handleOptionClick={onYearChange}
      />
    </div>
  )
}



export default MonthYearSelector