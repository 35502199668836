import React from 'react'
import {Home} from 'react-feather'
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../Context/NavigationContext';
import { useCustomTheme } from '../../Context/ThemeContext';

interface NavigationTabProps {
  tab: string;
  icon: string;
  route?: string;  // Made optional for popup functionality
  position: string;
  type: string;
  onClick?: () => void; 
}

const NavigationTabComponent: React.FC<NavigationTabProps> = (props) => {
  const {tab, icon, route, position, type, onClick} = props
  const IconComponent = getIconComponent(icon);
  const navigate = useNavigate()
  const location = useLocation();

  const {isDarkMode} = useCustomTheme()

  const { currentSidebarTab,
     handleUpdateCurrentSidebarTab, 
     handleUpdateCurrentSidebarType } = useNavigation()

  const handleRedirect = () => {
    if (route) {
      handleUpdateCurrentSidebarTab(tab, location.pathname)
      handleUpdateCurrentSidebarType(type)
    
      navigate(route)
    }
    if (onClick) {
      onClick();
    }
  }

  return (
    <div 
      onClick={() => {handleRedirect()}} 
      className={`w-full flex flex-row items-center hover:cursor-pointer 
        ${position === 'top' 
          ? 'hover:bg-slate-500 dark:hover:bg-slate-500 rounded-xl text-white h-10' 
          : currentSidebarTab === tab 
              ? 'bg-slate-600 hover:bg-slate-600 py-1.5' 
              : 'hover:bg-slate-600 dark:hover:bg-slate-600 py-1.5' }`}
    >
      {IconComponent && position === 'top' 
        ? <IconComponent size={20} className='mx-2.5 stroke-[2px] text-white' /> 
        : <IconComponent
            size={20}
            className={`ml-2 mr-2 text-white`}
          />
      } 
      <h1 className={`text-base ${currentSidebarTab === tab ? 'text-white dark:text-white' : 'text-white dark:text-white'}`}>{tab}</h1>
    </div>
  )
}

// color={currentSidebarTab === tab ? isDarkMode ? 'white' : 'black' : 'white'}

const getIconComponent = (icon: string) => {
  try {
    return require(`react-feather/dist/icons/${icon}`).default;
  } catch (error) {
    return null;
  }
};

export default NavigationTabComponent
