import IntellachatMessages from "./Messages/IntellachatMessages"
import IntellachatInput from "../../Components/Inputs/IntellachatInput"

const IntellachatPage = () => {
  return (
  <div className="max-h-full min-h-full h-full flex flex-col">
    <div className="w-full h-full overflow-y-scroll">
      <IntellachatMessages />
    </div>
    <div className="flex-shrink-0">
      <IntellachatInput />
    </div>
  </div>
  )
}

export default IntellachatPage