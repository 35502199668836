import React, { ReactNode, useRef } from 'react';
import TopRowComponent from '../Components/Navigation/TopRowComponent';
import SidebarComponent from '../Components/Navigation/SidebarComponent';

interface LayoutComponentProps {
  header: ReactNode | null;
  content: ReactNode | null;
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ header, content }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden" ref={containerRef}>
      <TopRowComponent />
      <div className="flex flex-1 flex-row bg-slate-100 dark:bg-slate-800">
        <div className='mt-2'>
          <SidebarComponent />
        </div>
        <div id="content" className="flex-1 max-h-full overflow-hidden">
          <div ref={contentRef} className="flex flex-col h-full w-full">
            {
              header === null
                ? null
                : <div className="w-full h-20 sticky top-0 z-20 px-4 pt-3">
                    {header}
                  </div>
            }
            <div id="main-content" className="flex-1 overflow-auto">
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
