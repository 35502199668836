export const CensusFollowupOptions = [
  {
    label:'Select',
    type:'checkbox',
    recordName: '',
    width: '5rem',
    sort: false,
  },
  {
    label:'Coordinator',
    type:'text',
    recordName: 'coordinator',
    people: [],
    width: '12rem',
    sort: false,
  },
  {
    label:'Claim Id',
    type:'text',
    recordName: 'claim_id',
    width: '10rem',
    sort: false,
  },
  {
    label:'New Claim Id',
    type:'text',
    recordName: 'new_claim_id',
    width: '12rem',
    sort: false,
  },
  {
    label:'In Collab',
    type:'text',
    recordName: 'claim_status',
    width: '12rem',
    sort: false,
  },
  {
    label:'CPT Code',
    type:'text',
    recordName: 'cpt_code',
    width: '8rem',
    sort: false,
  },
  {
    label:'Auth. Code',
    type:'text',
    recordName: 'authorization_number',
    width: '12rem',
    sort: false,
  },
  {
    label:'LOC',
    type:'text',
    recordName: 'level_of_care',
    width: '8rem',
    sort: false,
  },
  {
    label:'Name',
    type:'merge_name',
    recordName: 'name',
    width: '16rem',
    sort: false,
  },
  {
    label:'DOB',
    type:'date',
    recordName: 'date_of_birth',
    width: '10rem',
    sort: false,
  },
  {
    label:'Policy',
    type:'text',
    recordName: 'policy_no',
    width: '12rem',
    sort: false,
  },
  {
    label:'Insurance',
    type:'text',
    recordName: 'insurance_name',
    width: '20rem',
    sort: false,
  },
  {
    label:'Facility',
    type:'text',
    recordName: 'facility',
    width: '8rem',
    sort: false,
  },
  {
    label:'Start Date',
    type:'date',
    recordName: 'start_date',
    width: '12rem',
    sort: false,
  },
  {
    label:'End Date',
    type:'date',
    recordName: 'end_date',
    width: '12rem',
    sort: false,
  },
  {
    label:'Submission Date',
    type:'date',
    recordName: 'submission_date',
    width: '12rem',
    sort: false,
  },
]