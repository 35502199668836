import { CheckCircle, RefreshCcw } from 'react-feather';
import LayoutComponent from '../BaseScreen';
import { useParams } from 'react-router-dom';
import AvailityFilterComponent from '../../Components/SortAndFilter/AvailityFilterComponent';
import { useEffect } from 'react';
import { useIntake } from '../../Context/IntakeContext';
import { DateUtilityFunctions } from '../../Utils/DateUtilityFunctions';

const VerifyDetailsScreen = () => {
  const { policy } = useParams();
  const { availityData, verifyLoading } = useIntake();




  useEffect(() => {}, [availityData]);

  return (
    <LayoutComponent
      header={<AvailityFilterComponent />}
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2 text-black dark:text-white">
          {verifyLoading === false ? (
            <>
              <div className='flex flex-row w-full pt-3 px-3 justify-between items-end'>
                <p className='text-3xl font-bold'>
                  {availityData ? availityData.name : ''}
                </p>
                <p>Last Updated: {availityData.time_stap ? DateUtilityFunctions.removeGMT(availityData.time_stap) : "Not available"}</p>
                <div className='flex flex-row justify-end items-center'>
                  <CheckCircle
                    size={16}
                    color={`${
                      availityData &&
                      availityData.insurance_coverage === 'Active Coverage'
                        ? 'green'
                        : 'red'
                    }`}
                    className='mr-1'
                  />
                  <p
                    className={`text-${
                      availityData &&
                      availityData.insurance_coverage === 'Active Coverage'
                        ? 'green-500'
                        : 'red-500'
                    } text-xl font-semibold`}
                  >
                    {availityData.insurance_coverage
                      ? 'Active Coverage'
                      : 'No Coverage'}
                  </p>
                </div>
              </div>
              <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
                <p className=' font-bold text-2xl'>Insurance Details</p>
              </div>
              <div className='w-full flex flex-row'>
                <div className='flex flex-col w-1/2 pt-2 px-3'>
                  <p className=' text-base'>
                    Reference Number: {availityData ? availityData.reference_number : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Date Of Birth: {availityData ? DateUtilityFunctions.ReformatDate(availityData.dob) : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Age: {availityData ? DateUtilityFunctions.calculateAge(DateUtilityFunctions.ReformatDate(availityData.dob)) : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Insurance Type: {availityData ? availityData.insurance_type : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Plan/Product : {availityData ? availityData.insurance_product : ''}
                  </p>
                </div>
                <div className='flex flex-col w-1/2 pt-2 px-3'>
                  <p className=' text-base mt-2'>
                    Gender: {availityData && availityData.gender === 'M' ? 'Male' : 'Female'}
                  </p>
                  <p className=' text-base'>
                    Payer Id: {availityData ? availityData.payer_id : ''}
                  </p>
                  <p className=' text-base'>
                    Policy Id: {availityData ? availityData.member_id : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Insurance: {availityData ? availityData.insurance_name : ''}
                  </p>
                  <p className=' text-base mt-2'>
                    Coverage Start Date: {availityData ? DateUtilityFunctions.ReformatDate(availityData.insurance_start_date) : ''}
                  </p>
                </div>
              </div>
              <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
                <p className=' font-bold text-2xl'>In Network</p>
              </div>
              {availityData.insurance_coverage === 'Active Coverage' ? (
                <>
                  <div className='w-full flex flex-row gap-2'>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Individual Deductible:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.inn_deductibles.total ? availityData.inn_deductibles.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.inn_deductibles.remaining ? availityData.inn_deductibles.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.inn_deductibles.amount ? availityData.inn_deductibles.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Individual Out Of Pocket:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.inn_oop.total ? availityData.inn_oop.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.inn_oop.remaining ? availityData.inn_oop.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.inn_oop.amount ? availityData.inn_oop.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Family Deductible:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.inn_deductibles_fam.total ? availityData.inn_deductibles_fam.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.inn_deductibles_fam.remaining ? availityData.inn_deductibles_fam.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.inn_deductibles_fam.amount ? availityData.inn_deductibles_fam.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Family Out Of Pocket:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.inn_fam_oop.total ? availityData.inn_fam_oop.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.inn_fam_oop.remaining ? availityData.inn_fam_oop.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.inn_fam_oop.amount ? availityData.inn_fam_oop.amount : '0'} Used
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-row mt-10'>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Substance Abuse Facility - Inpatient:
                      </p>
                      <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                      <p className=' text-base mt-2'>
                        Client: {availityData.inn_coinsurance.client_percent !== 'null' && availityData.inn_coinsurance.client_percent !== undefined ? availityData.inn_coinsurance.client_percent : '100%'} / Provider: {availityData.inn_coinsurance.provider_percent !== 'null' && availityData.inn_coinsurance.provider_percent !== undefined ? availityData.inn_coinsurance.provider_percent : '0%'}
                      </p>
                      <p className=' text-base font-bold mt-2'>
                        Copayment: $
                        {availityData.inn_copayment_substance.amount
                          ? availityData.inn_copayment_substance.amount
                          : '0'}
                      </p>
                      <p className=' text-base mt-2'>
                        Authorization Required: {availityData.inn_copayment_substance.authorization_required ? 'Yes' : 'No'}
                      </p>
                      <div className='flex flex-col gap-2 mt-2'>
                        <p className=' text-xl font-semibold'>Payer Notes:</p>
                        {availityData.inn_copayment_substance.payerNotes ? (
                          availityData.inn_copayment_substance.payerNotes.map((note: any, index: any) => (
                            <p key={index} className=' text-base'>{note}</p>
                          ))
                        ) : (
                          <p className=' text-base'>Not available</p>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Substance Abuse Facility - Outpatient:
                      </p>
                      <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                      <p className=' text-base mt-2'>
                        Client: {availityData.inn_coinsurance_outpatient.client_percent !== 'null' && availityData.inn_coinsurance_outpatient.client_percent !== undefined ? availityData.inn_coinsurance_outpatient.client_percent : '100%'} / Provider: {availityData.inn_coinsurance_outpatient.provider_percent !== 'null' && availityData.inn_coinsurance_outpatient.provider_percent !== undefined ? availityData.inn_coinsurance_outpatient.provider_percent : '0%'}
                      </p>
                      <p className=' text-base font-bold mt-2'>
                        Copayment: $
                        {availityData.inn_copayment_substance_outpatient.amount
                          ? availityData.inn_copayment_substance_outpatient.amount
                          : '0'}
                      </p>
                      <p className=' text-base mt-2'>
                        Authorization Required: {availityData.inn_copayment_substance_outpatient.authorization_required ? 'Yes' : 'No'}
                      </p>
                      <div className='flex flex-col gap-2 mt-2'>
                        <p className=' text-xl font-semibold'>Payer Notes:</p>
                        {availityData.inn_copayment_substance_outpatient.payerNotes ? (
                          availityData.inn_copayment_substance_outpatient.payerNotes.map((note: any, index: any) => (
                            <p key={index} className=' text-base'>{note}</p>
                          ))
                        ) : (
                          <p className=' text-base'>Not available</p>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Mental Health Facility - Inpatient:
                      </p>
                      <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                      <p className=' text-base mt-2'>
                        Client: {availityData.inn_coinsurance_mental.client_percent !== 'null' && availityData.inn_coinsurance_mental.client_percent !== undefined ? availityData.inn_coinsurance_mental.client_percent : '100%'} / Provider: {availityData.inn_coinsurance_mental.provider_percent !== 'null' && availityData.inn_coinsurance_mental.provider_percent !== undefined ? availityData.inn_coinsurance_mental.provider_percent : '0%'}
                      </p>
                      <p className=' text-base font-bold mt-2'>
                        Copayment: $
                        {availityData.inn_copayment_mental.amount ? availityData.inn_copayment_mental.amount : '0'}
                      </p>
                      <p className=' text-base mt-2'>
                        Authorization Required: {availityData.inn_copayment_mental.authorization_required ? 'Yes' : 'No'}
                      </p>
                      <div className='flex flex-col gap-2 mt-2'>
                        <p className=' text-xl font-semibold'>Payer Notes:</p>
                        {availityData.inn_copayment_mental.payerNotes ? (
                          availityData.inn_copayment_mental.payerNotes.map((note: any, index: any) => (
                            <p key={index} className=' text-base'>{note}</p>
                          ))
                        ) : (
                          <p className=' text-base'>Not available</p>
                        )}
                      </div>
                    </div>

                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Mental Health Facility - Outpatient:
                      </p>
                      <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                      <p className=' text-base mt-2'>
                        Client: {availityData.inn_coinsurance_mental_outpatient.client_percent !== 'null' && availityData.inn_coinsurance_mental_outpatient.client_percent !== undefined ? availityData.inn_coinsurance_mental_outpatient.client_percent : '100%'} / Provider: {availityData.inn_coinsurance_mental_outpatient.provider_percent !== 'null' && availityData.inn_coinsurance_mental_outpatient.provider_percent !== undefined ? availityData.inn_coinsurance_mental_outpatient.provider_percent : '0%'}
                      </p>
                      <p className=' text-base font-bold mt-2'>
                        Copayment: $
                        {availityData.inn_copayment_mental_outpatient.amount ? availityData.inn_copayment_mental_outpatient.amount : '0'}
                      </p>
                      <p className=' text-base mt-2'>
                        Authorization Required: {availityData.inn_copayment_mental_outpatient.authorization_required ? 'Yes' : 'No'}
                      </p>
                      <div className='flex flex-col gap-2 mt-2'>
                        <p className=' text-xl font-semibold'>Payer Notes:</p>
                        {availityData.inn_copayment_mental_outpatient.payerNotes ? (
                          availityData.inn_copayment_mental_outpatient.payerNotes.map((note: any, index: any) => (
                            <p key={index} className=' text-base'>{note}</p>
                          ))
                        ) : (
                          <p className=' text-base'>Not available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className='text-xl font-semibold  p-4'>No Coverage</p>
              )}
              <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
                <p className=' font-bold text-2xl'>Out Of Network</p>
              </div>
              {availityData.insurance_coverage === 'Active Coverage' ? (
                <>
                  <div className='w-full flex flex-row'>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Individual Deductible:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.oon_deductibles.total ? availityData.oon_deductibles.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.oon_deductibles.remaining ? availityData.oon_deductibles.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.oon_deductibles.amount ? availityData.oon_deductibles.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Individual Out Of Pocket:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.oon_oop.total ? availityData.oon_oop.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.oon_oop.remaining ? availityData.oon_oop.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.oon_oop.amount ? availityData.oon_oop.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Family Deductible:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.oon_fam_deductibles.total ? availityData.oon_fam_deductibles.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.oon_fam_deductibles.remaining ? availityData.oon_fam_deductibles.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.oon_fam_deductibles.amount ? availityData.oon_fam_deductibles.amount : '0'} Used
                      </p>
                    </div>
                    <div className='flex flex-col w-1/2 pt-2 px-3'>
                      <p className=' text-xl font-semibold'>
                        Family Out Of Pocket:
                      </p>
                      <p className=' text-base mt-4'>
                        Total: ${availityData.oon_fam_oop.total ? availityData.oon_fam_oop.total : '0'} Calendar Year
                      </p>
                      <p className=' text-base'>
                        Remaining: ${availityData.oon_fam_oop.remaining ? availityData.oon_fam_oop.remaining : '0'} Remaining
                      </p>
                      <p className=' text-base'>
                        Used: ${availityData.oon_fam_oop.amount ? availityData.oon_fam_oop.amount : '0'} Used
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-row mt-2'>
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className=' text-xl font-semibold'>
                      Substance Abuse Facility - Inpatient:
                    </p>
                    <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                    <p className=' text-base mt-2'>
                      Client: {availityData.oon_coinsurance.client_percent !== 'null' && availityData.oon_coinsurance.client_percent !== undefined ? availityData.oon_coinsurance.client_percent : '100%'} / Provider: {availityData.oon_coinsurance.provider_percent !== 'null' && availityData.oon_coinsurance.provider_percent !== undefined ? availityData.oon_coinsurance.provider_percent : '0%'}
                    </p>
                    <p className=' text-base font-bold mt-2'>
                      Copayment: $
                      {availityData.oon_copayment_substance.amount ? availityData.oon_copayment_substance.amount : '0'}
                    </p>
                    <p className=' text-base mt-2'>
                      Authorization Required: {availityData.oon_copayment_substance.authorization_required ? 'Yes' : 'No'}
                    </p>
                    <div className='flex flex-col gap-2 mt-2'>
                      <p className=' text-xl font-semibold'>Payer Notes:</p>
                      {availityData.oon_copayment_substance.payerNotes ? (
                        availityData.oon_copayment_substance.payerNotes.map((note: any, index: any) => (
                          <p key={index} className=' text-base'>{note}</p>
                        ))
                      ) : (
                        <p className=' text-base'>Not available</p>
                      )}
                    </div>
                  </div>
                  
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className=' text-xl font-semibold'>
                      Substance Abuse Facility - Outpatient:
                    </p>
                    <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                    <p className=' text-base mt-2'>
                      Client: {availityData.oon_coinsurance_outpatient.client_percent !== 'null' && availityData.oon_coinsurance_outpatient.client_percent !== undefined ? availityData.oon_coinsurance_outpatient.client_percent : '100%'} / Provider: {availityData.oon_coinsurance_outpatient.provider_percent !== 'null' && availityData.oon_coinsurance_outpatient.provider_percent !== undefined ? availityData.oon_coinsurance_outpatient.provider_percent : '0%'}
                    </p>
                    <p className=' text-base font-bold mt-2'>
                      Copayment: $
                      {availityData.oon_copayment_substance_outpatient.amount ? availityData.oon_copayment_substance_outpatient.amount : '0'}
                    </p>
                    <p className=' text-base mt-2'>
                      Authorization Required: {availityData.oon_copayment_substance_outpatient.authorization_required ? 'Yes' : 'No'}
                    </p>
                    <div className='flex flex-col gap-2 mt-2'>
                      <p className=' text-xl font-semibold'>Payer Notes:</p>
                      {availityData.oon_copayment_substance_outpatient.payerNotes ? (
                        availityData.oon_copayment_substance_outpatient.payerNotes.map((note: any, index: any) => (
                          <p key={index} className=' text-base'>{note}</p>
                        ))
                      ) : (
                        <p className=' text-base'>Not available</p>
                      )}
                    </div>
                  </div>
                  
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className=' text-xl font-semibold'>
                      Mental Health Facility - Inpatient:
                    </p>
                    <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                    <p className=' text-base mt-2'>
                      Client: {availityData.oon_coinsurance_mental.client_percent !== 'null' && availityData.oon_coinsurance_mental.client_percent !== undefined ? availityData.oon_coinsurance_mental.client_percent : '100%'} / Provider: {availityData.oon_coinsurance_mental.provider_percent !== 'null' && availityData.oon_coinsurance_mental.provider_percent !== undefined ? availityData.oon_coinsurance_mental.provider_percent : '0%'}
                    </p>
                    <p className=' text-base font-bold mt-2'>
                      Copayment: $
                      {availityData.oon_copayment_mental.amount ? availityData.oon_copayment_mental.amount : '0'}
                    </p>
                    <p className=' text-base mt-2'>
                      Authorization Required: {availityData.oon_copayment_mental.authorization_required ? 'Yes' : 'No'}
                    </p>
                    <div className='flex flex-col gap-2 mt-2'>
                      <p className=' text-xl font-semibold'>Payer Notes:</p>
                      {availityData.oon_copayment_mental.payerNotes ? (
                        availityData.oon_copayment_mental.payerNotes.map((note: any, index: any) => (
                          <p key={index} className=' text-base'>{note}</p>
                        ))
                      ) : (
                        <p className=' text-base'>Not available</p>
                      )}
                    </div>
                  </div>
                  
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className=' text-xl font-semibold'>
                      Mental Health Facility - Outpatient:
                    </p>
                    <p className=' text-l font-semibold mt-3'>Coinsurance:</p>
                    <p className=' text-base mt-2'>
                      Client: {availityData.oon_coinsurance_mental_outpatient.client_percent !== 'null' && availityData.oon_coinsurance_mental_outpatient.client_percent !== undefined ? availityData.oon_coinsurance_mental_outpatient.client_percent : '100%'} / Provider: {availityData.oon_coinsurance_mental_outpatient.provider_percent !== 'null' && availityData.oon_coinsurance_mental_outpatient.provider_percent !== undefined ? availityData.oon_coinsurance_mental_outpatient.provider_percent : '0%'}
                    </p>
                    <p className=' text-base font-bold mt-2'>
                      Copayment: $
                      {availityData.oon_copayment_mental_outpatient.amount ? availityData.oon_copayment_mental_outpatient.amount : '0'}
                    </p>
                    <p className=' text-base mt-2'>
                      Authorization Required: {availityData.oon_copayment_mental_outpatient.authorization_required ? 'Yes' : 'No'}
                    </p>
                    <div className='flex flex-col gap-2 mt-2'>
                      <p className=' text-xl font-semibold'>Payer Notes:</p>
                      {availityData.oon_copayment_mental_outpatient.payerNotes ? (
                        availityData.oon_copayment_mental_outpatient.payerNotes.map((note: any, index: any) => (
                          <p key={index} className=' text-base'>{note}</p>
                        ))
                      ) : (
                        <p className=' text-base'>Not available</p>
                      )}
                    </div>
                  </div>
                </div>

                </>
              ) : (
                <p className='text-xl font-semibold  p-4'>No Coverage</p>
              )}
            </>
          ) : (
            <div className='text-3xl  font-bold flex-1 h-full flex justify-center items-center'>
              <RefreshCcw height={38} width={38} color='green' className='animate-spin' />
            </div>
          )}
          </div>
        </div>
      }
    />
  );
};

export default VerifyDetailsScreen;
