export const historicOptions = [
  {
    label:'Prefix',
    type:'clickable',
    recordName: 'prefix',
    width: '8rem'
  },
  {
    label:'Insurance',
    type:'text',
    recordName: 'insurance',
    width: '14rem'
  },
  {
    label:'Network',
    type:'text',
    recordName: 'network',
    width: '8rem'
  },
  {
    label:'Avg. Charge',
    type:'dollar',
    recordName: 'average_charged',
    width: '10rem'
  },
  {
    label:'Avg. Paid',
    type:'dollar',
    recordName: 'average_paid',
    width: '10rem'
  },
  {
    label:'Payout %',
    type:'percent',
    recordName: 'payout_ratio',
    width: '10rem'
  },
  {
    label:'Admission',
    type:'blank',
    recordName: 'network',
    width: '10rem'
  },
  {
    label:'Admission %',
    type:'blank',
    recordName: 'network',
    width: '10rem'
  },
]