import { CheckCircle, RefreshCcw } from 'react-feather';
import LayoutComponent from '../BaseScreen';
import { useParams } from 'react-router-dom';
import AvailityFilterComponent from '../../Components/SortAndFilter/AvailityFilterComponent';
import { useEffect } from 'react';
import { useIntake } from '../../Context/IntakeContext';
const CensusVerifyScreen = () => {
  const { policy } = useParams();

  const {availityData, verifyLoading} = useIntake()

  useEffect(() => {
  }, [availityData])
  
  return (
    <LayoutComponent
      header={
        <AvailityFilterComponent />
      }
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
            <div className="h-full overflow-scroll rounded-lg mt-2">
          {
            verifyLoading === false
              ? <>
                <div className='flex flex-row w-full pt-3 px-3 justify-between items-end'>
                  <p className='text-black dark:text-white text-3xl font-bold'>{availityData ? availityData.name : ''}</p>
                  <div className='flex flex-row justify-end items-center'>
                    <CheckCircle size={16} color={`${availityData && availityData.insurance_coverage === 'Active Coverage' ? 'green' : 'red'}`} className='mr-1'/>
                    <p className={`text-${availityData && availityData.insurance_coverage === 'Active Coverage' ? 'green-500' : 'red-500'} text-xl font-semibold`}>{availityData.insurance_coverage ? 'Active Coverage' : 'No Coverage'}</p>
                  </div>
                </div> 
                <div className='w-full h-10 bg-primary rounded-md flex flex-row items-center justify-between p-4 mt-5'>
                  <p className='text-white font-bold text-2xl'>Insurance Details</p>
                </div>
                <div className='w-full flex flex-row'>
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className='text-black dark:text-white text-base'>Reference Number: {availityData ? availityData.reference_number : ''}</p>
                    <p className='text-black dark:text-white text-base mt-2'>Date Of Birth: {availityData ? availityData.dob : ''}</p>
                    <p className='text-black dark:text-white text-base mt-2'>Age: {availityData ? availityData.dob : ''}</p>
                  </div>
                  <div className='flex flex-col w-1/2 pt-2 px-3'>
                    <p className='text-black dark:text-white text-base mt-2'>Gender: {availityData && availityData.gender === 'M' ? 'Male' : 'Female'}</p>
                    <p className='text-black dark:text-white text-base'>Policy Id: {availityData ? availityData.member_id : ''}</p>
                    <p className='text-black dark:text-white text-base mt-2'>Insurance: {availityData ? availityData.insurance_name : ''}</p>
                    <p className='text-black dark:text-white text-base mt-2'>Coverage Start Date: {availityData ? availityData.insurance_start_date : ''}</p>
                  </div>
                </div>
                <div className='w-full h-10 bg-primary rounded-md flex flex-row items-center justify-between p-4 mt-5'>
                  <p className='text-white font-bold text-2xl'>In Network</p>
                </div>
                {
                  availityData.insurance_coverage === 'Active Coverage'
                    ? <>
                        <div className='w-full flex flex-row'>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Individual Deductible: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.inn_deductibles.total ? availityData.inn_deductibles.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.inn_deductibles.remaining ? availityData.inn_deductibles.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.inn_deductibles.amount ? availityData.inn_deductibles.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Individual Out Of Pocket: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.inn_oop.total ? availityData.inn_oop.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.inn_oop.remaining ? availityData.inn_oop.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.inn_oop.amount ? availityData.inn_oop.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Family Deductible: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.inn_deductibles_fam.total ? availityData.inn_deductibles_fam.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.inn_deductibles_fam.remaining ? availityData.inn_deductibles_fam.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.inn_deductibles_fam.amount ? availityData.inn_deductibles_fam.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Family Out Of Pocket: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.inn_fam_oop.total ? availityData.inn_fam_oop.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.inn_fam_oop.remaining ? availityData.inn_fam_oop.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.inn_fam_oop.amount ? availityData.inn_fam_oop.amount : '0'} Used</p>
                          </div>
                        </div>
                        <div className='w-full flex flex-row'>
                          {/* <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Substance Abuse Facility - Outpatient: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Client: 50 % / Provider: 50 %</p>
                          </div> */}
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Substance Abuse Facility - Inpatient: </p>
                            <p className='text-black dark:text-white text-l font-semibold mt-3'>CoInsurance: </p>
                            <p className='text-black dark:text-white text-base mt-2'>Client: {availityData.inn_coinsurance.client_percent !== "null" && availityData.inn_coinsurance.client_percent !== undefined ? availityData.inn_coinsurance.client_percent : "100%"} / Provider: {availityData.inn_coinsurance.provider_percent !== "null" && availityData.inn_coinsurance.provider_percent !== undefined ? availityData.inn_coinsurance.provider_percent : "0%"}</p>
                          </div>
                        </div>
                      </>
                    : <p className='text-xl font-semibold text-black dark:text-white p-4'>No Coverage</p>
                }
                <div className='w-full h-10 bg-primary rounded-md flex flex-row items-center justify-between p-4 mt-5'>
                  <p className='text-white font-bold text-2xl'>Out Of Network</p>
                </div>
                {
                  availityData.insurance_coverage === 'Active Coverage'
                    ? <>
                        <div className='w-full flex flex-row'>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Individual Deductible: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.oon_deductibles.total ? availityData.oon_deductibles.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.oon_deductibles.remaining ? availityData.oon_deductibles.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.oon_deductibles.amount ? availityData.oon_deductibles.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Individual Out Of Pocket: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.oon_oop.total ? availityData.oon_oop.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.oon_oop.remaining ? availityData.oon_oop.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.oon_oop.amount ? availityData.oon_oop.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Family Deductible: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.oon_fam_deductibles.total ? availityData.oon_fam_deductibles.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.oon_fam_deductibles.remaining ? availityData.oon_fam_deductibles.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.oon_fam_deductibles.amount ? availityData.oon_fam_deductibles.amount : '0'} Used</p>
                          </div>
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Family Out Of Pocket: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Total: ${availityData.oon_fam_oop.total ? availityData.oon_fam_oop.total : '0'} Calendar Year</p>
                            <p className='text-black dark:text-white text-base'>Remaining: ${availityData.oon_fam_oop.remaining ? availityData.oon_fam_oop.remaining : '0'} Remaining</p>
                            <p className='text-black dark:text-white text-base'>Used: ${availityData.oon_fam_oop.amount ? availityData.oon_fam_oop.amount : '0'} Used</p>
                          </div>
                        </div>
                        <div className='w-full flex flex-row'>
                          {/* <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Substance Abuse Facility - Outpatient: </p>
                            <p className='text-black dark:text-white text-base mt-4'>Client: 50 % / Provider: 50 %</p>
                          </div> */}
                          <div className='flex flex-col w-1/2 pt-2 px-3'>
                            <p className='text-black dark:text-white text-xl font-semibold'>Substance Abuse Facility - Inpatient: </p>
                            <p className='text-black dark:text-white text-l font-semibold mt-3'>CoInsurance: </p>
                            <p className='text-black dark:text-white text-base mt-2'>Client: {availityData.oon_coinsurance.client_percent !== "null" && availityData.oon_coinsurance.client_percent !== undefined ? availityData.oon_coinsurance.client_percent : '100%'} / Provider: {availityData.oon_coinsurance.provider_percent !== "null" && availityData.oon_coinsurance.provider_percent !== undefined  ? availityData.oon_coinsurance.provider_percent : "0%"}</p>
                          </div>
                        </div>
                      </>
                    : <p className='text-xl font-semibold text-black dark:text-white p-4'>No Coverage</p>
                }
                </>
              : <div className='text-3xl text-black dark:text-white font-bold flex-1 h-full flex justify-center items-center'><RefreshCcw height={38} width={38} color='white' className='animate-spin'/></div>
          }
          </div>
        </div>
      } 
    />
  )
}
export default CensusVerifyScreen