import React from 'react'
import LayoutComponent from '../BaseScreen'
import CollabFormFilterComponent from '../../Components/SortAndFilter/CollabFormFilterComponent'
import CollabForm from '../../Components/Forms/CollabForm'

const CollabFormScreen = () => {
  return (
    <LayoutComponent
      header={<CollabFormFilterComponent />} // Render your custom header component here
      content={<CollabForm />} // Render your custom content component here
    />
  )
}

export default CollabFormScreen
