import React, { useEffect, useState } from 'react'
import { Route, useParams } from 'react-router-dom';
import LayoutComponent from '../BaseScreen'
import HistoricFilterComponent from '../../Components/SortAndFilter/HistoricFilterComponent'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { useHistoric } from '../../Context/HistoricContext';
import { historic2Options } from '../../Options/Historic2Options';
import UserInfo from '../../Components/Forms/UserInfo';
import { Historic1CareOptions } from '../../Options/Historic1CareOptions';
import { Historic1FinancialOptions } from '../../Options/Historic1FinancialCOptions';
import Historic1FilterComponent from '../../Components/SortAndFilter/Historic1FilterComponent';
import { useAuth } from '../../Context/AuthContext';

const Historic1Screen = () => {
  const {selectedUserInfo, selectedUserCare, selectedUserFinancial} = useHistoric()
  const {allUsers} = useAuth()

  return (
      <LayoutComponent 
        header={
          <Historic1FilterComponent />
        } 
        content={
          <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
            <div className="h-full overflow-scroll rounded-lg mt-2">
            <UserInfo
              name={selectedUserInfo ? selectedUserInfo?.name : ''}
              policy={selectedUserInfo ? selectedUserInfo?.policy : ''}
              prefix={selectedUserInfo ? selectedUserInfo?.prefix : ''}
              insurance={selectedUserInfo ? selectedUserInfo?.insurance : ''}
              dob={selectedUserInfo ? selectedUserInfo?.dob : ''}
              facility={selectedUserInfo ? selectedUserInfo?.facility : ''}
            />
            <div className='my-4 rounded-lg overflow-hidden'>
              <TableComponent  table='HistoricUserCare' users={allUsers} columns={Historic1CareOptions} records={selectedUserCare}/>
            </div>
            <div className='my-4 rounded-lg overflow-hidden'>
              <TableComponent  table='HistoricUserCare' users={allUsers} columns={Historic1FinancialOptions} records={selectedUserFinancial}/>
            </div>  
          </div>
          </div>
        }
      />
  )
}

export default Historic1Screen