import React, { useState } from 'react'
import ButtonComponent from '../Inputs/ButtonComponent'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNavigation } from '../../Context/NavigationContext'
import { useFollowup } from '../../Context/FollowupContext'


const CollabFormFilterComponent = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const  { setCollabFormData } = useFollowup();
  const {currentSidebarTab, handleUpdateCurrentSidebarTab, currentSidebarSubTab, handleUpdateCurrentSidebarSubTab} = useNavigation()
  const goBack = () => {
    handleUpdateCurrentSidebarTab('Follow Up', location.pathname)
    currentSidebarSubTab === 'Collab Md' ? handleUpdateCurrentSidebarSubTab('Collab Md') : handleUpdateCurrentSidebarSubTab('Avea')  
    navigate(-1);
    setCollabFormData([])
  };
  
  return (
    <div className="h-14 px-2 w-full flex flex-col justify-center bg-stone-700 rounded-md mb-4">
      <ButtonComponent label='Back' handler={goBack}/>
    </div>
  )
}

export default CollabFormFilterComponent
