import React, { useEffect, useState } from 'react'
import SelectOptionComponent from './SelectOptionComponent';
import CalendarSelectComponent from '../Inputs/CalendarSelectComponent';
import SelectPeopleComponent from './SelectPeopleComponent';
import { useData } from '../../Context/DataContext';
import axios from 'axios';
import baseUrl from '../../Utils/baseUrl';
import { Check, Edit, RefreshCw, X } from 'react-feather';
import { setRef } from '@mui/material';
import DateChangeComponent from '../Inputs/DateChangeComponent';
import InsuranceSelectComponent from './InsuranceSelectComponent';
import EditPolicyComponent from './EditPolicyComponent';
import DateSelectionComponent from '../Inputs/DateSelectionComponent';
import NotesForm from '../Forms/NotesForm';
import { useClaims } from '../../Context/ClaimsContext';
import { useNavigation } from '../../Context/NavigationContext';
import { useFollowup } from '../../Context/FollowupContext';
import ButtonComponent from '../Inputs/ButtonComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { useHistoric } from '../../Context/HistoricContext';
import SingleClickTabComponent from '../Navigation/SingleClickTabComponent';
import SingleSelectClickComponent from './SingleSelectClickComponent';
import { useAuth } from '../../Context/AuthContext';
import { useIntake } from '../../Context/IntakeContext';
import ClaimNotesForm from '../Forms/ClaimsNotesForm';
import { isNullishCoalesce } from 'typescript';
import ReactPlayer from 'react-player';
import EditNameComponent from '../Inputs/EditNameComponent';

interface PeopleOptions {
  name: string;
  userId: string;
}

interface ColumnData {
  label: string;
  type: string;
  recordName: string;
  options?: string[];
  dependent?: string;
  dependentResults?: string[];
  people?: PeopleOptions[];
  width?: string;
  admin?: boolean;
}

interface RecordData {
  [key: string]: any;
}

interface CellProps {
  columns: ColumnData[],
  record: RecordData,
  table: string,
  selectedClaims?: string[] | null
}

const CellComponent: React.FC<CellProps> = ({columns, record, table, selectedClaims}) => {
  const navigate = useNavigate()
  const {insuranceOptions, grabAvailityData, grabAveaAvailityData, grabCensusAvailityData, getNotes} = useData()
  const {updateSelectedClaims, updateSelectedClaimsAvea, getClaimsNotes, selectedColumns, selectedColumnsAvea, selectedColumnsCensus} = useClaims()
  const {grabPrefixRecords, grabUserRecords} = useHistoric()
  const {currentSidebarTab, currentSidebarSubTab, handleUpdateCurrentSidebarTab} = useNavigation()
  const {selectedFollowup, updateSelectedFollowup, updateCoordinatorFollwup, 
    updateCoordinatorClaims, removeBatchToFavorites, fetchAutomateCollab, 
    updateFollowupClaim, selectedColumnsFollowup, selectedColumnsFollowupAvea, 
    selectedColumnsFollowupCensus, setCollabDisableEdit} = useFollowup()
  const {currentProfile, intakeUsers, followupUsers, claimsUsers, allUsers, grabAllProfiles} = useAuth()
  const {showPrefixPopup, showPrefix, grabPrefixRecordsFromDashboard, 
    grabFullAvailityData, toggleShowRecordsing, showRecords, 
    loadingRecordings, numberRecordings, selectedColumnsIntake, recordingsRecord, handleSelectedPatientRecord, getIntakeRecords} = useIntake();
  const location = useLocation()


  const [selectedDate, setSelectedDate] = useState(record.expected_arrival_date ? record.expected_arrival_date : new Date())
  const [dobDate, setDobDate] = useState(record.date_of_birth)

  const [editDate, setEditDate] = useState<boolean>(false)

  const [editInsurance, setEditInsurance] = useState<boolean>(false)
  const [editPolicy, setEditPolicy] = useState<boolean>(false)
  const [editName, setEditName] = useState<boolean>(false)

  const [columnName, setColumnName] = useState<string>()
  const [singleRecord, setSingleRecord] = useState<any>()
  const [value, setValue] = useState<any>()

  const [showNotes, setShowNotes] = useState<boolean>(false)

  const [playing, setPlaying] = useState(false);

  const toggleShowPopup = () => {
    setShowNotes(!showNotes)
  }

  const updateEditDate = (columnName: string, record: any, value: any) => {
    setValue(value)
    setColumnName(columnName)
    setSingleRecord(record)
    setEditDate(!editDate)
  }

  const toggleEditInsurance = (columnName: string, record: any, value: any) => {
    setValue(value)
    setColumnName(columnName)
    setSingleRecord(record)
    setEditInsurance(!editInsurance)
  }

  const toggleEditPolicy = (columnName: string, record: any, value: any) => {
    setValue(value)
    setColumnName(columnName)
    setSingleRecord(record)
    setEditPolicy(!editPolicy)
  }

  const toggleEditName = (columnName: string, record: any, value: any) => {
    setValue(value)
    setColumnName(columnName)
    setSingleRecord(record)
    setEditName(!editName)
  }

  const handleDateChange = (date: string) => {
    setValue(date)
  }

  const handleSubitDateChange = () => {
    handleSelectChange(columnName, singleRecord, value)
    setEditDate(!editDate)
  }

  const updateInsuranceName = (newValue: string) => {
    handleSelectChange(columnName, singleRecord, newValue)
    setEditInsurance(!editInsurance)
  }

  const submitPolciy = (newValue: string) => {
    handleSelectChange(columnName, singleRecord, newValue)
    setEditPolicy(!editPolicy)
  }

  const submitName = (newValue: string) => {
    handleSelectChange(columnName, singleRecord, newValue)
    setEditName(!editName)
  }

  const handleDeleteRecord = (intake_id: string) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
        handleDelete(intake_id);  // User clicked OK
    } else {
        console.log("Delete operation was canceled.");  // User clicked cancel or closed the dialog
    }
  }

  const handleDelete = (intake_id: string) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/${intake_id}`,
      headers: { }
    };

    axios.request(config)
    .then((response) => {
      getIntakeRecords()
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (record.expected_arrival_date) {
      const parsedDate = new Date(record.expected_arrival_date);
      setSelectedDate(parsedDate);
    } else {
      setSelectedDate(new Date());
    }
  }, [record.expected_arrival_date]);

  function convertDateToMMDDYYYY(dateInput: any): string {
    if (typeof dateInput === 'string') {
        // Check if the string is in a valid date format (YYYY-MM-DD)
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateInput)) {
            return dateInput.replace(/-/g, '/');
        }
        // If it's a string but not in the expected format, return it as is
        return dateInput;
    }
    if (dateInput instanceof Date) {
        // Format the date as MM/DD/YYYY without changing the time zone
        const month = (dateInput.getMonth() + 1).toString().padStart(2, '0');
        const day = dateInput.getDate().toString().padStart(2, '0');
        const year = dateInput.getFullYear();
        return `${month}/${day}/${year}`;
    }
    return 'N/A';
}
  

  function convertDobDateToMMDDYYYY(dateString: string) {
    const date = new Date(dateString);
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const dd = String(date.getUTCDate()).padStart(2, '0'); 
    const yyyy = date.getUTCFullYear(); 

    return `${mm}/${dd}/${yyyy}`;
  }

  function convertDateToCustomFormatDate(date: Date) {
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0');  // pad with zero if needed
    const dd = String(date.getUTCDate()).padStart(2, '0');  // pad with zero if needed
    const yyyy = date.getUTCFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateToCustomFormat(dateStr: string) {
    const date = new Date(dateStr);
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const dd = String(date.getUTCDate()).padStart(2, '0'); 
    const yyyy = date.getUTCFullYear(); 
    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateToCustomFormatDob(dateStr: string) {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      // Date parsing failed, handle invalid date string
      return "Invalid Date";
  }
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const dd = String(date.getUTCDate() - 1).padStart(2, '0'); 
    const yyyy = date.getUTCFullYear(); 
    return `${yyyy}-${mm}-${dd}`;
  }

  function formatDateToPST(dateString: string): string {
    const date = new Date(dateString);
    
    // Adjust for PST (UTC-8)
    const pstDate = new Date(date.getTime() + (8 * 60 * 60 * 1000));
    
    // Format as MM/DD/YY
    const month = String(pstDate.getMonth() + 1).padStart(2, '0');
    const day = String(pstDate.getDate()).padStart(2, '0');
    const year = String(pstDate.getFullYear()).slice(-2);
    
    return `${month}/${day}/${year}`;
  }

  const handleDateSelectedChange = (date: string, column: string) => {
    column === 'DOB'
      ? setDobDate(date)
      : setSelectedDate(date)
  }

  const formatDollarAmount = (str: string) => {
    const num = parseFloat(str);
    return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  function truncateString(input: string, maxLength: number = 35): string {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input;
  }

  const getCoordinatorName = (userId: string) => {
    const coordinator = allUsers?.find(user => user.userid === userId);
    return coordinator ? coordinator.name : '';
  };

  const submitUpdate = (data: any) => {
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/${record.intake_id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        getIntakeRecords()
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSelectChange = (columnName: string | undefined, record: any, value: any) => {
    columnName === 'DOB' || columnName === 'Policy' || columnName === 'Insurance'
      ? updateInsuranceInfo(columnName, record, value)
      : updateRecordInfo(columnName, record, value)
  }

  const updateRecordInfo = (columnName: string | undefined, record: any, value: any) => {
    console.log('record to be updated: ', record)
    const data = {
      "checked_in": columnName === 'Status' ? value : record.checked_in,
      "booked": columnName === 'Booked' ? value : record.booked,
      "coordinator": columnName === 'Coordinator' ? value : record.coordinator,
      "summary_out": record.summary_out,
      "reason": columnName === 'Reason' ? value : record.reason,
      'facility': columnName === 'Facility' ? value : record.facility,
      'name': columnName === 'Client' ? value: record.name,
      "expected_arrival_date": columnName === 'Arriving Date' 
                                ? value
                                : record.expected_arrival_date === ''
                                  ? convertDateToCustomFormatDate(new Date()) 
                                  : convertDateToCustomFormat(record.expected_arrival_date)
    }
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/update_non_insurance_info/${record.intake_id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    console.log('update config: ', config)
    axios.request(config)
      .then((response) => {
        getIntakeRecords()
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const updateInsuranceInfo = (columnName: string | undefined, record: any, value: any) => {
    const data = {
      "dob": columnName === 'DOB' ? convertDateToCustomFormatDob(value) : convertDateToCustomFormatDob(record.date_of_birth),
      "policy": columnName === 'Policy' ? value : record.policy_id,
      "insurance": columnName === 'Insurance' ? value : record.insurance,
    }
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/update_insurance_info/${record.intake_id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        getIntakeRecords()
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePrivilegeChange = (columnName: string | undefined, record: any, value: any) => {
    const data = {
      "department": columnName === 'Department' ? value : record.department,
      "privileges": columnName === 'Privileges' ? value : record.privileges,
      "active": record.active
    };
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/users/update/${record.userid}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response: any) => {
        grabAllProfiles();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const formattedBillingUsers = () => {
  //   let newUsers: any = []
  //   newUsers.push({
  //     active: false,
  //     company: 'PHG',
  //     department: 'billing',
  //     email: '-',
  //     first_name: '-',
  //     last_name: '-',
  //     name: 'Select Coodinator',
  //     privileges: 'staff',
  //     userid: '-',
  //   })
  //   billingUsers?.map((user) => {
  //     newUsers.push(user)
  //   })
  //   return newUsers
  // }

  const handleClick = (prefixId: string, network: string) => {
    handleUpdateCurrentSidebarTab('Shared', location.pathname)
    grabPrefixRecords(prefixId, network)
    navigate(`/historic/prefix/${prefixId}/${network}`);
  };

  const handleClickHistoric = (policy_id: string) => {
    handleUpdateCurrentSidebarTab('HistoricPolicy', location.pathname)
    grabUserRecords(policy_id)
    navigate(`/historic/user/${policy_id}`);
  }

  const handleClickHistoricWithTab = (policy_id: string) => {
    handleUpdateCurrentSidebarTab('Shared', location.pathname)
    grabUserRecords(policy_id)
    navigate(`/historic/user/${policy_id}`);
  }

  const redirectToVerify = (policy_id: string) => {
    grabFullAvailityData(
      record.insurance, 
      convertDateToCustomFormatDate(new Date()), 
      record.policy_id, 
      convertDateToCustomFormat(record.date_of_birth),
      record.name
    )
    navigate(`/verifytx/${policy_id}`)
  }

  const redirectToCollabForm = (claim_id: string) => {
    fetchAutomateCollab(claim_id);
    navigate('/follow-up/collabform')
  }

  const redirectToPatientDetails = (record: any) => {
    handleSelectedPatientRecord(record)
    navigate('/patient')
  }

  const extractDate = (fileName: string) => {
    const datePart = fileName.split('_')[0];
    const [year, month, day] = datePart.split('-');
    return `${month}-${day}-${year}`;
  };

  const extractTime = (fileName: string) => {
    const timePart = fileName.split('_')[1];
    let [hour, minute, second] = timePart.split('-').map(Number);
  
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert to 12-hour format, treating 0 as 12
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
  };
  
  const downloadFile = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    const fileName = url.split('/').pop()?.split('?')[0] || 'download';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {columns.map((column, columnIndex) => {
        const cellValue = column.recordName ? record[column.recordName] : '';
        const width = `min-w-${column.width}`
        const selectedToShow = selectedColumns.includes(column.label)
        const selectedToShowAvea = selectedColumnsAvea.includes(column.label)
        const selectedToShowCensus = selectedColumnsCensus.includes(column.label)
        const selectedToShowFollowup = selectedColumnsFollowup.includes(column.label)
        const selectedToShowAveaFollowup = selectedColumnsFollowupAvea.includes(column.label)
        const selectedToShowCensusFollowup = selectedColumnsFollowupCensus.includes(column.label)
        const selectedToShowIntake = selectedColumnsIntake.includes(column.label)
        return (
          table === 'Claims' && !selectedToShow
            ? null
            : table === 'Avea Claims' && !selectedToShowAvea
                ? null
                : table === 'Claims Census' && !selectedToShowCensus
                    ? null
                    : table === 'Followup Collab' && !selectedToShowFollowup
                        ? null
                        : table === 'Followup Avea' && !selectedToShowAveaFollowup
                            ? null
                            : table === 'Followup Census' && !selectedToShowCensusFollowup
                                ? null
                                : table === 'intake' && !selectedToShowIntake
                                    ? null
                                    : <td key={columnIndex}>
                                        {column.type === 'select' && column.options 
                                          ? (
                                              column.type === 'select' && column.dependent && column.dependentResults 
                                                ? (
                                                    column.dependentResults.includes(record[column.dependent]) 
                                                      ? (
                                                          column.admin && currentProfile.privileges != 'staff'
                                                            ? (
                                                                <div>
                                                                  <SelectOptionComponent
                                                                    options={column.options}
                                                                    value={cellValue}
                                                                    onChange={(newValue) => {
                                                                      handleSelectChange(column.label, record, newValue)
                                                                    }}
                                                                  />
                                                                </div>
                                                              )
                                                            : (
                                                                record[column.recordName] ? <p className={`${record[column.recordName] === 'Yellow Stripe' ? 'text-yellow-500' : 'text-yellow'}`}>{record[column.recordName]}</p> : '' //${record[column.recordName] === 'Yellow Stripe' ? 'text-yellow-500' : 'text-white'}
                                                              )
                                                        ) 
                                                      : (
                                                          null
                                                        )
                                                  ) 
                                                : currentSidebarTab === 'Accounts' ? (
                                                    <div>
                                                      <SelectOptionComponent
                                                        options={column.options}
                                                        value={cellValue}
                                                        onChange={(newValue) => {
                                                          handlePrivilegeChange(column.label,record, newValue)
                                                        }}
                                                      />
                                                    </div>
                                                  ) : currentSidebarTab === 'Claims' 
                                                      ? (
                                                        <div>
                                                          <SelectOptionComponent
                                                            options={column.options}
                                                            value={cellValue}
                                                            onChange={(newValue) => {
                                                              updateFollowupClaim(record.claim_id, record['userid'], newValue)
                                                            }}
                                                          />
                                                        </div>
                                                      ) : currentSidebarTab === 'Follow Up' 
                                                            ? (
                                                              <div>
                                                                <SelectOptionComponent
                                                                  options={column.options}
                                                                  value={cellValue}
                                                                  onChange={(newValue) => {
                                                                    updateFollowupClaim(record.claim_id, record['userid'], newValue)
                                                                  }}
                                                                />
                                                              </div>
                                                            ) : (
                                                              column.admin && currentProfile.privileges != 'staff'
                                                                ? (
                                                                  <div>
                                                                    <SelectOptionComponent
                                                                      options={column.options}
                                                                      value={cellValue}
                                                                      onChange={(newValue) => {
                                                                        handleSelectChange(column.label, record, newValue)
                                                                      }}
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  <div className={`w-40 ${record[column.recordName] === 'Approved' || record[column.recordName] === 'Approved BD' || record[column.recordName] === 'Approved LB No RTC' || record[column.recordName] === 'Approved RTC' ? 'text-green-500' : record[column.recordName] === 'Denied' ? 'text-red-500' : record[column.recordName] === 'Pending' ? 'text-primary-select dark:text-secondary-select' : 'text-black'}`}>
                                                                    {record[column.recordName] ? record[column.recordName] : ''}
                                                                  </div>
                                                                )
                                              )
                                            ) 
                                          : column.type === 'select-edit' ? (
                                          <>
                                            <div className={`flex flex-row justify-center`}>
                                              {record[column.recordName]}
                                              <Edit height={20} width={20} className='text-red-700 ml-2'/>
                                            </div>
                                          </>
                                        ) : column.type === 'insurance-edit' ? (
                                          <>
                                            <div className='flex flex-row justify-center'>
                                              {
                                                editInsurance
                                                  ? <>
                                                      <div>
                                                        <InsuranceSelectComponent value={record[column.recordName]} onChange={updateInsuranceName} insuranceOptions={insuranceOptions}/>
                                                      </div>
                                                    </>
                                                  : <>
                                                      <div className={` flex flex-row`}>
                                                        {record[column.recordName]}
                                                        <Edit onClick={() => {toggleEditInsurance(column.label, record, record[column.recordName])}} height={20} width={20} className='text-primary-select dark:text-secondary-select ml-2'/>
                                                      </div>
                                                    </>
                                              }
                                            </div>
                                          </>
                                        ) : column.type === 'policy-edit' ? (
                                          <>
                                            <div className={` flex flex-row justify-center`}>
                                              {
                                                editPolicy
                                                  ? <>
                                                      <div >
                                                        <EditPolicyComponent value={record[column.recordName]} onSubmit={submitPolciy} type='text' capitalize={true}/>
                                                      </div>
                                                    </>
                                                  : <>
                                                      <div className={` flex flex-row`}>
                                                        {record[column.recordName]}
                                                        <Edit onClick={() => {toggleEditPolicy(column.label, record, record[column.recordName])}} height={20} width={20} className='text-primary-select dark:text-secondary-select ml-2'/>
                                                      </div>
                                                    </>
                                              }
                                            </div>
                                          </>
                                        ) : column.type === 'name-edit' ? (
                                          <>
                                            <div className={` flex flex-row justify-center`}>
                                              {
                                                editName
                                                  ? <>
                                                      <div >
                                                        <EditNameComponent value={record[column.recordName]} onSubmit={submitName} type='text'/>
                                                      </div>
                                                    </>
                                                  : <>
                                                      <div className={` flex flex-row`}>
                                                        {record[column.recordName]}
                                                        <Edit onClick={() => {toggleEditName(column.label, record, record[column.recordName])}} height={20} width={20} className='text-sky-500 ml-2'/>
                                                      </div>
                                                    </>
                                              }
                                            </div>
                                          </>
                                        ) : column.type === 'boolean' ? (
                                          <div  className={`flex flex-row justify-center`}>
                                            <p>{record[column.recordName] === true ? 'Yes' : record[column.recordName] === null ? 'Unknown' : 'No'}</p>
                                          </div>
                                        ) : column.type === 'date' ? (
                                          <>
                                            <div>
                                              <p>{convertDateToMMDDYYYY(record[column.recordName]) || 'N/A'}</p>
                                            </div>
                                          </>
                                        ) : column.type === 'date-edit' ? (
                                          <>
                                            <div className={`flex flex-row justify-center`}>
                                              {
                                                editDate
                                                  ? <>
                                                      <div className='flex flex-row items-center'>
                                                      <DateChangeComponent selectedDate={dobDate} handleDateChange={(date: string) => {
                                                        handleDateSelectedChange(date, column.label)
                                                        handleSelectChange(column.label, record, date)
                                                      }}/>
                                                      </div>
                                                    </>
                                                  : <>
                                                      {convertDobDateToMMDDYYYY(record[column.recordName])}
                                                      <Edit onClick={() => {updateEditDate(column.label, record, record[column.recordName])}} height={20} width={20} className='text-primary-select dark:text-secondary-select ml-2'/>
                                                    </>
                                              }
                                            </div>
                                          </>
                                        ) : column.type === 'select_date' ? (
                                          column.type === 'select_date' && column.dependent && column.dependentResults ? (
                                            column.dependentResults.includes(record[column.dependent]) ? (
                                              <div>
                                                <DateSelectionComponent selectedDate={selectedDate} handleDateChange={(date: string) => {
                                                  handleDateSelectedChange(date, column.label)
                                                  handleSelectChange(column.label, record, date)
                                                }}/>
                                              </div>
                                            ) : (
                                              null
                                            )
                                          ) : ( null )
                                        ) : column.type === 'dollar' ? (
                                          <div>
                                            <p>
                                              {record[column.recordName] ? formatDollarAmount(record[column.recordName]) : ''}
                                            </p>
                                          </div>
                                        ) : column.type === 'delete' ? (
                                          currentSidebarTab === 'Follow Up'
                                            ? currentSidebarSubTab === 'Collab Md'
                                                ? <div>
                                                    {
                                                      currentProfile.privileges === 'admin' || currentProfile.privileges === 'manager' || currentProfile.privileges === 'dev' || currentProfile.privileges === 'owner'
                                                        ? <div>
                                                            <button onClick={() => {removeBatchToFavorites(record.claim_id)}} className={`py-1 px-3 rounded-lg bg-primary dark:bg-secondary`}>Remove</button>
                                                          </div>
                                                        : null
                                                    }
                                                  </div>
                                                : <div>
                                                    {
                                                      currentProfile.privileges === 'admin' || currentProfile.privileges === 'manager' || currentProfile.privileges === 'dev' || currentProfile.privileges === 'owner'
                                                        ? <div>
                                                            <button onClick={() => {handleDeleteRecord(record.intake_id)}} className={`py-1 px-3 rounded-lg bg-primary dark:bg-secondary`}>Remove</button>
                                                          </div>
                                                        : null
                                                    }
                                                  </div>
                                            : <>
                                                {
                                                  currentProfile.privileges === 'dev'
                                                    ? <div>
                                                        <button onClick={() => {handleDeleteRecord(record.intake_id)}} className={`py-1 px-3 rounded-lg bg-primary dark:bg-secondary`}>Remove</button>
                                                      </div>
                                                    : null
                                                }
                                              </>
                                        ) : column.type === 'percent' ? (
                                          <div>
                                            <p>{record[column.recordName] === 0 ? '0%' : `${(record[column.recordName] * 100).toFixed(1)}%`}</p>
                                          </div>
                                        ) : column.type === 'note' ? (
                                          <div className='max-w-96 py-2'>
                                            <p>
                                              {record[column.recordName] ? record[column.recordName] : ''}
                                            </p>
                                          </div>
                                        ) : column.type === 'people' ? (
                                          currentSidebarTab === 'Dashboard'
                                            ? (
                                                column.admin && currentProfile.privileges != 'staff'
                                                  ? (
                                                    <div>
                                                      <SelectPeopleComponent
                                                        options={intakeUsers}
                                                        value={cellValue}
                                                        onChange={(newValue) => {
                                                          handleSelectChange(column.label, record, newValue)
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                  : (
                                                    <div className='w-44'>
                                                      {allUsers?.find((user) => user.userid === record[column.recordName])?.name}
                                                    </div>
                                                  )
                                              )
                                            : currentSidebarTab === 'Follow Up'
                                                ? <div>
                                                    <SelectPeopleComponent
                                                      options={followupUsers}
                                                      value={cellValue}
                                                      onChange={(newValue) => {
                                                        updateCoordinatorFollwup(record['claim_id'], newValue)
                                                      }}
                                                    />
                                                  </div>
                                                : currentSidebarTab === 'Claims'
                                                    ? <div>
                                                        <SelectPeopleComponent
                                                          options={claimsUsers}
                                                          value={cellValue}
                                                          onChange={(newValue) => {
                                                            updateFollowupClaim(record['userid'], newValue, record['status'])
                                                          }}
                                                        />
                                                      </div>
                                                    : <div>
                                                        <SelectPeopleComponent
                                                          options={followupUsers}
                                                          value={cellValue}
                                                          onChange={(newValue) => {
                                                            updateCoordinatorFollwup(record['claim_id'], newValue)
                                                          }}
                                                        />
                                                      </div>
                                        ) : column.type === 'popup' ? (
                                          currentSidebarTab === 'Dashboard' ? (
                                            column.label === 'Phone'
                                              ? <div>
                                                  <div className='hover:cursor-pointer'>
                                                    <p className='text-primary-select' onClick={() => {toggleShowRecordsing(record)}}>{record[column.recordName]}</p>
                                                  </div>
                                                  {showRecords && (
                                                    <div className='fixed inset-0 top-1/4 left-1/3 h-1/2 w-1/3 z-40 flex justify-center items-center'>
                                                      <div className='bg-alt-on p-4 rounded-lg z-50 max-h-[80vh] overflow-auto min-w-[50vw]'>
                                                        {loadingRecordings ? (
                                                          <div className='flex justify-center items-center'>
                                                            <RefreshCw height={32} width={32} color='white' className='animate-spin' />
                                                          </div>
                                                        ) : (
                                                          <>
                                                            <div className='w-full flex flex-col items-start'>
                                                              <div className='flex flex-row justify-between w-full'>
                                                                <p className='text-xl font-bold'>{recordingsRecord?.name}</p>
                                                                <X onClick={() => {toggleShowRecordsing(record)}} height={30} width={30} color='red'/>
                                                              </div>
                                                              <p className='text-base'>
                                                              Coordinator: {allUsers?.find((user) => user.userid === recordingsRecord['coordinator'])?.name}
                                                              </p> 
                                                              <p className='mt-8 text-lg font-bold'>Recordings: </p>
                                                              {numberRecordings.length > 0 ? (
                                                                <div className='mt-4 px-3 w-full'>
                                                                  {numberRecordings.map((recording) => (
                                                                    <div className='w-full flex flex-row items-center justify-between hover-cursor mb-4' key={recording.file_name}>
                                                                      <p className='mb-2'>{extractDate(recording.file_name)} ({extractTime(recording.file_name)})</p>
                                                                      <p onClick={() => [/* Your download logic */]} className='bg-green-500 p-2 rounded-lg'>Download</p>
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                              ) : (
                                                                <p>No recordings found.</p>
                                                              )}
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              : !showNotes ? (
                                                  <div onClick={() => { getNotes(record.intake_id, record.coordinator); toggleShowPopup(); }}>
                                                    <button className='text-center px-2 py-1 bg-primary dark:bg-secondary rounded-md font-bold text-white min-w-28 ml-4'>Show Notes</button>
                                                  </div>
                                                ) : (
                                                  <div className='fixed inset-0 bg-black bg-opacity-80 z-40 flex justify-center items-center overflow-auto w-full min-w-full mt-18'>
                                                    <div className='bg-stone-300 dark:bg-slate-700 p-4 rounded-lg shadow-lg z-50 max-h-[75vh] overflow-auto min-w-[50vw]'>
                                                      <ButtonComponent label='Close' handler={() => { toggleShowPopup(); }} />
                                                    </div>
                                                  </div>
                                                )
                                          ) : currentSidebarTab === 'Follow Up' || currentSidebarTab === 'Claims' ? (
                                            !showNotes ? (
                                              <div onClick={() => { getClaimsNotes(record.claim_id); toggleShowPopup(); }}>
                                                <button className='text-center px-2 py-1 bg-primary dark:bg-secondary rounded-md font-bold text-white min-w-28 ml-4'>Show Notes</button>
                                              </div>
                                            ) : (
                                                <div className='fixed inset-0 bg-black bg-opacity-80 z-40 flex justify-center items-center overflow-auto w-full min-w-full mt-18'>
                                                  <div className='bg-stone-300 dark:bg-slate-700 p-4 rounded-lg shadow-lg z-50 max-h-[75vh] overflow-auto min-w-[50vw]'>
                                                  <ClaimNotesForm />
                                                  <ButtonComponent label='Close' handler={() => { toggleShowPopup(); }} />
                                                </div>
                                              </div>
                                            )
                                          ) : (
                                            <div>
                                              {/* Default content for column.type === 'popup' but not 'Intake' or 'Claims' */}
                                              <p>Default popup content</p>
                                            </div>
                                          )
                                        ) : column.type === 'merge_name' ? (
                                          <div className="" >
                                            <p className='text-white'> <SingleSelectClickComponent value={record['first_name'] + ' ' +  record['last_name']} onChange={grabCensusAvailityData} record={record}/> </p>
                                          </div>
                                          
                                        ) : column.type === 'checkbox' ? (
                                          currentSidebarTab === 'Claims'
                                            ? <>
                                                <input
                                                  type="checkbox"
                                                  checked={table === 'Claims' ? selectedClaims?.includes(record['claim_id']) : table === 'Avea Claims' ? selectedClaims?.includes(record['claim_id']) : table === 'Claims Census' ? selectedClaims?.includes(record['claim_id']) : false}
                                                  onChange={() => {
                                                    if(table === 'Claims'){
                                                      updateSelectedClaims(record['claim_id'])
                                                    }
                                                    if(table === 'Avea Claims'){
                                                      updateSelectedClaims(record['claim_id'])
                                                    }
                                                    if(table === 'Claims Census'){
                                                      updateSelectedClaims(record['claim_id'])
                                                    }
                                                  }}
                                                />
                                              </>
                                            : currentSidebarTab === 'Follow Up'
                                                ? <>
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        table === 'Followup Collab' 
                                                          ? selectedFollowup?.includes(record['claim_id']) 
                                                          : table === 'Followup Avea' 
                                                              ? selectedFollowup?.includes(record['claim_id']) 
                                                              : table === 'Followup Census' 
                                                                  ? selectedClaims?.includes(record['claim_id']) 
                                                                  : false}
                                                      onChange={() => {
                                                        if(table === 'Followup Collab'){
                                                          updateSelectedFollowup(record['claim_id'])
                                                        }
                                                        if(table === 'Followup Avea'){
                                                          updateSelectedFollowup(record['claim_id'])
                                                        }
                                                        if(table === 'Followup Census'){
                                                          updateSelectedFollowup(record['claim_id'])
                                                        }
                                                      }}
                                                    />
                                                  </>
                                                : <>
                                                <input
                                                  type="checkbox"
                                                  checked={table === 'Claims' ? selectedFollowup?.includes(record['claim_id']) : false}
                                                  onChange={() => {
                                                  }}
                                                />
                                              </>
                                    ) : column.type === 'text-edit' ? (
                                      <><div className={`flex flex-row justify-center`}>
                                        {record[column.recordName]}
                                        <Edit height={20} width={20} className='text-secondary ml-2'/>
                                      </div></>
                                    ) : column.type === 'clickable' ? (
                                      currentSidebarTab === 'External'
                                        ? <div className='hover:cursor-pointer'>
                                            <p className='text-primary-select dark:text-secondary-select' onClick={() => {handleClick(record[column.recordName], record['network'])}}>{record[column.recordName]}</p>
                                          </div>
                                        : currentSidebarTab === 'Shared'
                                            ? table === 'HistoricPrefix'
                                                ? <div className='hover:cursor-pointer'>
                                                    <p className='text-primary-select dark:text-secondary-select' onClick={() => {handleClickHistoricWithTab(record[column.recordName])}}>{record[column.recordName]}</p>
                                                  </div>
                                                : <div className='hover:cursor-pointer'>
                                                    <p className='text-primary-select dark:text-secondary-select' onClick={() => {handleClick(record[column.recordName], record['network'])}}>{record[column.recordName]}</p>
                                                  </div>
                                            : currentSidebarTab === 'HistoricPrefix'
                                                ? <div className='hover:cursor-pointer'>
                                                    <p className='text-primary-select dark:text-secondary-select' onClick={() => {handleClickHistoric(record[column.recordName])}}>{record[column.recordName]}</p>
                                                  </div>
                                                : currentSidebarTab === 'Dashboard'
                                                    ? column.label === 'Prefix'
                                                        ? column.admin && currentProfile.privileges != 'staff'
                                                          ? <div className='hover:cursor-pointer' onClick={() => {grabPrefixRecordsFromDashboard(record[column.recordName]);showPrefixPopup()}}>
                                                              <p className='text-primary-select dark:text-secondary-select'>{record[column.recordName]}</p>
                                                            </div>
                                                          : <div>{record[column.recordName] ? record[column.recordName] : ''}</div>
                                                        : column.label === 'Claim Id'
                                                            ? <SingleSelectClickComponent value={record[column.recordName]} onChange={grabAvailityData} record={record}/>
                                                            : column.label === 'Details'
                                                                ? <div className='hover:cursor-pointer text-primary-select dark:text-secondary-select' onClick={() => {redirectToPatientDetails(record)}}>{'Client Details'}</div>
                                                                : table === 'HistoricPrefix'
                                                                    ? <div className='hover:cursor-pointer'>
                                                                        <p className='text-primary-select dark:text-secondary-select' onClick={() => {handleClickHistoricWithTab(record[column.recordName])}}>{record[column.recordName]}</p>
                                                                      </div>
                                                                    : column.label === 'Verify TX'
                                                                        ? <div className='hover:cursor-pointer text-primary-select dark:text-secondary-select' onClick={() => {redirectToVerify(record['policy_id'])}}>{'Verify Tx'}</div>
                                                                        : <div>{record[column.recordName] ? 'Yes' : 'No'}</div>
                                                : table === 'Followup Collab' || table === 'Claims'
                                                    ? <SingleSelectClickComponent value={record[column.recordName]} onChange={grabAvailityData} record={record}/>
                                                : table === 'Followup Avea' || table === 'Avea Claims'
                                                    ? <SingleSelectClickComponent value={record[column.recordName]} onChange={grabAveaAvailityData} record={record}/>

                                                    : <SingleSelectClickComponent value={record[column.recordName]} onChange={grabCensusAvailityData} record={record}/>
                                                       
                                                      
                                    ) : column.type === 'text' ? (
                                      column.label === 'Active'
                                        ? <div>
                                            <p>
                                              {record[column.recordName] ? 'Yes' : 'No'}
                                            </p>
                                          </div>
                                        : column.label === 'In Collab'
                                            ? <>
                                                {
                                                  record[column.recordName] === 'pending'
                                                    ? <p className='text-yellow-500'>
                                                        To Be Submitted
                                                      </p>
                                                    :  record[column.recordName] === 'Failed'
                                                        ? <p className='text-red-500 hover:underline hover:cursor-pointer' onClick={() =>{redirectToCollabForm(record.claim_id); setCollabDisableEdit(false)}}>
                                                            {record[column.recordName] ? record[column.recordName] : ''}

                                                          </p>
                                                        : <p className='text-green-500 hover:underline hover:cursor-pointer' onClick={() =>{redirectToCollabForm(record.claim_id);setCollabDisableEdit(true)}}>
                                                            {record[column.recordName] ? record[column.recordName] : ''}
                                                          </p>
                                                    }
                                                  </>
                                            :  <p>
                                                {record[column.recordName] ? record[column.recordName] : ''}
                                              </p>
                                    ) : (
                                      <div>
                                        <p>
                                          {record[column.recordName] ? record[column.recordName] : ''}
                                        </p>
                                      </div>
                                    )}
                                  </td>
        );
      })}
    </>
  )
}

export default CellComponent