import React, { useEffect, useState } from 'react';
import LayoutComponent from '../BaseScreen';
import { useData } from '../../Context/DataContext';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import DisplayAccordianHeader from '../../Components/SortAndFilter/DisplayAccordianHeader';
import BillingDetailsFilterComponent from '../../Components/SortAndFilter/BillingDetailsFilterComponent';
import { useNavigate } from 'react-router-dom';
import CensusUserFilterComponent from '../../Components/SortAndFilter/CensusUserFilterComponent';
import FormInputComponent from '../../Components/Inputs/FormInputComponent';
import DateSelectionComponent from '../../Components/Inputs/DateSelectionComponent';
import SelectComponent from '../../Components/Inputs/SelectComponent';
import ClaimDateSelect from '../../Components/Inputs/ClaimDateSelect';
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import { useIntake } from '../../Context/IntakeContext';
import { useHistoric } from '../../Context/HistoricContext';
import CensusTableComponent from '../../Components/Tables/CensusTableComponent';
import { CensusUserTableOptions } from '../../Options/CensusUserTableOptions';
import { DateUtilityFunctions } from '../../Utils/DateUtilityFunctions';
import { useAuth } from '../../Context/AuthContext';
import { useClaims } from '../../Context/ClaimsContext';

const CensusUserScreen = () => {
  const navigate = useNavigate();
  const { patientDetails, getSinglePatientCensus } = useData();
  const { allUsers } = useAuth()
  const { submittingCensusToCollabeLoading, submitCensusToCollab, updateNewClaim } = useClaims()
  const { grabFullAvailityData } = useIntake();
  const { grabUserFinancialRecords, grabUserIntellaClaimRecord, censusUserDetails } = useHistoric();

  const [diagnosis, setDiagnosis] = useState<any>([]);
  const [editableDiagnosis, setEditableDiagnosis] = useState<string[]>(patientDetails.diagnosis || []);
  const [start_date, setStartDate] = useState<Date>(getNextDate(new Date(patientDetails.start_date)));
  const [endDate, setEndDate] = useState<Date>(getNextDate(new Date(patientDetails.end_date)));
  const [AuthorizationNumber, setAuthorizationNumber] = useState<string>(patientDetails.authorization_number);
  const [gender, setGender] = useState<string>(patientDetails.gender);
  const [billType, setBillType] = useState<string>(patientDetails.bill_type);
  const [revCode, setRevCode] = useState<string>(patientDetails.rev_code);
  const [cptCode, setCptCode] = useState<string>(patientDetails.cpt_code);
  const [updatingClaim, setUpdatingClaim] = useState<boolean>(false);
  const [localPatientDetails, setLocalPatientDetails] = useState<any>(patientDetails);
  const [localCensusRecords, setLocalCensusRecords] = useState<any[]>([]);
  const [selectedUuid, setSelectedUuid] = useState<string>("Select uuid");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleUpdateStartDate = (date: Date) => setStartDate(date);
  const handleUpdateEndDate = (date: Date) => setEndDate(date);
  const handleUpdateAuth = (e: any) => setAuthorizationNumber(e.target.value);
  const handleUpdateGender = (e: any) => setGender(e.target.value);
  const handleUpdateBillType = (e: any) => setBillType(e.target.value);
  const handleUpdateRevCode = (e: any) => setRevCode(e.target.value);
  const handleUpdateCptCode = (e: any) => setCptCode(e.target.value);

  const handleOptionClick  = (value: string) => {
    setSelectedUuid(value)
    setIsOpen(false)
  }

  const handleOpenSelect = () => {
    setIsOpen(true);
  }


  const categories = localCensusRecords.map((record: any) => {
    let shortenedId = record.uuid.substring(record.uuid.length - 8)
    return {value: record.uuid, label: shortenedId}
  })

  categories.unshift({value:'Select uuid', label: 'uuid'})

  const displayRecords = localCensusRecords.map((record: any) => {
    return {
      ...record,
      uuid: record.uuid.substring(record.uuid.length - 8)
    }
  })


  const formatDiagnosisForSubmission = (): string => {
    const formattedDiagnosis = editableDiagnosis.map(code => `"${code}"`).join(',');
    return `{${formattedDiagnosis}}`;
  }

  const handleSubmitUpdatedClaim = async () => {
    setUpdatingClaim(true);
    const finalDiagnosisCodes = formatDiagnosisForSubmission();
    if (AuthorizationNumber && billType && gender && revCode && cptCode) {
        try {
          await updateNewClaim(
            patientDetails.intella_claim_id, 
            AuthorizationNumber, 
            DateUtilityFunctions.pstToUtc(start_date),      // Start Date and End date converted to UTC from PST before submission.
            DateUtilityFunctions.pstToUtc(endDate), 
            billType, 
            finalDiagnosisCodes, 
            gender, 
            revCode,
            cptCode
          );
          await grabUserIntellaClaimRecord(patientDetails.intella_claim_id)
          await getSinglePatientCensus(patientDetails.intella_claim_id, start_date, endDate);
        }  catch (error) {
            console.error('Error updating claim:', error);
            } finally {
            setUpdatingClaim(false);
            }
    }
    else {
      alert("Please ensure all form fields are filled out.")
      setUpdatingClaim(false);
    }
  }

  useEffect(() => {
    grabUserFinancialRecords(patientDetails.policy_no);
    if(patientDetails && patientDetails.diagnosis_codes){
      reformatString(patientDetails.diagnosis_codes);
    }
  }, [patientDetails]);

  useEffect(() => {
    setLocalPatientDetails(patientDetails);
  }, [patientDetails]);
  
  const handleCreateNewClaim = async () => {
    await submitCensusToCollab(selectedUuid);
    setTimeout(() => {
      grabUserIntellaClaimRecord(patientDetails.intella_claim_id);
    }, 4000);
  }

  useEffect(() => {
    grabUserIntellaClaimRecord(patientDetails.intella_claim_id);
  }, [patientDetails]);

  useEffect(() => {
    setLocalCensusRecords(censusUserDetails);
  }, [censusUserDetails, patientDetails]);

  function reformatString(input: string) {
    const decodedString = input.replace(/&#44;/g, ',');
    const valuesArray = decodedString.split(/(?=F\d{2}\.\d+)/);
    const filteredValuesArray = valuesArray.filter(value => value.trim() !== '');
    const formattedArray = filteredValuesArray.map(value => {
      const [code, ...descriptionParts] = value.trim().split(' ');
      const description = descriptionParts.join(' ');
      return { code, description };
    });
    setDiagnosis(formattedArray);
  }

  function formatDate(date: string) {
    const nextDate = new Date(date);
    const pstOffset = 8 * 60;
    const utcOffset = nextDate.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }

  function formatDateToPST(dateString: string): string {
    const date = new Date(dateString);
    const pstDate = new Date(date.getTime() + (8 * 60 * 60 * 1000));
    const month = String(pstDate.getMonth() + 1).padStart(2, '0');
    const day = String(pstDate.getDate()).padStart(2, '0');
    const year = String(pstDate.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  }

  function getNextDate(date: Date): Date {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  }

  function convertDateToCustomFormatDate(date: Date) {
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0');
    const dd = String(date.getUTCDate()).padStart(2, '0');
    const yyyy = date.getUTCFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateToCustomFormat(dateStr: string) {
    const date = new Date(dateStr);
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0');
    const dd = String(date.getUTCDate()).padStart(2, '0');
    const yyyy = date.getUTCFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  const redirectToVerify = (policy_id: string) => {
    grabFullAvailityData(
      patientDetails.insurance_name, 
      convertDateToCustomFormatDate(new Date()), 
      policy_id, 
      convertDateToCustomFormat(patientDetails.date_of_birth),
      `${patientDetails.first_name} ${patientDetails.last_name}`
    );
    navigate(`/verifytx/census/${policy_id}`);
  }

  return (
    <LayoutComponent
      header={<CensusUserFilterComponent />}
      content={
        <div className='w-full h-full overflow-hidden flex flex-col text-black dark:text-white'>
          <div className='w-full overflow-auto flex flex-row'>
            <div className='w-2/3 px-2 overflow-auto' style={{ height: 'calc(100vh - 10rem)' }}>
              <div className='px-4 w-full flex flex-col'>
                <div className='flex flex-row justify-between'>
                  <p className='text-4xl font-bold pb-4'>{patientDetails.first_name} {patientDetails.last_name}</p>
                  <ButtonComponent label='Verify Tx' handler={() => { redirectToVerify(patientDetails.policy_no) }} />
                </div>
                <div className='w-full flex flex-row'>
                  <p className='text-xl '>{patientDetails.insurance_name}</p>
                  <p className='text-base mx-2'>|</p>
                  <p className='text-xl '>{patientDetails.policy_no}</p>
                  <p className='text-base mx-2'>|</p>
                  <p className='text-xl '>{formatDate(patientDetails.date_of_birth)}</p>
                </div>
                <p className='text-xl'>Days Remaining: {patientDetails.days_left} days</p>
              </div>
              <div >
                <div className='mt-2'>
                  <DisplayAccordianHeader label='Current Visit' display={false} onChange={() => { }} />
                </div>
                <div className='text-sm mt-4 px-4'>
                  <p className='text-base mt-2'>Level Of Care: ({patientDetails.level_of_care}) - {localPatientDetails.cpt_code}</p>
                  <p className='text-base mt-2'>Current Diagnosis:</p>
                  {
                    localPatientDetails.diagnosis && localPatientDetails.diagnosis.length > 0
                      ? localPatientDetails.diagnosis.map((singleCode: any) => (
                        <div key={singleCode}>
                          <p className='ml-4 text-sm mt-2'>{singleCode}</p>
                        </div>
                      ))
                      : null
                  }
                </div>
              </div>
              <div className="w-full mt-2">
                <div className="h-full overflow-auto rounded-lg">
                  <CensusTableComponent key={JSON.stringify(localCensusRecords)} columns={CensusUserTableOptions} records={displayRecords} users={allUsers} table='User Claims' />
                </div>
              </div>
            </div>
            <div className='overflow-auto w-1/3' style={{ height: 'calc(100vh - 10rem)' }}>
              <div className=''>
                <DisplayAccordianHeader label='Submit New Claim' display={false} onChange={() => { }} />
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>Full Name:</p>
                  <p className='text-xl'>{patientDetails.first_name} {patientDetails.last_name}</p>
                </div>
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>DOB:</p>
                  <p className='text-xl'>{formatDate(patientDetails.date_of_birth)}</p>
                </div>
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>Gender:</p>
                  <p className='text-xl'>{patientDetails.gender}</p>
                </div>
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>Insurance:</p>
                  <p className='text-xl'>{patientDetails.insurance_name}</p>
                </div>
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>Insurance Policy:</p>
                  <p className='text-xl'>{patientDetails.policy_no}</p>
                </div>
                <div className='w-full mt-2 px-4 flex flex-row justify-between'>
                  <p className='text-xl'>CPT Code:</p>
                  <p className='text-xl'>{patientDetails.cpt_code}</p>
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Start Date:</p>
                  <ClaimDateSelect selectedDate={new Date(start_date)} handleDateChange={handleUpdateStartDate} />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>End Date:</p>
                  <ClaimDateSelect selectedDate={new Date(endDate)} handleDateChange={handleUpdateEndDate} />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Authorization Number:</p>
                  <input
                    className='w-1/2 text-lg border-black rounded-md p-1 bg-primary dark:bg-secondary placeholder-gray-600 dark:placeholder-gray-400'
                    type={'text'}
                    placeholder={'Authorization Number...'}
                    value={AuthorizationNumber}
                    onChange={(e) => { handleUpdateAuth(e) }}
                  />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Gender:</p>
                  <input
                    className='w-1/2 text-lg border-black rounded-md p-1 bg-primary dark:bg-secondary placeholder-gray-600 dark:placeholder-gray-400'
                    type={'text'}
                    placeholder={'Gender...'}
                    value={gender}
                    onChange={(e) => { handleUpdateGender(e) }}
                  />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Revenue Code:</p>
                  <input
                    className='w-1/2 text-lg border-black rounded-md p-1 bg-primary dark:bg-secondary placeholder-gray-600 dark:placeholder-gray-400'
                    type={'text'}
                    placeholder={'Revenue Code...'}
                    value={revCode}
                    onChange={(e) => { handleUpdateRevCode(e) }}
                  />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Cpt Code:</p>
                  <input
                    className='w-1/2 text-lg border-black rounded-md p-1 bg-primary dark:bg-secondary placeholder-gray-600 dark:placeholder-gray-400'
                    type={'text'}
                    placeholder={'Cpt Code...'}
                    value={cptCode}
                    onChange={(e) => { handleUpdateCptCode(e) }}
                  />
                </div>
                <div className='w-full mt-3 px-4 flex flex-row justify-between items-center'>
                  <p className='text-xl'>Bill Type:</p>
                  <input
                    className='w-1/2 text-lg border-black rounded-md p-1 bg-primary dark:bg-secondary placeholder-gray-600 dark:placeholder-gray-400'
                    type={'text'}
                    placeholder={'Bill Type...'}
                    value={billType}
                    onChange={(e) => { handleUpdateBillType(e) }}
                  />
                </div>
                {/* <div className='w-full mt-3 px-4 flex flex-col justify-between items-center'>
                  <div className='background-color-secondary'>
                    <p className='mb-3 text-xl text-white'>Diagnosis Codes:</p>
                  </div>
                  {editableDiagnosis.map((single: string, index: number) => (
                    <div key={index} className="flex justify-center items-center w-full mb-2">
                      <input
                        type="text"
                        value={single}
                        onChange={(e) => handleDiagnosisChange(index, e.target.value)}
                        className="text-xl p-1 text-left mt-1 bg-primary border-b rounded-md w-full max-w-full border-gray-400 focus:outline-none focus:border-blue-500"
                      />
                    </div>
                  ))}
                </div> */}
                <div className='flex flex-row justify-center mt-12'>
                  <div className='flex overflow-auto'>
                    <SelectComponent placeholder='Select uuid' selectedValue={selectedUuid} options={categories} handleOptionClick={handleOptionClick} handleOpenSelect={handleOpenSelect} isOpen={isOpen}/>
                  </div>
                  {
                    updatingClaim
                      ? <ButtonComponent label='Updating Info' handler={() => { }} />
                      : <ButtonComponent label='Update Info' handler={handleSubmitUpdatedClaim} />
                  }
                </div>
                <div className='flex flex-row justify-center mt-8'>
                  {
                    selectedUuid !== 'Select uuid' ? 
                    (submittingCensusToCollabeLoading
                    ? <ButtonComponent label='Submitting...' handler={() => { }} />
                    : <ButtonComponent label='Create New Claim' handler={handleCreateNewClaim} />)
                    : <p>Please Select a Uuid</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default CensusUserScreen;
