import React from 'react'
import LayoutComponent from '../BaseScreen'
import ClaimsFilterComponent from '../../Components/SortAndFilter/ClaimsFilterComponent'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { ClaimOptions } from '../../Options/ClaimOptions'
import { FollowupOptions } from '../../Options/FollowupOptions'
import FollowupFilterComponent from '../../Components/SortAndFilter/FollowupFilterComponent'
import { useFollowup } from '../../Context/FollowupContext'
import { CensusFollowupOptions } from '../../Options/CensusFollowupOptions'
import ClaimsCensusFilter from '../../Components/SortAndFilter/ClaimsCensusFilter'
import { useAuth } from '../../Context/AuthContext'
import { useClaims } from '../../Context/ClaimsContext'

const FollowupCensusScreen = () => {

  const {claimsCensusRecords, showDeniedClaims, allDeniedClaims} = useClaims()
  const {followupTab} = useFollowup()
  const {allUsers} = useAuth()


  return (
    <LayoutComponent 
      header={
        <div className='min-h-20 w-full mb-2'>
          <ClaimsCensusFilter/>
        </div>
      } 
      content={
        <div className="w-full px-4 mt-12" style={{ height: 'calc(100vh - 12rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent table={'Claims Census'} users={allUsers} columns={CensusFollowupOptions} records={claimsCensusRecords}/>
            {
              showDeniedClaims
                ? <div style={{ height: 'calc(100vh - 12rem)' }} className='w-1/3 absolute z-55 bottom-0 right-0 bg-slate-100 dark:bg-slate-800 overflow-y-scroll'>
                    {
                      allDeniedClaims && allDeniedClaims.length > 0
                        ? (
                            allDeniedClaims.map((record: any, index: number) => {
                              return(
                                <div className={`w-full flex flex-col p-3 border-b-2 border-b-slate-200 ${index % 2 === 0 ? 'bg-primary-alternate dark:bg-stone-800' : 'bg-slate-100 dark:bg-stone-900' }`}>
                                  <div className='flex flex-row justify-between'>
                                    <div>
                                      <p>{record.first_name} {record.last_name}</p>
                                    </div>
                                    <div>
                                      <p>Claim: {record.claim_id}</p>
                                    </div>
                                  </div>
                                  <div className='flex flex-row justify-between'>
                                    <div>
                                      <p>{record.insurance}</p>
                                    </div>
                                    <div>
                                      <p>{record.policy_id}</p>
                                    </div>
                                  </div>
                                  <div className='flex flex-row justify-between'>
                                    <div>
                                      <p>Claim Amount:  ${record.claim_amount}</p>
                                    </div>
                                    <div>
                                      <p>Claim Paid: ${record.payout_amount | 0}</p>
                                    </div>
                                  </div>
                                  <div className='flex flex-row justify-between'>
                                    <div>
                                      <p>{record.status}</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          )
                        : (
                            <div>No Records Found</div>
                          )
                    }

                  </div>
                : null
            }
          </div>
        </div>
      }
    />
  )
}

//236672616

export default FollowupCensusScreen
