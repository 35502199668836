import React from "react";
import { useFollowup } from "../../Context/FollowupContext";
import { useData } from "../../Context/DataContext";
import FollowupAveaScreen from '../../Screens/FollowUp/FollowupAveaScreen';
import { useClaims } from "../../Context/ClaimsContext";

const ReminderPopup = () => {
  const { remindersData } = useFollowup();
  const { followupRecords } = useFollowup()
  const { followupAveaRecords } = useClaims()

  return (
    <div className='absolute top-full right-0 mt-2 w-64 max-h-72 overflow-y-auto bg-purple-300 shadow-lg rounded-lg p-4 z-50'>
      {remindersData && remindersData.length > 0 
      ? (remindersData.map((reminder, index) => {
        const foundRecord = reminder.claim_id.startsWith('AV') 
          ? followupAveaRecords?.find(record => record.claim_id === reminder.claim_id)
          : followupRecords?.find(record => record.claim_id === reminder.claim_id);
        
        const displayName = foundRecord ? foundRecord.name : 'Unknown';

        return (
            <div key={index} className='reminder'>
              <p className='text-black'>
                {displayName}
              </p>
              <p className="text-secondary">
                {reminder.claim_id}
              </p>
            </div>
        );
      })
      ) : (
        <p>Up to date on followups!</p>
      )}
    </div>
  );
}

export default ReminderPopup;
