import { CheckCircle, RefreshCcw } from 'react-feather';
import LayoutComponent from '../BaseScreen';
import { useParams } from 'react-router-dom';
import AvailityFilterComponent from '../../Components/SortAndFilter/AvailityFilterComponent';
import { useEffect, useState } from 'react';
import { useIntake } from '../../Context/IntakeContext';
import NotesForm from '../../Components/Forms/NotesForm';
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import SelectOptionComponent from '../../Components/Tables/SelectOptionComponent';
import baseUrl from '../../Utils/baseUrl';
import axios from 'axios';
import DateSelectionComponent from '../../Components/Inputs/DateSelectionComponent';
import { useData } from '../../Context/DataContext';
import { useAuth } from '../../Context/AuthContext';
import { IntakeNote } from '../../Components/Forms/NotesForm';

const PatientInfo = () => {
  const { policy } = useParams();
  const { availityData, verifyLoading, selectedPatientRecord } = useIntake();
  const { getIntakeRecords } = useIntake()
  const { currentProfile } = useAuth()
  const { getNotes, currentTimeZone } = useData()

  const statusOptions = [
    'Pending',
    'Approved',
    'Approved BD',
    'Approved RTC',
    'Approved LB No RTC',
    'Private Pay',
    'Denied'
  ]

  const bookedOptions = [
    'Pending',
    'Arrived',
    'MIA',
    'Booked',
    'Booked BD',
    'Yellow Stripe',
    'Clinically Denied'
  ]

  const reasonOptions = [
    'Pending',
    'Lost Contact',
    'Unwilling To Travel',
    'Booked Out',
    'Other - See Notes'
  ]

  const facilityOptions = [
    'Not Set',
    'Affinity',
    'Beachside',
    'Axis',
  ]

  // console.log(selectedPatientRecord)


  const [statusValue, setStatusValue] = useState<string>(selectedPatientRecord.checked_in)
  const [bookedValue, setBookedValue] = useState<string>(selectedPatientRecord.booked)
  const [reasonValue, setReasonValue] = useState<string>(selectedPatientRecord.reason)
  const [facilityValue, setFacilityValue] = useState<string>(selectedPatientRecord.facility)
  const [selectedDate, setSelectedDate] = useState(selectedPatientRecord.expected_arrival_date ? selectedPatientRecord.expected_arrival_date : new Date())
  const [intakeNotes, setIntakeNotes] = useState<IntakeNote[]>([])

  useEffect (() => {
    getNotes(selectedPatientRecord.intake_id, currentTimeZone)
  }, [selectedPatientRecord])

  const handleStatusValue = (columnName: string | undefined, record: any, text: string) => {
    setStatusValue(text)
    updateRecordInfo(columnName, record, text)
  }

  const handleBookedValue = (columnName: string | undefined, record: any, text: string) => {
    setBookedValue(text)
    updateRecordInfo(columnName, record, text)
  }

  const handleReasonValue = (columnName: string | undefined, record: any, text: string) => {
    setReasonValue(text)
    updateRecordInfo(columnName, record, text)
  }

  const handleFacilityValue = (columnName: string | undefined, record: any, text: string) => {
    setFacilityValue(text)
    updateRecordInfo(columnName, record, text)
  }

  const handleDateSelectedChange = (date: string, column: string) => {
    setSelectedDate(date)
    updateRecordInfo(column, selectedPatientRecord, date)
  }

  function convertDateToCustomFormatDate(date: Date) {
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0');  // pad with zero if needed
    const dd = String(date.getUTCDate()).padStart(2, '0');  // pad with zero if needed
    const yyyy = date.getUTCFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  function convertDateToCustomFormat(dateStr: string) {
    const date = new Date(dateStr);
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const dd = String(date.getUTCDate()).padStart(2, '0'); 
    const yyyy = date.getUTCFullYear(); 
    return `${yyyy}-${mm}-${dd}`;
  }

  const updateRecordInfo = (columnName: string | undefined, record: any, value: any) => {
    console.log('column name: ', columnName)
    console.log('record: ', record)
    console.log('value: ', value)
    const data = {
      "checked_in": columnName === 'Status' ? value : record.checked_in,
      "booked": columnName === 'Booked' ? value : record.booked,
      "coordinator": columnName === 'Coordinator' ? value : record.coordinator,
      "summary_out": record.summary_out,
      "reason": columnName === 'Reason' ? value : record.reason,
      'facility': columnName === 'Facility' ? value : record.facility,
      'name': columnName === 'Client' ? value: record.name,
      "expected_arrival_date": columnName === 'Arriving Date' 
                                ? value
                                : record.expected_arrival_date === '' 
                                  ? convertDateToCustomFormatDate(new Date()) 
                                  : convertDateToCustomFormat(record.expected_arrival_date)
    }
    console.log('request data: ', data)
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/update_non_insurance_info/${record.intake_id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        console.log('updated intake record complete')
        getIntakeRecords()
      })
      .catch((error) => {
        console.log(error);
      });
  }


  return (
    <LayoutComponent
      header={<AvailityFilterComponent />}
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2 text-black dark:text-white">
            <p className=' text-2xl font-bold'>
              {selectedPatientRecord.name}
            </p>
            <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
              <p className=' font-bold text-2xl text-white dark:text-white'>Client Details</p>
            </div>
            <div className='w-full flex flex-row'>
              <div className='flex flex-col w-1/2 pt-2 px-3'>
                <p className=' text-base'>
                  DOB: {selectedPatientRecord.date_of_birth}
                </p>
                <p className=' text-base mt-2'>
                  Insurance: {selectedPatientRecord.insurance}
                </p>
                <p className=' text-base mt-2'>
                  Policy: {selectedPatientRecord.policy_id}
                </p>
              </div>
              <div className='flex flex-col w-1/2 pt-2 px-3'>
                <p className=' text-base mt-2'>
                  Phone Number: {selectedPatientRecord.phone_number}
                </p>
                <p className=' text-base'>
                  Emergency Contact Name: {selectedPatientRecord.emergency_contact || 'Not Fuund'}
                </p>
                <p className=' text-base'>
                  Emergency Contact Phone: {selectedPatientRecord.emergency_number || 'Not Found'}
                </p>
                
              </div>
            </div>
            <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
              <p className=' font-bold text-2xl text-white dark:text-white'>Patient VOB</p>
            </div>
            <div className='w-full flex flex-row'>
              <div className='flex flex-col w-1/2 pt-2 px-3'>
                {
                  currentProfile.privileges != 'staff'
                    ? <>
                        <div className='flex flex-row items-center'>
                          <p className=' text-base mr-3'>
                            Status:
                          </p>
                          <div>
                            <SelectOptionComponent
                              options={statusOptions}
                              value={statusValue}
                              onChange={(newValue) => {
                                handleStatusValue('Status', selectedPatientRecord, newValue)
                              }}
                            />
                          </div>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Booked:
                          </p>
                          <div>
                            <SelectOptionComponent
                              options={bookedOptions}
                              value={bookedValue}
                              onChange={(newValue) => {
                                handleBookedValue('Booked', selectedPatientRecord, newValue)
                              }}
                            />
                          </div>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Reason:
                          </p>
                          <div>
                            <SelectOptionComponent
                              options={reasonOptions}
                              value={reasonValue}
                              onChange={(newValue) => {
                                handleReasonValue('Reason', selectedPatientRecord, newValue)
                              }}
                            />
                          </div>
                        </div>
                      </>
                    : <>
                        <div className='flex flex-row items-center'>
                          <p className=' text-base mr-3'>
                            Status: {selectedPatientRecord.checked_in}
                          </p>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Booked: {selectedPatientRecord.booked}
                          </p>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Reason: {selectedPatientRecord.reason}
                          </p>
                        </div>
                      </>
                }
              </div>
              <div className='flex flex-col w-1/2 pt-2 px-3'>
                {
                  currentProfile.privileges != 'staff'
                    ? <>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Arrival Date:
                          </p>
                          <div>
                            <DateSelectionComponent selectedDate={selectedDate} handleDateChange={(date: string) => {
                              handleDateSelectedChange(date, 'Arriving Date')
                            }}/>
                          </div>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Facility:
                          </p>
                          <div>
                            <SelectOptionComponent
                              options={facilityOptions}
                              value={facilityValue}
                              onChange={(newValue) => {
                                handleFacilityValue('Facility', selectedPatientRecord, bookedValue)
                              }}
                            />
                          </div>
                        </div>
                      </>
                    : <>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Arrival Date: {selectedPatientRecord.expected_arrival_date}
                          </p>
                        </div>
                        <div className='flex flex-row items-center mt-3'>
                          <p className=' text-base mr-3'>
                            Facility: {selectedPatientRecord.facility}
                          </p>
                        </div>
                      </>
                }
              </div>
            </div>
            <div className='w-full h-10 bg-primary founded-md flex flex-row items-center justify-between p-4 mt-5'>
              <p className=' font-bold text-2xl text-white dark:text-white'>Notes</p>
            </div>
            <div className='w-full flex flex-row justify-center'>
              <div className='w-full text-center mt-4'>
                <NotesForm intakeNotes={intakeNotes}/>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PatientInfo;
