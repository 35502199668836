export const DateUtilityFunctions = {

  //ReformatsDate to YYYY-MM-DD format

  'ReformatDate':   function formatDate(date: Date) {
        const nextDate = new Date(date);
        const pstOffset = 8 * 60;
        const utcOffset = nextDate.getTimezoneOffset();
        nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
        nextDate.setDate(nextDate.getDate() + 1);
        const year = nextDate.getFullYear();
        const month = String(nextDate.getMonth() + 1).padStart(2, '0');
        const day = String(nextDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
  },

  'getTimeZone': function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },

  'getInitialTimeZone':  function getInitialTimeZone() {
    const savedTimeZone = localStorage.getItem('userTimeZone');
    return savedTimeZone || DateUtilityFunctions.getTimeZone()
  },

  //Convert PST to UTC format

  'pstToUtc': function pstToUtc(date: Date) {
    const pstDate = new Date(date);

    const utcTime = pstDate.getTime() + (pstDate.getTimezoneOffset() * 60000);
  
    const utcDate = new Date(utcTime);
  
    const pstOffset = 8 * 60 * 60000; 
    utcDate.setTime(utcDate.getTime() + pstOffset);

  
    return utcDate;
  },

  //Get current Date as string

  'getCurrentDateString':   function getCurrentDateString(): string {
    const date = new Date();
  
    
    const year: number = date.getFullYear();
    let month: string = (date.getMonth() + 1).toString();
    let day: string = date.getDate().toString();
  

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;
  

    return `${year}-${month}-${day}`;
  },
  
  //Calculate Age

  'calculateAge': function calculateAge(birthDate: string): number {    
    // Parse the birth date string
    const [year, month, day] = birthDate.split('-').map(Number);
    const birth = new Date(year, month - 1, day); // month is 0-indexed in JS Date
  
    // Get current date in PST
    const now = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
    const today = new Date(now);
  
    // Calculate the difference in years
    let age = today.getFullYear() - birth.getFullYear();
  
    // Adjust age if birthday hasn't occurred this year
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
  
    return age;
  },

  'removeGMT': function removeGMT(inputDate: string) {
      const convertedString = inputDate.substring(0, inputDate.length-3)
      return convertedString
  },
}