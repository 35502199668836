import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import baseUrl from '../Utils/baseUrl';
import { useIntake } from './IntakeContext';
import { useClaims } from './ClaimsContext';
import { useNavigate } from 'react-router-dom';
import { useFollowup } from './FollowupContext';
import ClaimsAveaScreen from "../Screens/Claims/ClaimsAveaScreen";
import { addDays, subDays, format } from 'date-fns'; // Import date-fns for date manipulation
import { DateUtilityFunctions } from '../Utils/DateUtilityFunctions';
import { AvailityBillingDataProps } from '../Utils/BillingAnalyticsFunctions';
import { useAuth } from './AuthContext';
import { useHistoric } from './HistoricContext';
import { time } from 'console';


interface ProfileProps {
  active: boolean;
  company: string;
  department: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  privileges: string;
  userid: string;
}

interface BillingAnalyticsProps {
  claim_id: string,
  total_paid: string,
  total_charged: string,
  start_date: string,
  Axis: string,
  Affinity: string,
  Beachside: string
}

interface IntakeProps {
  active: boolean;
  booked: string;
  checked_in: string;
  coordinator: string;
  created_date: string;
  date: string;
  date_of_birth: string;
  in_network_oop: number;
  inn_deductable: number;
  insurance: string;
  name: string;
  onn_deducatible: number;
  out_network_oop: number;
  payer_id: string;
  policy_id: string;
  prefix: string;
  source: string;
  summary_out: string;
}

interface HistoricProps {
  average_charged: number;
  average_paid: number;
  balance: number;
  insurance: string;
  network: string;
  payout_ratio: number;
  prefix: string
}

interface ClaimsProps {
  balance_total: number,
  charged_total: number,
  claim_id: string,
  claim_status: string,
  coordinator: string,
  end_date: string,
  facility: string,
  favorites: number,
  fu_note: string | null,
  name: string,
  network: string,
  paid_total: number,
  payout_ratio: boolean,
  start_date: string,
  status: string
}

interface FolloupProps {
  balance_total: number,
  charged_total: number,
  claim_id: string,
  claim_status: string,
  coordinator: string,
  end_date: string,
  facility: string,
  favorites: number,
  fu_note: string | null,
  name: string,
  network: string,
  paid_total: number,
  payout_ratio: boolean,
  start_date: string,
  status: string
}

interface InsuranceOptionsProps {
  insurance: string;
  payer_id: number;
  label?: string;
}

interface ExternalDataProps {
  allowedPercent: string;
  avgDailyRate: string;
  lastPaid: string;
  levelOfCare: string;
  paidPercent: string;
  prefix: string;
  totalUnits: string;
}

interface NotesProps {
  date: string;
  intake_id: string;
  notes: string;
  coordinator: string;
}

interface DataContextType {
  insuranceOptions: InsuranceOptionsProps[] | null;
  addRecord: boolean;
  availityData: any;
  loadingAvailityData: boolean
  externalData: ExternalDataProps[] | null;
  currentNotes: NotesProps[] | null;
  currentIntakeId: any;
  billingAnalytics: BillingAnalyticsProps[] | null;
  billingAnalyticsTimespand: string;
  page: number, 
  currentPatients: any,
  patientDetails: any,
  patientDetailsFinancial: any,
  endDateCensus: Date,
  startDateCensus: Date,
  billingAnalyticsCount: any[]
  cptCode: string
  loc: string
  loadingCensus: boolean,
  availityBillingData: AvailityBillingDataProps[],
  currentTimeZone: string,
  loadingNewTicket: boolean,
  collectAllData: () => void;
  handleAddRecord: () => void;
  addSupportTicket: (data:any) => void;
  grabAvailityData: (claim_id: any) => void;
  grabAveaAvailityData: (claim_id: any) => void;
  grabCensusAvailityData: (claim_id: any) => void;
  grabExternalData: () => void;
  getNotes: (intake_id: string, coordinator: string) => void;
  sendNewNotes: (notesData: any) => void;
  searchExternalData: (search: string) => void;
  filterBillingAnalytics: (text: string) => void;
  handlePageChange: (page: number) => void,
  getCurrentPatients: () => void
  updatePatientDetails: (patient: any) => void
  getPatientDetailsFinancial: (selectedPolicy: string) => void
  getSinglePatientCensus: (intella_claim_id: string, start_date: Date, end_date: Date) => void
  updateStartDateCensus: (date: Date) => void
  updateEndDateCensus: (date: Date) => void
  handleCptChange: (data: string) => void
  handleCptCodeChange: (text: string) => void
  handleLocChange: (text: string) => void
  setLoadingCensus: (data: boolean) => void
  grabAvailityBillingData: (start: Date, end: Date) => void
  handleTimeZoneChange: (timezone: string) => void
}

const DataContext = createContext<DataContextType>({
  insuranceOptions: null,
  addRecord: false, 
  availityData: null,
  loadingAvailityData: false,
  externalData: null,
  currentNotes: null,
  currentIntakeId: null,
  billingAnalytics: null,
  billingAnalyticsTimespand: '1 Month',
  page: 0, 
  currentPatients: [],
  patientDetails: null,
  patientDetailsFinancial: [],
  endDateCensus: new Date(),
  startDateCensus: subDays(new Date(), 30),
  billingAnalyticsCount: [],
  cptCode: 'All',
  loc: 'All',
  loadingCensus: false,
  currentTimeZone: DateUtilityFunctions.getInitialTimeZone(),
  availityBillingData: [],
  loadingNewTicket: false,
  collectAllData: () => {},
  handleAddRecord: () => {},
  addSupportTicket: () => {},
  grabAvailityData: () => {},
  grabAveaAvailityData: () => {},
  grabCensusAvailityData: () => {},
  grabExternalData: () => {},
  getNotes: () => {},
  sendNewNotes: () => {},
  searchExternalData: () => {},
  filterBillingAnalytics: () => {},
  handlePageChange: () => {},
  getCurrentPatients: () => {},
  updatePatientDetails: () => {},
  getPatientDetailsFinancial: () => {},
  updateStartDateCensus: () => {},
  updateEndDateCensus: () => {},
  handleCptChange: () => {},
  handleCptCodeChange: () => {},
  handleLocChange: () => {},
  getSinglePatientCensus: () => {},
  setLoadingCensus: () => {},
  grabAvailityBillingData: () => {},
  handleTimeZoneChange: () => {}
});

export function useData() {
  return useContext(DataContext);
}

interface AppProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<AppProviderProps> = ({ children }) => {

  const [insuranceOptions, setInsuranceOptions] = useState<InsuranceOptionsProps[] | null>(null)

  const [addRecord, setAddRecord] = useState<boolean>(false)

  const [loadingNewTicket, setLoadingNewTicket] = useState<boolean>(false);

  const [availityData, setAvailityData] = useState<any>(null)
  const [loadingAvailityData, setLoadingAvailityData] = useState<boolean>(false)

  const [currentNotes, setCurrentNotes] = useState<NotesProps[] | null>(null)
  const [currentIntakeId, setCurrentIntakeId] = useState<any>(null)

  const [externalData, setExternalData] = useState<ExternalDataProps[] | null>(null)

  const [page, setPage] = useState<number>(() => {
    const storedPage = localStorage.getItem('page');
    return storedPage !== null ? parseInt(storedPage, 10) : 0;
  });

  const [cptCode, setCptCode] = useState<string>('All')
  const [loc, setLoc] = useState<string>('All')

  const [billingAnalytics, setBillingAnalytics] = useState<BillingAnalyticsProps[] | null>([])
  const [billingAnalyticsTimespand, setBillingAnalyticsTimespand] = useState<string>('1 Month')

  const [currentPatients, setCurrentPatients] = useState<any>([])
  const [patientDetails, setPatientDetails] = useState<any>([])
  const [patientDetailsFinancial, setPatientDetailsFinancial] = useState<any>([])

  const [endDateCensus, setEndDateCensus] = useState<Date>(new Date()); // Today's date
  const [startDateCensus, setStartDateCensus] = useState<Date>(subDays(new Date(), 30)); // 30 days ago

  const navigate = useNavigate()

  const [billingAnalyticsCount, setBillingAnalyticsCount] = useState<any>([])
  const [loadingCensus, setLoadingCensus] = useState<boolean>(false)
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(null);

  const {getAveaFollowup, getAllCensusFollowup, grabSearchByNameClaims, grabAveaClaims, grabSearchByNameCensus, grabAllDeniedClaims} = useClaims()
  const {getClaimsFollowup, } = useFollowup()
  const {grabAllProfiles} = useAuth()
  const {grabRecords} = useHistoric()
  const {getIntakeRecords} = useIntake()

  const [availityBillingData, setAvailityBillingData] = useState<AvailityBillingDataProps[] | []>([])

  const [currentTimeZone, setCurrentTimeZone] = useState<string>(DateUtilityFunctions.getInitialTimeZone())

  let counter = 0

  const handleTimeZoneChange = (timeZone: string) => {
    setCurrentTimeZone(timeZone);
    localStorage.setItem('userTimeZone', timeZone)
  }

  const [billingDetails, setBillingDetails] = useState<HistoricProps[] | null>(null)

  const handleCptCodeChange = (text: string) => {
    setCptCode(text)
  }

  const handleLocChange = (text: string) => {
    setLoc(text)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('page', page.toString());
  }

  const updatePatientDetails = (patient: any) => {
    setPatientDetails(patient)
  }

  const updateStartDateCensus = (date: Date) => {
    setStartDateCensus(date)
  }

  const updateEndDateCensus = (date: Date) => {
    setEndDateCensus(date)
  }

  const handleCptChange = (data: string) => {
    setCptCode(data)
  }

  const convertDate = (date: Date) => {
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Local month
    const dd = String(date.getDate()).padStart(2, '0'); // Local date
    return `${mm}/${dd}`;
  };

  const grabBillingAnalytics = () => {
    const url = `${baseUrl}/billing/`
    const body = {
      'tz': currentTimeZone
    }
    axios.post(url, body)
    .then((response) => {
      let data = response.data.slice(7, 37)
      data.reverse()
      data.map((record: any) => {
        if (!(record.start_date instanceof Date)) {
          record.start_date = new Date(record.start_date);
        }
        record.start_date = convertDate(record.start_date)
      })
      setBillingAnalytics(data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabBillingAnalyticsByTime = (timeSpan: number) => {
    const url = `${baseUrl}/billing/`
    const body = {
      'tz': currentTimeZone
    }
    axios.post(url, body)
    .then((response) => {
      let data = response.data.slice(7, timeSpan)
      data.reverse()
      data.map((record: any) => {
        if (!(record.start_date instanceof Date)) {
          record.start_date = new Date(record.start_date);
        }
        record.start_date = convertDate(record.start_date)
      })
      setBillingAnalytics(data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabAvailityBillingData = (start: Date, end: Date) => {
    const url = `${baseUrl}/availity/search`;
    const body = {
      'start_date': start,
      'end_date': end,
      'tz': currentTimeZone
    };
    axios.post(url, body)
    .then((response: any) => {

      const sortedData = response.data.sort((a: any, b: any) => {
        const dateA = new Date(a.finalized_date);
        const dateB = new Date(b.finalized_date);

        return dateA.getTime() - dateB.getTime();
      })
      console.log(sortedData)
      setAvailityBillingData(sortedData)
    })
    .catch((error) => {
      console.error(error)
    });
  }

  const reformatSubmissionDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-based month
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  const reformatData = (data: any) => {
    const result: { [key: string]: { total_records: number; pending: number; successful: number; failed: number; } } = {};
  
    data.forEach((record: any) => {
      const submissionDate = reformatSubmissionDate(record.submission_date); // Reformat the date
      if (!result[submissionDate]) {
        result[submissionDate] = {
          total_records: 0,
          pending: 0,
          successful: 0,
          failed: 0
        };
      }
      result[submissionDate].total_records += 1;
      if (record.claim_status === 'Pending') {
        result[submissionDate].pending += 1;
      } else if (record.claim_status === 'Successful') {
        result[submissionDate].successful += 1;
      } else if (record.claim_status === 'Failed') {
        result[submissionDate].failed += 1;
      }
    });
  }

    const grabBillingCount = ( timeSpan?: number, startDate?: string, endDate?: string) => {
      if (!timeSpan) return 
        const endDateObj = new Date();
        const startDateObj = new Date();
        startDateObj.setDate(endDateObj.getDate() - timeSpan);
  
        startDate = startDateObj.toISOString().split('T')[0]; // format as YYYY-MM-DD
        endDate = endDateObj.toISOString().split('T')[0]; // format as YYYY-MM-DD
      
      const data = {
        "start_date": startDate,
        "end_date": endDate
      };
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/claims/favorites/analytics`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          const reformattedData = reformatData(response.data);
          setBillingAnalyticsCount(reformattedData);
        })
        .catch((error) => {
          console.log(error);
        });
    }

  const filterBillingAnalytics = (text:string) => {
    if (text === '1 Week') {
      grabBillingCount(14)
      grabBillingAnalyticsByTime(14) 
    }
    else if(text === '1 Month') {
      grabBillingAnalyticsByTime(37)
      grabBillingCount(37)
    }
    else if(text === '3 Months') {
      grabBillingAnalyticsByTime(97)
      grabBillingCount(97)
    }
    else if(text === '6 Months') {
      grabBillingAnalyticsByTime(187)
      grabBillingCount(187)
    }
    setBillingAnalyticsTimespand(text)
  }

  function formatDate(date: Date) {
    const nextDate = new Date(date);
    const pstOffset = 8 * 60;
    const utcOffset = nextDate.getTimezoneOffset();
    nextDate.setMinutes(nextDate.getMinutes() + utcOffset - pstOffset);
    nextDate.setDate(nextDate.getDate() + 1);
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const day = String(nextDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const generateFiveDigitNumber = () => {
    const min = 10000;
    const max = 99999;
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    return number.toString();
  }


  const grabInsuranceOptions = () => {
    const url = `${baseUrl}/verifytx_payers`
    axios.get(url)
      .then((response) => {
        let records = response.data 
        records.sort((a: any, b: any) => {
          const insuranceA = a.insurance.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          const insuranceB = b.insurance.toUpperCase();
        
          if (insuranceA < insuranceB) {
            return -1;
          }
          if (insuranceA > insuranceB) {
            return 1;
          }
          return 0;
        });
        const udpatedRecords = reformatInsurance(records)
        setInsuranceOptions(udpatedRecords)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const grabAvailityData = (claim_id: any) => {
    setLoadingAvailityData(true);
    const url = `${baseUrl}/availity/claim`;

    const body = {
      'claim_id': claim_id,
      'tz': currentTimeZone
    }

    axios.post(url, body)
        .then((response: any) => {
          const existingClaimNumbers = new Set();
          const newDataArray = response.data.claimStatuses.reduce((acc: any[], claimStatus: any) => {
            if (!existingClaimNumbers.has(claimStatus.claimControlNumber)) {
              existingClaimNumbers.add(claimStatus.claimControlNumber);
              const percent_payout = Number(claimStatus.statusDetails[0].paymentAmount)/Number(claimStatus.statusDetails[0].claimAmount);
              const newData = {
                claimControlNumber: claimStatus.claimControlNumber,
                patientControlNumber: claimStatus.patientControlNumber,
                fromDate: claimStatus.fromDate,
                toDate: claimStatus.toDate,
                category: claimStatus.statusDetails[0].category,
                categoryCode: claimStatus.statusDetails[0].categoryCode,
                checkNumber: claimStatus.statusDetails[0].checkNumber,
                claimAmount: claimStatus.statusDetails[0].claimAmount,
                effectiveDate: claimStatus.statusDetails[0].effectiveDate,
                finalizedDate: claimStatus.statusDetails[0].finalizedDate,
                paymentAmount: claimStatus.statusDetails[0].paymentAmount,
                percent_payout: percent_payout,
                remittanceDate: claimStatus.statusDetails[0].remittanceDate,
                status: claimStatus.statusDetails[0].status,
                statusCode: claimStatus.statusDetails[0].statusCode,
                traceId: claimStatus.traceId,
                patientName: `${response.data.subscriber.lastName}, ${response.data.subscriber.firstName}`,
                payerName: response.data.payer.name,
                payerId: response.data.payer.id
              };
              acc.push(newData);
            }
            return acc;
          }, []);
          setAvailityData(newDataArray);
          navigate('/availityScreen');
          setLoadingAvailityData(false);

        })
        .catch((err: any) => {
          setLoadingAvailityData(false);
          if(counter < 3){
            counter = counter + 1;
            grabAvailityData(claim_id)
          }else{
            alert("Claim was not found in Availity");
            console.error(err);
            counter = 3;
          }
        });
  }

  const grabAveaAvailityData = (claim_id: any) => {
    setLoadingAvailityData(true);
    const url = `${baseUrl}/availity/avea`;
    const body = {
      'claim_id': claim_id,
      'tz': DateUtilityFunctions.getTimeZone()
    };
    axios.post(url, body)
      .then((response: any) => {
        const existingClaimNumbers = new Set();
        const newDataArray = response.data.claimStatuses.reduce((acc: any[], claimStatus: any) => {
          if (!existingClaimNumbers.has(claimStatus.claimControlNumber)) {
            const percent_payout = Number(claimStatus.statusDetails[0].paymentAmount) / Number(claimStatus.statusDetails[0].claimAmount);
            existingClaimNumbers.add(claimStatus.claimControlNumber);
            const newData = {
              claimControlNumber: claimStatus.claimControlNumber,
              patientControlNumber: claimStatus.patientControlNumber,
              fromDate: claimStatus.fromDate,
              toDate: claimStatus.toDate,
              category: claimStatus.statusDetails[0].category,
              categoryCode: claimStatus.statusDetails[0].categoryCode,
              checkNumber: claimStatus.statusDetails[0].checkNumber,
              claimAmount: claimStatus.statusDetails[0].claimAmount,
              effectiveDate: claimStatus.statusDetails[0].effectiveDate,
              finalizedDate: claimStatus.statusDetails[0].finalizedDate,
              paymentAmount: claimStatus.statusDetails[0].paymentAmount,
              percent_payout: percent_payout,
              remittanceDate: claimStatus.statusDetails[0].remittanceDate,
              status: claimStatus.statusDetails[0].status,
              statusCode: claimStatus.statusDetails[0].statusCode,
              traceId: claimStatus.traceId,
            };
            acc.push(newData);
          }
          return acc;
        }, []);
  
        setAvailityData(newDataArray);
        navigate('/availityScreen');
        setLoadingAvailityData(false);
  
      })
      .catch((err: any) => {
        setLoadingAvailityData(false);
        if (counter < 3) {
          counter = counter + 1;
          grabAveaAvailityData(claim_id);
        } else {
          alert("Claim was not found in Availity");
          console.error(err);
          counter = 3;
        }
      });
  };

  const grabCensusAvailityData = (claim_id: any) => {
    setLoadingAvailityData(true);
    const url = `${baseUrl}/availity/census`;

    const body = {
      'claim_id': claim_id,
      'tz': currentTimeZone
    }

    axios.post(url, body)
        .then((response: any) => {
          // existing code
          const existingClaimNumbers = new Set();
          const newDataArray = response.data.claimStatuses.reduce((acc: any[], claimStatus: any) => {
            if (!existingClaimNumbers.has(claimStatus.claimControlNumber)) {
              existingClaimNumbers.add(claimStatus.claimControlNumber);
              const percent_payout = Number(claimStatus.statusDetails[0].paymentAmount)/Number(claimStatus.statusDetails[0].claimAmount);
              const newData = {
                claimControlNumber: claimStatus.claimControlNumber,
                patientControlNumber: claimStatus.patientControlNumber,
                fromDate: claimStatus.fromDate,
                toDate: claimStatus.toDate,
                category: claimStatus.statusDetails[0].category,
                categoryCode: claimStatus.statusDetails[0].categoryCode,
                checkNumber: claimStatus.statusDetails[0].checkNumber,
                claimAmount: claimStatus.statusDetails[0].claimAmount,
                effectiveDate: claimStatus.statusDetails[0].effectiveDate,
                finalizedDate: claimStatus.statusDetails[0].finalizedDate,
                paymentAmount: claimStatus.statusDetails[0].paymentAmount,
                percent_payout: percent_payout,
                remittanceDate: claimStatus.statusDetails[0].remittanceDate,
                status: claimStatus.statusDetails[0].status,
                statusCode: claimStatus.statusDetails[0].statusCode,
                traceId: claimStatus.traceId,
              };
              acc.push(newData);
            }
            return acc;
          }, []);

          setAvailityData(newDataArray);
          navigate('/availityScreen');
          setLoadingAvailityData(false);

        })
        .catch((err: any) => {
          // existing code
          setLoadingAvailityData(false);
          // check if the coutner number <3
          if(counter < 3){
            counter = counter + 1;
            grabCensusAvailityData(claim_id)
          }else{
            alert("Claim was not found in Availity");
            console.error(err);
            counter = 3;
          }
          // increment the counter
          // call the same funciton repass the claim id

        });
  }

  const searchExternalData = (search: string) => {
    if(search === ''){
      grabExternalData()
    } else {
      let searchResults:any = []
      externalData?.map((record) => {
        const reference = record.prefix.toLowerCase()
        if(reference.includes(search.toLowerCase())){
          searchResults.push(record)
        }
      })
      setExternalData(searchResults)
    }
  }

  const getPatientDetailsFinancial = (selectedPolicy: string) => {
    const body = {
      'policy_id': selectedPolicy,
      'tz': currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/level1`,
      headers: { },
      data: body
    };
    axios.request(config)
    .then((response) => {
      setPatientDetailsFinancial(response.data.billing)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const grabExternalData = () => {
    const url = `${baseUrl}/external/getData`;
    axios.get(url)
    .then((response:any) => {
      setExternalData(response.data)
    })
    .catch((err: any) => {
      console.error("Failed to fetch External Data!")
    })
  }

  const getCurrentPatients = async () => {
    const data = {
      "tz": currentTimeZone,
      "start_date": formatDate(startDateCensus),
      "end_date": formatDate(endDateCensus)
    }
    const now = new Date().getTime();
    const cacheTime = 1.5 * 60 * 1000;
  
    if (currentPatients && lastFetchTime && (now - lastFetchTime < cacheTime)) {
      return;
    }
  
    setLoadingCensus(true);
  
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/census/records`,
      // url: `${baseUrl}/census/records?start_date=${formatDate(startDateCensus)}&end_date=${formatDate(endDateCensus)}`,
      headers: { },
      data: data
    };
  
    try {
      const response = await axios.request(config);
      setCurrentPatients(response.data);
      setLastFetchTime(now);
    } catch (err) {
      console.error("Failed to fetch current patients!", err);
    } finally {
      setLoadingCensus(false);
    }
  };

  const getSinglePatientCensus = (intella_claim_id: string, start_date: Date, end_date: Date) => {
    const data = {

      intella_claim_id: intella_claim_id,
      start_date: start_date,
      end_date: end_date,
      tz: currentTimeZone
    }
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/census/record`,
      headers: { },
      data: data
    };
    axios.request(config)
    .then((response: any) => {
      setPatientDetails(response.data)
    })
  }

  const getNotes = (intake_id: string, timeZone:string ) => {
    setCurrentIntakeId(intake_id)
    const url = `${baseUrl}/intake/get_intake_note`

    const body ={
      'intake_id': intake_id,
      'tz': timeZone
    }

    axios.post(url, body)
    .then((response: any) => {
      if (response) {
        setCurrentNotes(response.data)
    }})
    .catch((err: any) => {
      console.error("Error fetching notes.", err)
    })
  }


  const sendNewNotes = (notesData: any) => {
    const url = `${baseUrl}/intake/update_intake_note`

    const body = {
      ...notesData,
      'tz': currentTimeZone
    }

    axios.post(url, body)
    .then((response: any) => {
      getNotes(currentIntakeId, notesData.coordinator)
    })
    .catch((err: any) => {
      console.error("Error sending new notes.", err)
    })
  }

  const reformatInsurance = (records: InsuranceOptionsProps[]) => {
    let newRecords: any[] = []
    records.map((record: any) => {
      newRecords.push({label: record.insurance, value: record.payer_id})
    })
    return(newRecords)
  }

  const handleAddRecord = () => {
    setAddRecord(!addRecord)
  }

  const addSupportTicket = (data: any) => {
    setLoadingNewTicket(true);

    const ticket_id = generateFiveDigitNumber();
    const ticket_data = { data: {
      email: data.email,
      message: data.message,
      name: data.name,
      status: data.status,
      subject: data.subject,
      ticket_id: ticket_id
    }

    }

    const url = `${baseUrl}/support/submit_ticket`
    

    axios.post(url, ticket_data.data)
    .then((response) => {
      setLoadingNewTicket(false);
      alert('Your ticket request has been sent to our team. We will try to fix the issue and get back to your regarding this issue.')
    })

  }

  const collectAllData = () => {
    grabAllProfiles()
    getIntakeRecords()
    grabInsuranceOptions()
    grabRecords()
    grabSearchByNameClaims("")
    grabAveaClaims()
    getClaimsFollowup()
    grabExternalData()
    getAveaFollowup()
    grabBillingAnalytics()
    grabBillingCount(14)
    // getCurrentPatients()
    getAllCensusFollowup()
    // getFollowupCensusRecords()
    grabSearchByNameCensus('')
    grabAllDeniedClaims()
  }

  const contextValue: DataContextType = {
    insuranceOptions, 
    addRecord, 
    billingAnalytics,
    billingAnalyticsTimespand,
    page, 
    currentPatients,
    patientDetails,
    patientDetailsFinancial,
    startDateCensus,
    endDateCensus,
    availityData,
    loadingAvailityData,
    currentNotes,
    externalData,
    currentIntakeId,
    billingAnalyticsCount,
    loadingCensus,
    cptCode,
    loc,
    availityBillingData,
    handleCptChange,
    handleCptCodeChange,
    handleLocChange,
    getSinglePatientCensus,
    collectAllData,
    handleAddRecord,
    addSupportTicket,
    grabAvailityData,
    grabAveaAvailityData,
    grabCensusAvailityData,
    grabExternalData,
    getNotes,
    sendNewNotes,
    searchExternalData,
    filterBillingAnalytics,
    handlePageChange,
    getCurrentPatients,
    updatePatientDetails,
    getPatientDetailsFinancial,
    updateStartDateCensus,
    updateEndDateCensus,
    setLoadingCensus,
    grabAvailityBillingData,
    currentTimeZone,
    handleTimeZoneChange,
    loadingNewTicket
  };

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );
};