import React, { useState, useEffect } from 'react'
import { useNavigation } from '../../Context/NavigationContext'
import { useFollowup } from '../../Context/FollowupContext'
import { useIntake } from '../../Context/IntakeContext'
import { useData } from '../../Context/DataContext'
import NavigationTabComponent from './NavigationTabComponent'
import ReminderPopup from '../Misc/ReminderPopup'
import BellReminderPopup from '../Misc/BellReminderPopup'
import Logo2 from '../../Assets/new-logo.png'
import { TimeZoneOptions, TimeZoneOffsets } from '../../Options/TimeZoneOptions'
import { useCustomTheme } from '../../Context/ThemeContext'
import { Moon, Sun, Clock, X } from 'react-feather'
import TimeZoneSelector from '../Inputs/TimeZoneSelector'

const TopRowComponent = () => {
  const {isDarkMode, toggleDarkMode} = useCustomTheme()
  const { topNavigation } = useNavigation();
  const { currentTimeZone, handleTimeZoneChange } = useData()
  const { fetchReminders, showReminder, setShowReminder, remindersData } = useFollowup();
  const { fetchYellowStripeRemindersData, yellowStripeRemindersData, showBellReminder, setShowBellReminder, showTimeSelector, handleShowTimeZones } = useIntake();

  const [showRedDot, setShowRedDot] = useState(false);

  const handleBellClick = () => {
    setShowBellReminder(prev => ({ ...prev, isVisible: !prev.isVisible }));
    setShowReminder(false);
  }


  useEffect(() => {
    fetchYellowStripeRemindersData();
  }, []);

  return (
    <div className='w-screen min-h-16 flex flex-row justify-between items-center bg-slate-900 dark:bg-slate-900'>
      <div className='flex flex-row items-center'>
        <img className='h-8 ml-2' src={Logo2} alt="Logo"/>
      </div>
      <div className='flex flex-row mr-2 justify-between items-center'>
        <div className='mr-3 flex flex-row relative items-center justify-center'>
          <div onClick={handleShowTimeZones}>
            <Clock size={20} color='white' />
          </div>
          { showTimeSelector &&
            <div className='absolute bg-slate-300 dark:bg-secondary p-1 mt-1 rounded-md z-50 right-10'>
              <TimeZoneSelector placeholder={currentTimeZone} selectedValue={currentTimeZone} options={TimeZoneOptions} mappedOptions={TimeZoneOffsets} onTimeZoneChange={handleTimeZoneChange}/>
            </div>
          }
        </div>
        <div onClick={toggleDarkMode} className='flex flex-row'>
          <p className='text-white dark:text-white'>
            {isDarkMode ? 
              <div className='flex flex-row items-center'><Sun size={20} color='white' /></div> : 
              <div className='flex flex-row items-center'><Moon size={20} color='white' /></div>
            }
          </p>
        </div>
        {
          topNavigation.map((tab, index) => (
            <div key={index} className="relative flex items-center">
              <NavigationTabComponent 
                type={tab.page} 
                onClick={tab.icon === 'bell' ? handleBellClick : undefined} 
                tab={tab.label} 
                icon={tab.icon} 
                route={tab.route} 
                position={'top'}
              />
              {tab.page === 'popup' && tab.icon === 'activity' && showRedDot && (
                <span className="absolute top-1.5 right-1 block h-2 w-2 rounded-full bg-red-600" style={{ transform: 'translate(50%, -50%)' }} />
              )}
            </div>
          ))
        }
        <div className='relative'>
          {showReminder && <ReminderPopup/>}
        </div>
      </div>
    </div>
  )
}

export default TopRowComponent