import React from 'react';
import CellComponent from './CellComponent';
import { useClaims } from '../../Context/ClaimsContext';
import { useNavigation } from '../../Context/NavigationContext';
import { useFollowup } from '../../Context/FollowupContext';
import { useAuth } from '../../Context/AuthContext';
import SortHeaderComponent from '../SortAndFilter/SortHeaderComponent';
import { useIntake } from '../../Context/IntakeContext';

interface PeopleOptions {
  name: string;
  userId: string;
}

interface ColumnData {
  label: string;
  type: string;
  recordName: string;
  options?: string[];
  dependent?: string;
  dependentResults?: string[];
  people?: PeopleOptions[];
  width?: string;
  sort?: boolean;
  admin?: boolean
}

interface UserData {
  active: boolean;
  company: string;
  department: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  privileges: string;
  userid: string;
}

interface RecordData {
  [key: string]: any;
}

interface TableProps {
  columns: ColumnData[];
  records: any;
  users: UserData[] | null;
  table: string
}

const TableComponent: React.FC<TableProps> = (props) => {
  const { columns, records, users, table } = props;

  const { currentSidebarTab } = useNavigation();
  const { selectedClaims, selectedClaimsAvea, allClaims, 
    allClaimsAvea, unselectAllClaims, selectAllClaims,
    unselectAllClaimsAvea, selectAllClaimsAvea } = useClaims();
  const { selectAllFollowup, unselectAllFollowup, allFollowup, 
    selectedColumnsFollowup, selectedColumnsFollowupAvea, selectedColumnsFollowupCensus } = useFollowup();
  const { currentProfile } = useAuth();
  const { selectedColumns, selectedColumnsAvea, selectedColumnsCensus } = useClaims();
  const { selectedColumnsIntake } = useIntake();

  return (
    <div className='max-w-full max-h-full overflow-auto'>
      <table className='w-full border-collapse'>
        <thead className='bg-primary-header dark:bg-secondary sticky top-0 h-14'>
          <tr>
            {columns?.map((column, index) => {
              const selectedToShow = selectedColumns.includes(column.label);
              const selectedToShowAvea = selectedColumnsAvea.includes(column.label);
              const selectedToShowCensus = selectedColumnsCensus.includes(column.label);
              const selectedToShowFollowup = selectedColumnsFollowup.includes(column.label);
              const selectedToShowAveaFollowup = selectedColumnsFollowupAvea.includes(column.label);
              const selectedToShowCensusFollowup = selectedColumnsFollowupCensus.includes(column.label);
              const selectedToShowCIntake = selectedColumnsIntake.includes(column.label);

              if (
                (table === 'Claims' && !selectedToShow) ||
                (table === 'Avea Claims' && !selectedToShowAvea) ||
                (table === 'Claims Census' && !selectedToShowCensus) ||
                (table === 'Followup Collab' && !selectedToShowFollowup) ||
                (table === 'Followup Avea' && !selectedToShowAveaFollowup) ||
                (table === 'Followup Census' && !selectedToShowCensusFollowup) ||
                (table === 'intake' && !selectedToShowCIntake)
              ) {
                return null;
              }

              return (
                <th key={index} style={{ minWidth: column.width }}>
                  {column.type === 'checkbox' ? (
                    currentSidebarTab === 'Claims' ? (
                      <input
                        type="checkbox"
                        checked={table === 'Claims' ? allClaims : table === 'Avea Claims' ? allClaimsAvea : false}
                        onChange={() => {
                          if (table === 'Claims') {
                            if (allClaims) {
                              unselectAllClaims();
                            } else {
                              selectAllClaims(records);
                            }
                          }
                          if (table === 'Avea Claims') {
                            if (allClaimsAvea) {
                              unselectAllClaimsAvea();
                            } else {
                              selectAllClaimsAvea(records);
                            }
                          }
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        checked={allFollowup}
                        onChange={() => {
                          if (allFollowup) {
                            unselectAllFollowup();
                          } else {
                            selectAllFollowup(records);
                          }
                        }}
                      />
                    )
                  ) : column.sort ? (
                    <SortHeaderComponent label={column.label} columnName={column.recordName} />
                  ) : column.label === 'Delete' ? (
                    currentProfile.privileges === 'admin' || currentProfile.privileges === 'manager' || currentProfile.privileges === 'dev' || currentProfile.privileges === 'owner' ? (
                      <p className='text-lg text-black dark:text-white'>{column.label}</p>
                    ) : null
                  ) : (
                    <p className='text-lg text-black dark:text-white'>{column.label}</p>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {records != null ? (
            records.map((record: any, rowIndex: number) => (
              <tr key={rowIndex} className={`text-center min-h-14 h-16 text-black dark:text-white font-semibold ${rowIndex % 2 === 0 ? 'bg-slate-100 dark:bg-stone-900' : 'bg-primary-alternate dark:bg-stone-800'}`}>
                <CellComponent table={table} columns={columns} record={record} selectedClaims={table === 'Claims' ? selectedClaims : table === 'Avea Claims' ? selectedClaimsAvea : null} />
              </tr>
            ))
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
