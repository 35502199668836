import React from "react";
import { useState, useCallback } from "react";
import { useFollowup } from "../../Context/FollowupContext";
import { useData } from "../../Context/DataContext";
import { useAuth } from "../../Context/AuthContext";
import { useIntake } from "../../Context/IntakeContext";
import ButtonComponent from "../Inputs/ButtonComponent";
import { DateUtilityFunctions } from "../../Utils/DateUtilityFunctions";
import { debounce } from "@mui/material";
import {XCircle} from 'react-feather'

const BellReminderPopup = () => {
  const {currentUser} = useAuth()
  
  const {sendNewNotes} = useData()

  const { yellowStripeRemindersData,fetchYellowStripeRemindersData, setShowBellReminder, showBellReminder, handleDismissBellReminder } = useIntake();

  const [inputNotes, setInputNotes] = useState<{ [key: string]: string }>({})

  const formatDateToPST = (dateString: string) => {
    const date = new Date(dateString);

    // Convert date to PST (UTC-8 or UTC-7 depending on DST)
    const utcOffset = date.getTimezoneOffset(); // Get the current UTC offset in minutes
    const pstOffset = 8 * 60; // PST is UTC-8 hours
    const dstOffset = date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }).includes('PDT') ? 60 : 0;

    // Calculate the total offset and adjust the date
    const totalOffset = pstOffset - utcOffset + dstOffset;
    const pstDate = new Date(date.getTime() - (totalOffset * 60 * 1000));

    // Format the date to YYYY-MM-DD
    const year = pstDate.getFullYear();
    const month = String(pstDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(pstDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const debouncedSetInputNotes = useCallback(
    debounce((intakeId: string, value: string) => {
      setInputNotes(prevNotes => ({
        ...prevNotes,
        [intakeId]: value
      }));
    }, 300),
    [setInputNotes]
  );

  const handleInputChange = useCallback((intakeId: string, value: string) => {
    debouncedSetInputNotes(intakeId, value);
  }, [debouncedSetInputNotes]);


  const handleSubmit = async (intakeId: string) => {
    const note = inputNotes[intakeId];

    const data = {
      intake_id: intakeId,
      date: DateUtilityFunctions.getCurrentDateString(),
      notes: note,
      coordinator: currentUser.userId
    }
    if (!note) return; // Don't submit if the note is empty
  
    try {

      await sendNewNotes(data);
      
      // Clear the input after successful submission
      setInputNotes(prevNotes => ({
        ...prevNotes,
        [intakeId]: ''
      }));

      fetchYellowStripeRemindersData()
  
      // Optionally, you might want to refetch the reminders or update the local state
      // await fetchYellowStripeRemindersData();
    } catch (error) {
      console.error("Failed to submit note:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // const handleShowBell = () => {
  //   setShowBellReminder(!showBellReminder)
  // }
  return (
    <div className='absolute flex justify-center items-center align-middle inset-0 text-center w-full z-50'>
      
      <div className='relative h-3/5 w-3/4 flex flex-col gap-5 bg-slate-300 dark:bg-slate-700 overflow-y-auto shadow-lg rounded-lg p-4'>
        <div className="relative">
          <XCircle onClick={handleDismissBellReminder} className="fixed z-80" size={25} color='red'/>
        </div>
        {yellowStripeRemindersData && yellowStripeRemindersData.length > 0 
        ? (yellowStripeRemindersData.map((reminder, index) => {
          return (
              <div key={index} className='flex reminder justify-between items-center flex-row border-4 rounded-md p-4'>
                <p className="text-black dark:text-white">{reminder.name}</p>
                <p className="text-black dark:text-white">Last note submitted: {formatDateToPST(reminder.most_recent_date)}</p>
                <textarea 
                  className="flex bg-primary min-w-80 dark:bg-secondary text-white placeholder-slate-300 p-2" 
                  placeholder="Input Note..." 
                  value={inputNotes[reminder.intake_id || '']}
                  onChange={(e) => handleInputChange(reminder.intake_id, e.target.value)}
                />
                <div onClick={() => handleSubmit(reminder.intake_id)} className="flex hover:cursor-pointer">
                  <p className='text-center px-2 py-1 bg-primary dark:bg-secondary rounded-md font-bold text-white'>Submit</p>
                </div>
              </div>
          );
        })
        ) : (
          <p>Up to date on Yellow Stripes!</p>
        )}
      </div>
    </div>
  );
}

export default BellReminderPopup;
