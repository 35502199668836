import React, { useEffect, useState } from 'react'
import { ChevronsDown, ChevronsUp } from 'react-feather'
import { useData } from '../../Context/DataContext'
import { useNavigation } from '../../Context/NavigationContext'
import { useClaims } from '../../Context/ClaimsContext'
import { useFollowup } from '../../Context/FollowupContext'

interface SortHeaderProps {
  label: string,
  columnName: string
}

const SortHeaderComponent: React.FC<SortHeaderProps> = ({label, columnName}) => {

  const {ascending, handleSortColumnChange, handleSortOrderChange, grabRefreshClaims} = useClaims()
  const {getRefreshClaimsFollowupTwo} = useFollowup()
  const {currentSidebarTab} = useNavigation()

  const onHandleChange = () => {
    handleSortOrderChange(!ascending)
    handleSortColumnChange(columnName)
  }

  useEffect(() => {
    if(currentSidebarTab === 'Follow Up'){
      grabRefreshClaims()
    } 
  }, [ascending])

  return (
    <div className='w-full flex flex-row justify-center ml-1.5' onClick={() => {onHandleChange()}}>
      <p className='text-lg text-black dark:text-white'>{label}</p>
      {
        ascending
          ? <ChevronsUp height={24} width={24} className='text-black dark:text-white'/>
          : <ChevronsDown height={24} width={24} className='text-black dark:text-white'/>
      }
    </div>
  )
}

export default SortHeaderComponent