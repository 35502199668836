import React from 'react'
import LayoutComponent from '../BaseScreen'
import { accountsOptions } from '../../Options/AccountsOptions'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { useAuth } from '../../Context/AuthContext'

const AccountsScreen = () => {

  const {allUsers} = useAuth()

  return (
    <LayoutComponent
      header={null} 
      content={
        <div className="w-full px-4 mt-3" style={{ height: 'calc(100vh - 5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <TableComponent table={'Accounts'} users={allUsers} columns={accountsOptions} records={allUsers}/>
            </div>
          </div>
      } 
    />
  )
}

export default AccountsScreen
