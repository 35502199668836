import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import LayoutComponent from '../BaseScreen';
import IntakeFilterComponent from '../../Components/SortAndFilter/IntakeFilterComponent';
import TableComponent from '../../Components/Tables/TableComponent';
import { useIntake } from '../../Context/IntakeContext';
import { useData } from '../../Context/DataContext';
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import { intakeAdminColumns } from '../../Options/IntakeAdminOptions';
import { intakeDevOptions } from '../../Options/IntakeDevOptions';
import { intakeOColumns } from '../../Options/IntakeOptions';
import { historic2Options } from '../../Options/Historic2Options';

const HomeScreen = () => {
  const { currentProfile } = useAuth();
  const { allUsers, intakeUsers } = useAuth();
  const { showPrefix, hidePrefixPopup, prefixDataDashboard } = useIntake();
  const { intakeRecords, filteredIntakeRecords, getIntakeRecords } = useIntake();
  const [records, setRecords] = useState<any>([]);

  useLayoutEffect(() => {
    getIntakeRecords()
  }, [])

  useEffect(() => {
    setRecords(intakeRecords);
  }, [intakeRecords]);

  const showIntakeTable = () => (
    <LayoutComponent
      header={
        <div className="h-14 w-full mb-2">
          <IntakeFilterComponent />
        </div>
      }
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            {currentProfile.department === 'administration' ? (
              <TableComponent table="intake" users={intakeUsers} columns={intakeAdminColumns} records={filteredIntakeRecords} />
            ) : currentProfile.department === 'dev' ? (
              <TableComponent table="intake" users={intakeUsers} columns={intakeDevOptions} records={filteredIntakeRecords} />
            ) : (
              <TableComponent table="intake" users={intakeUsers} columns={intakeOColumns} records={filteredIntakeRecords} />
            )}
          </div>
        </div>
      }
    />
  );

  const showPopup = () => {
    if (prefixDataDashboard) {
      return (
        <LayoutComponent
          header={
            <div className="h-14 px-2 w-full flex flex-col justify-center bg-stone-700 rounded-md mb-4">
              <ButtonComponent label="Back" handler={hidePrefixPopup} />
            </div>
          }
          content={
            <div className="h-full w-full overflow-auto">
              {prefixDataDashboard && prefixDataDashboard.length > 0 ? (
                <TableComponent table="HistoricPrefix" columns={historic2Options} records={prefixDataDashboard} users={allUsers} />
              ) : (
                <div className="flex justify-center items-center h-full w-full p-8">
                  <p className="flex text-white text-center">No records available for this prefix!</p>
                </div>
              )}
            </div>
          }
        />
      );
    }
  };

  return <>{showPrefix ? showPopup() : showIntakeTable()}</>;
};

export default HomeScreen;
