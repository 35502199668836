import React, { useState } from 'react'
import PaginationComponent from '../Pagination/PaginationComponent'
import ButtonComponent from '../Inputs/ButtonComponent'
import IntakeForm from '../Forms/IntakeForm'
import { useData } from '../../Context/DataContext'
import SearchComponent from '../Inputs/SearchComponent'
import { X } from 'react-feather'
import SelectPeopleComponent from '../Tables/SelectPeopleComponent'
import SelectInputColumns from '../Inputs/SelectInputColumns'
import { useIntake } from '../../Context/IntakeContext'
import { useAuth } from '../../Context/AuthContext'

const IntakeFilterComponent = () => {

  const {addRecord, handleAddRecord} = useData()
  const {toggleToShowColumnIntake, selectedColumnsIntake, 
    searchIntakeRecords, getIntakeRecords, updateFilteredIntakeRecords, filteredUser} = useIntake()
  const {intakeUsers} = useAuth()

  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] =useState(30)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [activeSearch, setActiveSearch] = useState<boolean>(false)

  const cliamsIntakeOption = [
    'Notes',
    'Details',
    'Date',
    'Status',
    'Booked',
    'Reason',
    'Arriving Date',
    'Facility',
    'Client',
    'DOB',
    'Insurance',
    'Prefix',
    'Policy',
    'Active',
    'Verify TX',
    'Coordinator',
    'Phone',
    'Emergency Name',
    'Emergency Phone',
  ]

  const handleSearchChange = (text: string) => {
    setSearchTerm(text)
    if(text === ''){
      getIntakeRecords()
    } else {
      searchIntakeRecords(text)
    }
  }

  const enhancedIntakeUsers = intakeUsers ? [
    { active:true,
      company:"PHG",
      department:"intake",
      email:"",
      first_name:"",
      last_name:"",
      name:"Select A Coordinator",
      privileges:"staff",
      userid:"1"
    },
    ...intakeUsers
  ] : [];

  return (
    <div className="h-14 px-2 w-full flex flex-col justify-center bg-primary-header dark:bg-slate-700 rounded-lg mb-4">
      <div className='w-full flex flex-row items-center justify-start'>
        <ButtonComponent label='Add Record' handler={handleAddRecord}/>
        <SearchComponent searchTerm={searchTerm} handler={handleSearchChange}
          activeSearch={activeSearch} handleActiveSearch={() => {}}
          placeholder='Search Name...' requireSubmit={false}
        />
        <div className='flex min-w-64 flex-row items-center mr-4'>
          <p className='mx-2 text-black dark:text-white font-semibold'>Columns: </p>
          <SelectInputColumns options={cliamsIntakeOption} showColumnFunction={toggleToShowColumnIntake} columnsList={selectedColumnsIntake}/>
        </div>
        <div className='flex flex-row items-center'>
          <p className='text-black dark:text-white text-base font-semibold mr-2'>Coordinators: </p>
          <SelectPeopleComponent
            options={enhancedIntakeUsers}
            value={filteredUser}
            onChange={(newValue) => {
              updateFilteredIntakeRecords(newValue)
            }}
          />
        </div>
      </div>
      {
        addRecord ? (
          <div className='absolute max-h-96 w-1/4 top-[4.5rem] left-4 bg-stone-300 dark:bg-stone-700 p-3 min-w-96 rounded-lg overflow-y-scroll'>
            <div className='w-full flex flex-row justify-between'>
              <div className='text-black dark:text-white font-bold text-2xl ml-2'>New Record</div>
              <X onClick={handleAddRecord} height={30} width={30} color='red'/>
            </div>
            <IntakeForm />
          </div>
        ) : (
          null
        )
      }
    </div>
  )
}

export default IntakeFilterComponent
