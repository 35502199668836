export const TimeZoneOptions = [
  "Etc/GMT+12",  
  "Pacific/Niue",  
  "Pacific/Honolulu",  
  "America/Anchorage",  
  "America/Los_Angeles",  
  "America/Denver",  
  "America/Chicago",  
  "America/New_York",  
  "America/Halifax",  
  "America/St_Johns",  
  "America/Sao_Paulo",  
  "Atlantic/South_Georgia",  
  "Atlantic/Azores",  
  "Europe/London",  
  "Europe/Berlin",  
  "Europe/Helsinki",  
  "Europe/Moscow",  
  "Asia/Tehran",  
  "Asia/Dubai",  
  "Asia/Kabul",  
  "Asia/Kolkata",  
  "Asia/Dhaka",  
  "Asia/Bangkok",  
  "Asia/Singapore",  
  "Asia/Tokyo",  
  "Australia/Sydney",  
  "Pacific/Noumea",  
  "Pacific/Auckland",  
  "Pacific/Chatham",  
  "Pacific/Apia",  
  "Pacific/Kiritimati"  
]


export const TimeZoneOffsets = {
  "Etc/GMT+12": 'UTC-12:00',
  "Pacific/Niue": 'UTC-11:00',
  "Pacific/Honolulu": 'UTC-10:00',
  "America/Anchorage": 'UTC-09:00',
  "America/Los_Angeles": 'UTC-08:00',
  "America/Denver": 'UTC-07:00',
  "America/Chicago": 'UTC-06:00',
  "America/New_York": 'UTC-05:00',
  "America/Halifax": 'UTC-04:00',
  "America/St_Johns": 'UTC-03:30',
  "America/Sao_Paulo": 'UTC-03:00',
  "Atlantic/South_Georgia": 'UTC-02:00',
  "Atlantic/Azores": 'UTC-01:00',
  "Europe/London": 'UTC+00:00',
  "Europe/Berlin": 'UTC+01:00',
  "Europe/Helsinki": 'UTC+02:00',
  "Europe/Moscow": 'UTC+03:00',
  "Asia/Tehran": 'UTC+03:30',
  "Asia/Dubai": 'UTC+04:00',
  "Asia/Kabul": 'UTC+04:30',
  "Asia/Kolkata": 'UTC+05:30',
  "Asia/Dhaka": 'UTC+06:00',
  "Asia/Bangkok": 'UTC+07:00',
  "Asia/Singapore": 'UTC+08:00',
  "Asia/Tokyo": 'UTC+09:00',
  "Australia/Sydney": 'UTC+10:00',
  "Pacific/Noumea": 'UTC+11:00',
  "Pacific/Auckland": 'UTC+12:00',
  "Pacific/Chatham": 'UTC+12:45',
  "Pacific/Apia": 'UTC+13:00',
  "Pacific/Kiritimati": 'UTC+14:00'
};

