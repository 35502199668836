import { useChat } from "../../../Context/ChatContext"

const IntellachatMessages = () => {
  const {chatMessages} = useChat()

  return (
  <div className="bg-white h-full max-h-full overflow-y-auto rounded-md border-4 border-primary p-1">
    {chatMessages.map((message, index) =>
    {
      return (
        <div key={index}>
          <div className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`w-fit rounded-md p-3 mt-3 ${message.sender === 'ai' ? 'bg-gray-500 ' : 'bg-purple-600 justify-end'}`}>
              <div className={`rounded-md w-fit p-2 flex ${message.sender === 'ai' ? 'bg-purple-400' : 'bg-blue-400'}`}>
                {message.sender === 'ai' ? `✨ ${message.sender}` : message.sender}
              </div>
              <p className="m-2">{message.message}</p>
            </div>
          </div>
        </div>
      )
    }
    )}
  </div>
  )
}

export default IntellachatMessages