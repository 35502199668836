import React, { useEffect, useState } from 'react';
import LayoutComponent from '../BaseScreen';
import { useData } from '../../Context/DataContext';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, BarChart, Cell } from 'recharts';
import DisplayAccordianHeader from '../../Components/SortAndFilter/DisplayAccordianHeader';
import BillingDetailsFilterComponent from '../../Components/SortAndFilter/BillingDetailsFilterComponent';
import CalendarSelectComponent from '../../Components/Inputs/CalendarSelectComponent';
import SelectOptionComponent from '../../Components/Tables/SelectOptionComponent';
import BillingTotals from '../../Components/Tables/BillingAnalyticsTable';
import { useFollowup } from '../../Context/FollowupContext';
import { useClaims } from '../../Context/ClaimsContext';
import { aggregateDataByFacility, monthSelectOptions, getDateRangeFromSelection } from '../../Utils/BillingAnalyticsFunctions';
import SelectInputComponent from '../../Components/Inputs/SelectInputComponent';
import MonthYearSelector from '../../Components/Inputs/SelectMonthYear';
import FacilityBarChart from '../../Components/Analytics/FacilityBarChart';

const BillingAnalyticsScreen = () => {
  const { billingAnalytics } = useData();
  const { billingAnalyticsCount } = useFollowup()
  const { oldCmdSubmittedClaims, newCmdSubmittedClaims } = useClaims()

  const {billingAnalyticsTimespand, filterBillingAnalytics, grabAvailityBillingData, availityBillingData} = useData()
  const billingAnalyticsOptions = ['1 Week', '1 Month', '3 Months', '6 Months']
  const billingAnalyticsFacilityOptions = ['All', 'Totals', 'Affinity', 'Beachside', 'Axis']
  const billingAnalyticsFacilityOptions2 = ['All','Affinity', 'Beachside', 'Axis']

  const currentDate = new Date()
  const oneMonthAgo = new Date(currentDate)
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [showTotalPaid, setShowTotalPaid] = useState<boolean>(false);
  const [showSubmittedClaims, setShowSubmittedClaims] = useState<boolean>(false);
  const [showSubmittedClaimsOld, setShowSubmittedClaimsOld] = useState<boolean>(false);
  const [yAxisDomain, setYAxisDomain] = useState<[number, number]>([0, 100]);
  const [chargedyAxisDomain, setChargedYAxisDomain] = useState<[number, number]>([0, 100]);
  const [facilityFilter, setFacilityFilter] = useState<string>('All')
  // const [startDate, setStartDate] = useState<Date>(oneMonthAgo)
  // const [endDate, setEndDate] = useState<Date>(currentDate)
  const [startMonth, setStartMonth] = useState<string>(monthSelectOptions[oneMonthAgo.getMonth()])
  const [endMonth, setEndMonth] = useState<string>(monthSelectOptions[currentDate.getMonth()])
  const [startYear, setStartYear] = useState<string>(oneMonthAgo.getFullYear().toString())
  const [endYear, setEndYear] = useState<string>(currentDate.getFullYear().toString())

  const colors = {
    Affinity: "bg-custom-orange",
    Axis: "bg-custom-yellow",
    Beachside: "bg-custom-red",
    total_paid: 'bg-custom-macaroni',
    Affinity_charged: "bg-custom-orange",
    Axis_charged: "bg-custom-yellow",
    Beachside_charged: "bg-custom-red",
    total_charged: 'bg-custom-macaroni',
    total_records: 'bg-custom-macaroni',
    successful: 'bg-custom-green',
    pending: 'bg-custom-orange',
    failed: 'bg-custom-red'
  }

  const keysAndLabels = [
    { key: 'total_paid', label: 'Total' },
    { key: 'Affinity', label: 'Affinity' },
    { key: 'Axis', label: 'Axis' },
    { key: 'Beachside', label: 'Beachside' },
  ]
  
  const keysAndLabelsCharged = [
    { key: 'total_charged', label: 'Total' },
    { key: 'Affinity_charged', label: 'Affinity' },
    { key: 'Axis_charged', label: 'Axis' },
    { key: 'Beachside_charged', label: 'Beachside' },
  ]

  const keysAndLabelsCounts = [
    { key: 'total_records', label: 'Total Records' },
    { key: 'successful', label: 'Successful' },
    { key: 'pending', label: 'Pending' },
    { key: 'failed', label: 'Failed' },
  ];


  // useEffect(() => {
  //   let maxValue: number = 0;
  //   let maxValueCharged: number = 0;
  //   if (billingAnalytics != null) {
  //     billingAnalytics.map(entry => {
  //       let totalPaid: number = parseInt(entry.total_paid);
  //       if (totalPaid > maxValue) {
  //         maxValue = totalPaid;
  //       }
  //       let totalCharged: number = parseInt(entry.total_charged)
  //       if (totalCharged > maxValueCharged) {
  //         maxValueCharged = totalCharged
  //       }
  //     });
  //     setYAxisDomain([0, maxValue]);
  //     setChargedYAxisDomain([0, maxValueCharged]);
  //   }
  // }, []);

  useEffect(() => {
    const {start_date, end_date} = getDateRangeFromSelection(startMonth, startYear, endMonth, endYear)
    grabAvailityBillingData(start_date, end_date)
  }, [startMonth, startYear, endMonth, endYear])

  const filterBillingDetailsFacility = (text: string) => {
    setFacilityFilter(text)
  }

  const handleSelectChangeStartMonth = (value: string) => {
    setStartMonth(value);
  };

  const handleSelectChangeStartYear = (value: string) => {
    setStartYear(value)
  }

  const handleSelectChangeEndMonth = (value: string) => {
    setEndMonth(value)
  }

  const handleSelectChangeEndYear = (value: string) => {
    setEndYear(value)
  }


  const getBarColor = (facility: string) => {
    switch(facility) {
      case 'Affinity': return '#fc8003';
      case 'Beachside': return 'red';
      case 'Axis': return '#fcd303';
      case 'Total': return '#fcba03';
      default: return '#000000';
    }
  };

  const aggregatedData = aggregateDataByFacility(availityBillingData);

  return (
    <LayoutComponent
      header={null}
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 5.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
          <DisplayAccordianHeader label={'Total Paid'} display={showTotalPaid} onChange={() => setShowTotalPaid(!showTotalPaid)} />
          {
            showTotalPaid
              ? <div className="h-14 px-2 w-full flex flex-row items-center bg-slate-200 dark:bg-stone-700 rounded-md mb-4">
                  <div className='w-full flex flex-row items-center justify-center gap-40'>
                      <div className='flex flex-row text-black dark:text-white items-center'>
                        <MonthYearSelector onMonthChange={handleSelectChangeStartMonth} selectedMonth={startMonth} onYearChange={handleSelectChangeStartYear} selectedYear={startYear} label='Start'/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <MonthYearSelector onMonthChange={handleSelectChangeEndMonth} selectedMonth={endMonth} onYearChange={handleSelectChangeEndYear} selectedYear={endYear} label='End'/>
                      </div>
                    </div>
                </div>
              : null
          }
        {showTotalPaid ? (
        <FacilityBarChart 
          data={availityBillingData}
          startDate={new Date(parseInt(startYear), monthSelectOptions.indexOf(startMonth))}
          endDate={new Date(parseInt(endYear), monthSelectOptions.indexOf(endMonth))}
        />
      ) : null}
          <DisplayAccordianHeader label={'New Billing Via Intellasure'} display={showSubmittedClaimsOld} onChange={() => setShowSubmittedClaimsOld(!showSubmittedClaimsOld)} />
          {
            showSubmittedClaimsOld
              ? <div className="h-14 px-2 w-full flex flex-row items-center bg-stone-700 rounded-md mb-4">
                  <div className=''>
                    <SelectOptionComponent value={billingAnalyticsTimespand} onChange={filterBillingAnalytics} options={billingAnalyticsOptions}/>
                  </div>
                  <div className='ml-4'>
                    <SelectOptionComponent value={facilityFilter} onChange={filterBillingDetailsFacility} options={billingAnalyticsFacilityOptions}/>
                  </div>
                </div>
              : null
          }
          {
            showSubmittedClaimsOld
              ? <div>
                <LineChart
                  width={window.innerWidth * .85}
                  height={((2 / 3) * (window.innerWidth * .35))}
                  data={oldCmdSubmittedClaims} // Ensure to create a copy before reversing the array
                  margin={{ top: 5, right: 150, left: 100, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="gray" />
                  <XAxis dataKey="submission_date" tick={{ fill: 'gray' }} />
                  <YAxis/>
                  <Tooltip />
                  <Legend />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="total_records"
                    stroke="#fcba03"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="successful"
                    stroke="pink"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="pending"
                    stroke="red"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="failed"
                    stroke="orange"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
                <BillingTotals data={oldCmdSubmittedClaims} colors={colors} keysAndLabels={keysAndLabelsCharged} />
                </div>
              : null
          }
          <DisplayAccordianHeader label={'Re-Billing Via Intellasure'} display={showSubmittedClaims} onChange={() => setShowSubmittedClaims(!showSubmittedClaims)} />
          {
            showSubmittedClaims
              ? <div className="h-14 px-2 w-full flex flex-row items-center bg-stone-700 rounded-md mb-4">
                  <div className=''>
                    <SelectOptionComponent value={billingAnalyticsTimespand} onChange={filterBillingAnalytics} options={billingAnalyticsOptions}/>
                  </div>
                </div>
              : null
          }
          {
            showSubmittedClaims
              ? <div className='flex flex-col justify-center align-center'>
                <LineChart
                  width={window.innerWidth * .85}
                  height={((2 / 3) * (window.innerWidth * .35))}
                  data={newCmdSubmittedClaims} // Ensure to create a copy before reversing the array
                  margin={{ top: 5, right: 150, left: 100, bottom: 5 }}
                  className='flex-1'
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="gray" />
                  <XAxis dataKey="submission_date" tick={{ fill: 'gray' }} />
                  <YAxis/>
                  <Tooltip />
                  <Legend />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="total_records"
                    stroke="#fcba03"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="successful"
                    stroke="green"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="pending"
                    stroke="orange"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="failed"
                    stroke="red"
                    label="Total Paid"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
                <BillingTotals data={newCmdSubmittedClaims} keysAndLabels={keysAndLabelsCounts} colors={colors}/>
                </div>
                
              : null
              
          }
          </div>
        </div>
      }
    />
  );
}

export default BillingAnalyticsScreen;
