import React from 'react'
import LayoutComponent from '../BaseScreen'
import IntellachatHeader from './Header/IntellachatHeader'
import IntellachatPage from './IntellachatPage'

const IntellachatScreen = () => {
  return (
    <LayoutComponent
      header={<IntellachatHeader />} // Render your custom header component here
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
            <IntellachatPage />
          </div>
        </div>
      } // Render your custom content component here
    />
  )
}

export default IntellachatScreen
