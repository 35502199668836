import React, { useEffect, useState } from 'react'
import { Route, useParams } from 'react-router-dom';
import LayoutComponent from '../BaseScreen'
import HistoricFilterComponent from '../../Components/SortAndFilter/HistoricFilterComponent'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { useHistoric } from '../../Context/HistoricContext';
import { historic2Options } from '../../Options/Historic2Options';
import Historic2FilterComponent from '../../Components/SortAndFilter/Historic2FilterComponent';
import { useAuth } from '../../Context/AuthContext';

const Historic2Screen = () => {
  const { prefix_id, network } = useParams();

  const {prefixRecords} = useHistoric()
  const {allUsers} = useAuth()

  return (
      <LayoutComponent 
        header={
          <Historic2FilterComponent />
        } 
        content={
          <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
            <div className="h-full overflow-scroll rounded-lg mt-2">
              <TableComponent table='HistoricPrefix' users={allUsers} columns={historic2Options} records={prefixRecords}/>
            </div>
          </div>
        }
      />
  )
}

export default Historic2Screen