import React, { useEffect } from 'react'
import NavigationTabComponent from './NavigationTabComponent'
import { useNavigation } from '../../Context/NavigationContext'
import SidebarSubMenuComponent from './SidebarSubMenuComponent'
import { useAuth } from '../../Context/AuthContext'
import { useCustomTheme } from '../../Context/ThemeContext'

const SidebarComponent = () => {

  const { sideNavigation, currentSidebarTab, sideNavigationClaims, sideNavigationFollowup } = useNavigation()
  const {currentProfile} = useAuth()
  const { isDarkMode } = useCustomTheme()

  const [maxHeight, setMaxHeight] = React.useState(window.innerHeight - 52);

  useEffect(() => {
    const updateDimensions = () => {
      setMaxHeight(window.innerHeight - 52);
    };
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className={`h-full min-w-44 max-w-44 bg-slate-900 dark:bg-slate-900 rounded-tr-lg pt-2 flex flex-col items-between`} style={{height: maxHeight}}>
      {
        currentProfile.department === 'claims'
          ? sideNavigationClaims.map((tab) => {
              return(
                <div key={tab.label}>
                  {
                    tab.privileges.includes(currentProfile.privileges ? currentProfile.privileges : '') 
                    && tab.department.includes(currentProfile.department ? currentProfile.department : '')
                      ? <NavigationTabComponent type={tab.page} tab={tab.label} icon={tab.icon} route={tab.route} position={'side'}/>
                      : null
                  }
                  {
                    tab.subTabs === null 
                      ? null
                      : <div>
                          {
                            currentSidebarTab === tab.label
                              ? <SidebarSubMenuComponent tab={tab}/>
                              : null
                          }
                        </div>
                  }
                </div>
              )
            })
          : currentProfile.department === 'followup'
              ? sideNavigationFollowup.map((tab) => {
                  return(
                    <>
                      {
                        tab.privileges.includes(currentProfile.privileges ? currentProfile.privileges : '') 
                        && tab.department.includes(currentProfile.department ? currentProfile.department : '')
                          ? <NavigationTabComponent type={tab.page} tab={tab.label} icon={tab.icon} route={tab.route} position={'side'}/>
                          : null
                      }
                      {
                        tab.subTabs === null 
                          ? null
                          : <div>
                              {
                                currentSidebarTab === tab.label
                                  ? <SidebarSubMenuComponent tab={tab}/>
                                  : null
                              }
                            </div>
                      }
                    </>
                  )
                })
              : sideNavigation.map((tab) => {
                  return(
                    <>
                      {
                        tab.privileges.includes(currentProfile.privileges ? currentProfile.privileges : '') 
                        && tab.department.includes(currentProfile.department ? currentProfile.department : '')
                          ? <NavigationTabComponent type={tab.page} tab={tab.label} icon={tab.icon} route={tab.route} position={'side'}/>
                          : null
                      }
                      {
                        tab.subTabs === null 
                          ? null
                          : <div>
                              {
                                currentSidebarTab === tab.label
                                  ? <SidebarSubMenuComponent tab={tab}/>
                                  : null
                              }
                            </div>
                      }
                    </>
                  )
                })
      }
    </div>
  )
}

export default SidebarComponent
