import React from 'react';
import { useData } from '../../Context/DataContext';
import { keysAndLabelsCoordinators } from '../../Screens/Analytics/IntakeAnalytivsScreen';

interface TotalItemProps {
  label: string,
  value: number,
  color: string,
  data: any[]
}

interface KeyLabelPair {
  key: string;
  label: string;
}

interface Colors {
  [key: string]: string;
}

interface BillingTotalsProps {
  data: any[],
  keysAndLabels: KeyLabelPair[];
  colors: Colors;
  isCoordinator?: boolean;
}

const TotalItem: React.FC<TotalItemProps> = ({ data, label, value, color }) => {
  const {billingAnalyticsCount, billingAnalytics} = useData()
  return (
    <div className='flex flex-row items-center'>
      <div className={`w-4 h-4 ${color} ml-2 mr-2 mt-1`}>

      </div>
      { data === billingAnalytics
        ?  <span className='text-sm text-white'>{label}: ${value}</span> 
      
        :  <span className='text-sm text-white'>{label}: {value}</span>
      }
    </div>
  );
};

const BillingTotals: React.FC<BillingTotalsProps> = ({ data, keysAndLabels, colors, isCoordinator }) => {
  const calculateTotal = (key: string) => {

    if (isCoordinator) {
      const item = data.find(obj => obj.name.trim() === key.trim());
      return item ? parseInt(item.value) : 0; 
    } 
    else {
      return data.reduce((acc, obj) => acc + (parseInt(obj[key]) || 0), 0)
    }
  };

  return (
    <div className='flex justify-center items-center'>
      <div className='flex flex-row justify-center text-center bg-alt-on gap-4 rounded-md p-3'>
        {keysAndLabels.map(({ key, label }, index) => (
          <TotalItem 
            key={index} 
            label={label} 
            value={calculateTotal(key)} 
            color={colors[key]}
            data={data} 
          />
        ))}
      </div>
    </div>
  );
};

export default BillingTotals;