import { useState, useEffect } from "react";
import { useData } from "../../Context/DataContext";
import { useAuth } from "../../Context/AuthContext";
import ButtonComponent from "../Inputs/ButtonComponent";
import FormInputComponent from "../Inputs/FormInputComponent";

export interface IntakeNote {
  date: string;
  notes: string;
  coordinator: string;
}

interface IntakeNotesProps {
  intakeNotes: IntakeNote[]
}

const NotesForm: React.FC<IntakeNotesProps> = ({
  intakeNotes
}) => {
  const {currentNotes, sendNewNotes, currentIntakeId, getNotes, currentTimeZone} = useData();
  const {allUsers} = useAuth()

  const {currentUser} = useAuth();

  const [noteContent, setNoteContent] = useState("");
  const [submitCount, setSubmitCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmitNotes = () => {
    const data = {
      intake_id: currentIntakeId,
      date: getCurrentDateString(),
      notes: noteContent,
      coordinator: currentUser.userId
    }
    sendNewNotes(data)
    setNoteContent("");

  }

  function getCurrentDateString(): string {
    const date = new Date();
  
    
    const year: number = date.getFullYear();
    let month: string = (date.getMonth() + 1).toString();
    let day: string = date.getDate().toString();
  

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;
  

    return `${year}-${month}-${day}`;
  }

  function reformatDateString(date: string): string {
    if (!date) return "N/A";
  
    // Create a new Date object from the input string
    const dateString = new Date(date);
  
    // Extract the year, month, and day
    const year = dateString.getUTCFullYear();
    let month = (dateString.getUTCMonth() + 1).toString();
    let day = dateString.getUTCDate().toString();
  
    // Format month and day to ensure two digits
    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;
  
    return `${year}-${month}-${day}`;
  }


  const getCoordinatorName = (coordinatorId: string) => {
    if (allUsers) {
      const user = allUsers.find((user) => user.userid === coordinatorId);
      return user ? user.name : "N/A"
    }
    return 'N/A'
  };



  return (
    <div className="w-full">
      <table className="min-w-full divide-y divide-primary rounded-lg overflow-hidden">
        <thead className="bg-primary-alternate dark:bg-secondary min-w-full">
          <tr>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-black dark:text-white uppercase tracking-wider">
              Date
            </th>
            <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-black dark:text-white uppercase tracking-wider ">
              Notes
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-black dark:text-white uppercase tracking-wider">
              Name
            </th>
          </tr>
        </thead>
        <tbody className="bg-slate-100 dark:bg-black divide-y divide-primary">
          {currentNotes && currentNotes.map((note, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-black dark:text-white">
                {reformatDateString(note.date) || "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-wrap text-sm text-black dark:text-white">
                {note.notes || "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-wrap text-sm text-black dark:text-white">
                {getCoordinatorName(note.coordinator)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <form>
        <div className='flex my-2 px-3 py-2 flex-col justify-center rounded-lg'>
        <p className="b-1 font-bold text-primary dark:text-sky-400 text-md mr-4 mb-1">Enter New Note</p>
        <input 
          className="flex-1 text-base border-b-2 border-b-slate-800 dark:border-b-slate-300 bg-stone-300 dark:bg-slate-700 text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          placeholder="Add new note..."
        />
        </div>
        <div className="mb-5">
        <ButtonComponent label="Submit Notes" handler={handleSubmitNotes}/>
        </div>
      </form>
    </div>
  )
}

export default NotesForm