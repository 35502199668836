import React from "react";
import SelectInputComponent from "./SelectInputComponent";

interface TimeZoneSelectorProps {
  placeholder: string;
  selectedValue: string;
  options: string[];
  mappedOptions: any;
  onTimeZoneChange: (timezone: string) => void; 
}

const TimeZoneSelector: React.FC<TimeZoneSelectorProps> = ({
  placeholder,
  selectedValue,
  options,
  mappedOptions,
  onTimeZoneChange
}) => {
  return (
    <div className="flex flex-row gap-2">
      <SelectInputComponent placeholder={placeholder} selectedValue={selectedValue} options={options} handleOptionClick={onTimeZoneChange} />
      <p className="text-black dark:text-white">{mappedOptions[selectedValue]}</p>
    </div>
  )
}

export default TimeZoneSelector

