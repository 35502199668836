import React, { useEffect, useState } from 'react'
import LayoutComponent from '../BaseScreen'
import HistoricFilterComponent from '../../Components/SortAndFilter/HistoricFilterComponent'
import TableComponent from '../../Components/Tables/TableComponent'
import { useData } from '../../Context/DataContext'
import { historicOptions } from '../../Options/Historic3Options'
import { useAuth } from '../../Context/AuthContext'
import { useHistoric } from '../../Context/HistoricContext'
const HistoricScreen = () => {

  const {allUsers} = useAuth()
  const {billingDetails, grabRecords} = useHistoric()

  useEffect(() => {
    grabRecords()
  }, [])

  return (
      <LayoutComponent 
        header={
          <div className='h-14 w-full mb-2'>
            <HistoricFilterComponent />
          </div>
        } 
        content={
          <div className="w-full px-4" style={{ height: 'calc(100vh - 10.5rem)' }}>
            <div className="h-full overflow-scroll rounded-lg mt-2">
              <TableComponent table='Historic' users={allUsers} columns={historicOptions} records={billingDetails}/>
            </div>
          </div>
        }
      />
  )
}

export default HistoricScreen